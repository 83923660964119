import { Header } from './Header';
import { ReactNode, useEffect } from 'react';
import * as React from 'react';
import config from 'src/config';

export const BasePage = ({ children, title }: { children: ReactNode, title?: string }) => {
  useEffect(() => {
    document.title = title || config.title;
  }, []);

  return (
    <div className="bg-neutral-beta-900 min-h-screen">
      <Header />

      <div>{children}</div>
    </div>
  );
};
