import { io } from 'socket.io-client';
import config from 'src/config';
import { AppBroadcast } from 'src/libs/broadcast';

declare global {
  interface Window {
    sockets: {
      [ESocketType: string]: ReturnType<typeof io> | null;
    };
  }
}

window.sockets = {};

export const SOCKET_TYPE = {
  ORDER: 'ORDER',
  PUBLIC: 'PUBLIC',
};

export const SOCKET_EVENTS = {
  SUBSCRIBE_REALTIME_CANDLE_CHART: 'SUBSCRIBE_REALTIME_CANDLE_CHART',
  UNSUBSCRIBE_REALTIME_CANDLE_CHART: 'UNSUBSCRIBE_REALTIME_CANDLE_CHART',
  SUBSCRIBE_REALTIME_TRANSACTION: 'SUBSCRIBE_REALTIME_TRANSACTION',
  UNSUBSCRIBE_REALTIME_TRANSACTION: 'UNSUBSCRIBE_REALTIME_TRANSACTION',
  CANDLE_CHART: 'CANDLE_CHART',
  TRANSACTION: 'TRANSACTION',
  SUBSCRIBE_ORDER: 'SUBSCRIBE_ORDER',
  ORDER: 'ORDER',
};

export const getSocketInstance = (key: string): ReturnType<typeof io> | null =>
  window.sockets[key];

export const createSocketConnection = (
  key: string,
  network: string,
  accessToken?: string,
) => {
  window.sockets[key] = io(config.socketUrl, {
    transports: ['websocket', 'polling'],
    reconnectionDelayMax: 1000,
    autoConnect: true,
    reconnection: true,
    ...(accessToken && { query: { authorization: accessToken } }),
    auth: { offset: undefined },
    path: `/${network}${key == SOCKET_TYPE.ORDER ? '/orders' : ''}`,
  });

  getSocketInstance(key)?.on('connect', () => {
    console.log(`Connected to websocket server ${config.socketUrl}`);
  });

  getSocketInstance(key)?.on('disconnect', (reason, details) => {
    console.log('Websocket disconnected', reason, details);
  });
};

export const subscribePublic = () => {
  getSocketInstance(SOCKET_TYPE.PUBLIC)?.on(
    SOCKET_EVENTS.CANDLE_CHART,
    (data: any) => {
      AppBroadcast.dispatch(SOCKET_EVENTS.CANDLE_CHART, data);
    },
  );

  getSocketInstance(SOCKET_TYPE.PUBLIC)?.on(
    SOCKET_EVENTS.TRANSACTION,
    (data: any) => {
      AppBroadcast.dispatch(SOCKET_EVENTS.TRANSACTION, data);
    },
  );
};

export const subscribeOrder = () => {
  getSocketInstance(SOCKET_TYPE.ORDER)?.on(SOCKET_EVENTS.ORDER, (data: any) => {
    AppBroadcast.dispatch(SOCKET_EVENTS.ORDER, data);
  });
};

export const closeSocket = (url: string) => {
  console.log('close socket connection...');
  const instance = getSocketInstance(url);
  if (instance) {
    instance.removeAllListeners();
    instance.close();
    window.sockets[url] = null;
  }
};
