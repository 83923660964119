import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { FilterIcon } from 'src/assets/icons';
import cx from 'classnames';

const TYPE_OPTIONS = [
  {
    name: 'All',
    value: 'ALL',
  },
  {
    name: 'Buy',
    value: 'BUY',
  },
  {
    name: 'Sell',
    value: 'SELL',
  },
  {
    name: 'Add',
    value: 'ADD',
  },
  {
    name: 'Remove',
    value: 'REMOVE',
  },
];

export const FilterByType = ({
  params,
  setParams,
}: {
  params: any;
  setParams: (data: any) => void;
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const filterRef = useRef<any>(null);

  const handleClickOutside = (event: Event) => {
    if (
      filterRef.current &&
      !filterRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <div
      className="cursor-pointer relative hover:text-neutral-alpha-1000"
      ref={filterRef}
    >
      <FilterIcon
        onClick={() => setShowDropdown(true)}
        className={
          params.tradingType !== 'ALL' ? 'text-neutral-alpha-1000' : ''
        }
      />
      {showDropdown && (
        <div className={cx('absolute right-0 pt-[8px] z-10 w-max')}>
          <div className="w-full border border-neutral-alpha-50 p-2 bg-[#0f1018] rounded-[4px]">
            {TYPE_OPTIONS.map((option, index) => {
              const isActive = option.value === params.tradingType;
              return (
                <div
                  key={index}
                  className={cx(
                    'flex justify-between items-center py-2 px-3',
                    'cursor-pointer w-full',
                    'rounded-[4px]',
                    'hover:bg-neutral-alpha-50 text-neutral-0',
                    isActive ? 'bg-neutral-alpha-50' : '',
                  )}
                  onClick={() => {
                    setParams({
                      ...params,
                      tradingType: option.value,
                    });
                    setShowDropdown(false);
                  }}
                >
                  {option.name}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
