import React from 'react';
import { useState } from 'react';
import { FilterIcon } from 'src/assets/icons';
import { ModalFilterAmount } from 'src/modals';

export const FilterByAmount = ({
  params,
  setParams,
  isBaseAmount,
}: {
  params: any;
  isBaseAmount?: boolean;
  setParams: (data: any) => void;
}) => {
  const [isShowModalFilter, setIsShowModalFilter] = useState<boolean>(false);
  const getClassIcon = () => {
    if (isBaseAmount) {
      return !!params.baseAmount
        ? 'text-neutral-alpha-1000'
        : 'text-neutral-alpha-500';
    }
    return !!params.quoteAmount
      ? 'text-neutral-alpha-1000'
      : 'text-neutral-alpha-500';
  };
  return (
    <div className="cursor-pointer">
      <FilterIcon
        onClick={() => setIsShowModalFilter(true)}
        className={`${getClassIcon()} hover:text-neutral-alpha-1000`}
      />

      <ModalFilterAmount
        isBaseAmount={isBaseAmount}
        setParams={setParams}
        params={params}
        isOpen={isShowModalFilter}
        onClose={() => setIsShowModalFilter(false)}
      />
    </div>
  );
};
