import * as React from 'react';
import { Transactions } from '../components/Transactions';
import { TopTraders } from '../components/TopTraders';
import { Holders } from '../components/Holders';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { MyHoldings } from 'src/pages/pool/components/MyHoldings';
import {
  TransactionIcon,
  HoldersIcon,
  TopHoldersIcon,
  MinusIcon,
  CherronUpIcon,
  ChevronDownIcon,
} from 'src/assets/icons';

export const PartPoolInsights = ({
  activeTab,
  setActiveTab,
  expand,
  hide,
  isHidden,
  sectionExpanded,
}: {
  isHidden?: boolean;
  activeTab: string;
  setActiveTab: (value: string) => void;
  sectionExpanded: null | string;
  expand: (value: string) => void;
  hide: (value: string) => void;
}) => {
  const { accessToken } = useSelector((state: RootState) => state.user);

  const TAB = [
    {
      id: 'transactions',
      name: 'Transactions',
      icon: TransactionIcon,
    },
    !!accessToken && {
      id: 'my-holdings',
      name: 'My Holdings',
      icon: TransactionIcon,
    },
    {
      id: 'top-traders',
      name: 'Top Traders',
      icon: TopHoldersIcon,
    },
    {
      id: 'holders',
      name: 'Holders',
      icon: HoldersIcon,
    },
  ].filter(Boolean);

  const _renderContent = () => {
    switch (activeTab) {
      case 'transactions': {
        return <Transactions isExpand={sectionExpanded === 'TABLE'} />;
      }
      case 'top-traders': {
        return <TopTraders isExpand={sectionExpanded === 'TABLE'}/>;
      }
      case 'holders': {
        return <Holders isExpand={sectionExpanded === 'TABLE'}/>;
      }
      case 'my-holdings': {
        return <MyHoldings isExpand={sectionExpanded === 'TABLE'} />;
      }
      default: {
        return <Transactions isExpand={sectionExpanded === 'TABLE'} />;
      }
    }
  };

  return (
    <div>
      <div className="border-y border-neutral-alpha-50 flex justify-between items-center">
        <div className="flex gap-1">
          {TAB.map((item: any, index) => {
            return (
              <div
                onClick={() => setActiveTab(item.id)}
                key={index}
                className={`hover:text-neutral-alpha-1000 hover:font-semibold flex items-center text-[12px] leading-[16px] gap-1 cursor-pointer px-4 py-3 ${
                  activeTab === item.id
                    ? 'text-neutral-alpha-1000 border-neutral-alpha-500 border-b font-semibold'
                    : 'text-neutral-alpha-800 border-0 font-normal'
                }`}
              >
                <item.icon />
                {item.name}
              </div>
            );
          })}
        </div>

        <div className="flex">
          <div className="p-2 cursor-pointer">
            {sectionExpanded === 'CHART' ? (
              <MinusIcon className="opacity-50" />
            ) : (
              <MinusIcon onClick={() => hide('TABLE')} />
            )}
          </div>
          <div className="p-2 cursor-pointer">
            {sectionExpanded === 'TABLE' ? (
              <ChevronDownIcon width={16} onClick={() => hide('TABLE')} />
            ) : (
              <CherronUpIcon onClick={() => expand('TABLE')} />
            )}
          </div>
        </div>
      </div>
      {!isHidden && _renderContent()}
    </div>
  );
};
