import config from 'src/config';
import BaseRequest from './BaseRequest';

export default class TransactionRequest extends BaseRequest {
  getUrlPrefix() {
    return config.apiUrl;
  }

  async getTransactions(network: string, params: any) {
    const url = `/${network}/transactions`;
    return this.get(url, params);
  }
}
