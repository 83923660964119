import * as React from 'react';
import { ChevronDownIcon, IconSettings, FlashIcon } from 'src/assets/icons';
import { useState } from 'react';
import { OrderFormSetting, TOrderSetting } from './OrderFormSetting';
import { NumericFormat } from 'react-number-format';
import { AppLogoNetwork } from 'src/components';
import { toastError, toastInfo } from 'src/libs/toast';
import rf from 'src/services/RequestFactory';
import { useContext } from 'react';
import { PoolContext } from 'src/pages/pool/context';
import { TPair } from 'src/types';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { filterParams } from 'src/utils/helper';

const DEFAULT_AMOUNT = [0.25, 0.5, 1, 2, 5, 10];

export const OrderFormBuyNow = () => {
  const [amount, setAmount] = useState<any>('');
  const [isShowAdvancedSettings, setIsShowAdvancedSettings] =
    useState<boolean>(false);
  const { pair } = useContext(PoolContext) as { pair: TPair };
  const [orderSetting, setOrderSetting] = useState<TOrderSetting>({
    slippage: 20,
    priorityFee: '0.008',
  });
  const { accessToken } = useSelector((state: RootState) => state.user);
  const { walletsActive } = useContext(PoolContext);

  const createOrder = async () => {
    try {
      if (!accessToken) {
        throw Error('Please login!');
      }

      if (!amount) {
        throw Error('Please input amount!');
      }

      if (!walletsActive?.length) {
        throw Error('Please select a wallet to start trading!');
      }

      await rf.getRequest('OrderRequest').createOrderQuickBuy(
        pair.network,
        filterParams({
          buyAmount: amount,
          orderSetting,
          pairId: pair?.pairId,
          tokenAddress: pair?.tokenBase?.address,
          wallets: walletsActive,
        }),
      );
      setAmount('');
      toastInfo('Submitting', 'Submitting Order!');
    } catch (e: any) {
      console.error(e);
      toastError('Error', e.message || 'Something went wrong!');
    }
  };

  return (
    <div>
      <div className="text-[10px] leading-[14px] text-neutral-alpha-500 mb-2">
        Balance: -- {pair?.tokenQuote?.symbol}
      </div>
      <div className="flex grid grid-cols-3 gap-2 flex-wrap">
        {DEFAULT_AMOUNT.map((item, index) => {
          return (
            <div
              className={`px-1 h-[28px] flex items-center justify-center text-[14px] leading-none cursor-pointer border ${
                item === +amount
                  ? 'border-neutral-alpha-500'
                  : 'border-neutral-alpha-100'
              } rounded-[4px]`}
              key={index}
              onClick={() => setAmount(item)}
            >
              {item}
            </div>
          );
        })}
      </div>

      <div className="p-2 mt-2 bg-neutral-alpha-50 rounded-[4px] flex items-center gap-2">
        <div className="uppercase text-neutral-alpha-800 text-[12px]">
          Amount
        </div>
        <div className="bg-neutral-beta-800 flex gap-2 items-center rounded-[4px] px-1 py-2 flex-1">
          <NumericFormat
            value={amount ?? ''}
            allowLeadingZeros
            allowNegative={false}
            thousandSeparator=","
            className="bg-transparent outline-none font-semibold text-[14px] leading-[20px] w-full text-right"
            decimalScale={6}
            onValueChange={({ floatValue }) => {
              return setAmount(floatValue?.toString());
            }}
          />

          <div className="text-neutral-alpha-500 text-[10px] leading-[14px] flex items-center gap-1">
            <AppLogoNetwork network={pair?.network} className="w-3 h-3" />
            {pair?.tokenQuote?.symbol}
          </div>
        </div>
      </div>

      <div
        className={`${
          isShowAdvancedSettings ? 'pt-3' : '-y py-3'
        } flex hidden hover:text-neutral-0 font-bold text-[13px] text-[#8d93b7] justify-between items-center w-full cursor-pointer border-neutral-alpha-500`}
        onClick={() => setIsShowAdvancedSettings(!isShowAdvancedSettings)}
      >
        <div className="flex gap-2 items-center">
          <IconSettings />
          Advanced Settings
        </div>
        <div>
          <ChevronDownIcon
            className={`${
              isShowAdvancedSettings ? 'rotate-[180deg]' : ' '
            } w-4 h-4`}
          />
        </div>
      </div>

      {isShowAdvancedSettings && (
        <OrderFormSetting
          setOrderSetting={setOrderSetting}
          orderSetting={orderSetting}
        />
      )}

      <div
        className="px-2 my-2 py-[10px] rounded-[6px] text-brand-300 gap-1 action-sm-medium-14 cursor-pointer border-brand-800 border bg-brand-800 flex items-center justify-center"
        onClick={createOrder}
      >
        <FlashIcon /> Quick Buy
      </div>

      <div className="body-xs-regular-10 text-neutral-alpha-500">
        Once you click on <b>Quick Buy</b>, your transaction is sent
        immediately.
      </div>
    </div>
  );
};
