import { DEFAULT_TRADING_VIEW_INTERVAL } from 'src/components/TradingView/utils';

const PREFERENCES = `dex-trade`;

type StorageInterface = {
  timeFrame?: string;
  referralCode?: string;
  accessToken?: string;
  network?: string;
};

const defaultPreferences: StorageInterface = {
  timeFrame: DEFAULT_TRADING_VIEW_INTERVAL,
};

function getStorage(): StorageInterface {
  const preferencesString = localStorage.getItem(PREFERENCES);
  const preferences = JSON.parse(preferencesString || '{}');
  return {
    ...defaultPreferences,
    ...preferences,
  };
}

function setStorage(type: string, value: StorageInterface) {
  localStorage.setItem(type, JSON.stringify(value));
}

class Storage {
  static init() {
    const preferences = getStorage();
    setStorage(PREFERENCES, preferences);
  }

  static getTimeFrame(): string | undefined {
    const { timeFrame } = getStorage();
    return timeFrame || DEFAULT_TRADING_VIEW_INTERVAL;
  }

  static setTimeFrame(timeFrame: string) {
    const preferences = getStorage();
    preferences.timeFrame = timeFrame;
    setStorage(PREFERENCES, preferences);
  }
  
  static setReferralCode(referralCode: string) {
    const preferences = getStorage();
    preferences.referralCode = referralCode;
    setStorage(PREFERENCES, preferences);
  }

  static getReferralCode() {
    const { referralCode } = getStorage();
    return referralCode;
  }

  static clearTimeFrame() {
    const preferences = getStorage();
    delete preferences.timeFrame;
    setStorage(PREFERENCES, preferences);
  }

  static getAccessToken(): string | undefined {
    const { accessToken } = getStorage();
    return accessToken;
  }

  static setAccessToken(accessToken: string) {
    const preferences = getStorage();
    preferences.accessToken = accessToken;
    setStorage(PREFERENCES, preferences);
  }

  static clearAccessToken() {
    const preferences = getStorage();
    delete preferences.accessToken;
    setStorage(PREFERENCES, preferences);
  }

  static getNetwork(): string | undefined {
    const { network } = getStorage();
    return network;
  }

  static setNetwork(accessToken: string) {
    const preferences = getStorage();
    preferences.network = accessToken;
    setStorage(PREFERENCES, preferences);
  }

  static clearNetwork() {
    const preferences = getStorage();
    delete preferences.network;
    setStorage(PREFERENCES, preferences);
  }
}

export default Storage;
