import { BaseModal } from 'src/modals/BaseModal';
import React from 'react';
import { AppButton } from 'src/components';

export const ModalConfirm = ({
  isOpen,
  onClose,
  description,
  onConfirm,
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  description: string;
}) => {
  const handleConfirm = async () => {
    try {
      await onConfirm();
    } catch (e) {
      console.error(e);
    } finally {
      onClose();
    }
  };
  return (
    <BaseModal isOpen={isOpen} title={'Confirmation'} onClose={onClose}>
      <div className="text-center">{description}</div>

      <div className="flex gap-4 justify-center mt-8">
        <AppButton
          onClick={onClose}
          variant="secondary"
          className="min-w-[120px]"
        >
          Cancel
        </AppButton>
        <AppButton className="min-w-[120px]" onClick={handleConfirm}>
          Confirm
        </AppButton>
      </div>
    </BaseModal>
  );
};
