import * as React from 'react';
import { BasePage } from 'src/layout';

export const MemeScopePage = () => {
  return (
    <BasePage>
      <div className="px-9 mt-8">
        <div className="font-bold text-[20px] uppercase">memescope</div>
        <div className="text-[#8d93b7] mt-2">
          Customized real-time feeds of new tokens matching your selected preset
          filters.
        </div>
      </div>
    </BasePage>
  );
};
