import { ColorType, createChart } from 'lightweight-charts';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import {
  CANDLE_TYPE,
  CANDLE_UNIT,
  LIST_RESOLUTION_METADATA,
} from 'src/components/TradingView/consts';
import { NavBar } from './parts/NavBar';
import rf from 'src/services/RequestFactory';
import {
  convertResolution2MilliSeconds,
  convertResolution2Seconds,
  getCandleTime,
} from 'src/components/TradingView/utils';
import * as moment from 'moment';
import Storage from 'src/libs/storage';
import { useEffectUnsafe, useInitialing } from 'src/hooks';
import { TCandle, TChart, TPair } from 'src/types';
import { AppBroadcast } from 'src/libs/broadcast';
import { getSocketInstance, SOCKET_EVENTS, SOCKET_TYPE } from 'src/libs/socket';

type TChartLightWeight = {
  handleFullscreen: any;
  isFullScreen: boolean;
  pair: TPair;
};

export const ChartLightWeight = ({
  handleFullscreen,
  isFullScreen,
  pair,
}: TChartLightWeight) => {
  const selectedTimeFrame = Storage.getTimeFrame() || '1S';
  const defaultInterval = LIST_RESOLUTION_METADATA[selectedTimeFrame]?.value;

  const chartContainerRef = useRef<any>(null);
  const [interval, setInterval] = useState<any>(defaultInterval);
  const [candleType, setCandleType] = useState<string>(CANDLE_TYPE.PRICE);
  const [candleUnit, setCandleUnit] = useState<string>(CANDLE_UNIT.USD);
  const [dataChart, setDataChart] = useState<TChart[]>([]);
  const lastCandleRef = useRef<TChart>({} as TChart);
  const { isInitialing } = useInitialing();


  const getQueryBy = () => {
    if (candleType === CANDLE_TYPE.PRICE) {
      if (candleUnit === CANDLE_UNIT.TOKEN_QUOTE) {
        return CANDLE_TYPE.PRICE;
      }
      return CANDLE_TYPE.PRICE_USD;
    }

    if (candleUnit === CANDLE_UNIT.TOKEN_QUOTE) {
      return CANDLE_TYPE.MCAP;
    }

    return CANDLE_TYPE.MCAP_USD;
  };

  const getDataChart = async () => {
    if (!pair?.pairId) return;
    try {
      const endTime = getCandleTime(
        moment().valueOf(),
        convertResolution2MilliSeconds(interval),
      );
      const startTime =
        endTime - 100000 * convertResolution2MilliSeconds(interval);

      const res = await rf
        .getRequest('CandleRequest')
        .getCandles(pair.network, {
          from: +startTime / 1000,
          to: +endTime / 1000,
          pair: pair.pairId,
          queryBy: getQueryBy(),
          resolution: convertResolution2Seconds(interval),
        });

      const dataFormat = res?.candles?.map((item: TCandle) => {
        return {
          close: +item.close,
          high: +item.high,
          low: +item.low,
          open: +item.open,
          time: item.timestamp,
        };
      });

      setDataChart(dataFormat);
    } catch (e) {}
  };

  useEffectUnsafe(() => {
    getDataChart().then();
  }, [interval, pair?.pairId, candleType, candleUnit]);

  useEffect(() => {
    AppBroadcast.on(SOCKET_EVENTS.CANDLE_CHART, (data: TCandle) => {
      const lastCandle = lastCandleRef?.current;
      const dataFormat = {
        close: +data.close,
        high: +data.high,
        low: +data.low,
        open: +data.open,
        time: data.timestamp,
      };

      let newData = dataChart;
      if (dataFormat.time > lastCandle.time) {
        newData = [...dataChart, ...[dataFormat]];
      } else if (dataFormat.time === lastCandle.time) {
        newData = dataChart.map((item: TChart) =>
          item.time === dataFormat.time ? dataFormat : item,
        );
      }
      setDataChart(newData);
    });

    return () => {
      AppBroadcast.remove(SOCKET_EVENTS.CANDLE_CHART);
    };
  }, [dataChart]);

  const subscribeCandleChart = () => {
    getSocketInstance(SOCKET_TYPE.PUBLIC)?.emit(
      SOCKET_EVENTS.SUBSCRIBE_REALTIME_CANDLE_CHART,
      {
        pair: pair?.slug,
        queryBy: getQueryBy(),
        resolution: convertResolution2Seconds(interval),
      },
    );
  };

  const unsubscribeCandleChart = () => {
    getSocketInstance(SOCKET_TYPE.PUBLIC)?.emit(
      SOCKET_EVENTS.UNSUBSCRIBE_REALTIME_CANDLE_CHART,
      {
        pair: pair?.slug,
        queryBy: getQueryBy(),
        resolution: convertResolution2Seconds(interval),
      },
    );
  };

  useEffect(() => {
    if (isInitialing) return;
    subscribeCandleChart();

    return () => {
      unsubscribeCandleChart();
    };
  }, [pair?.slug, isInitialing, interval, candleType, candleUnit]);

  const setupChart = () => {
    (window as any).chart = createChart(chartContainerRef.current, {
      layout: {
        background: { type: ColorType.Solid, color: '#0f1018' },
        textColor: '#8d93b7',
      },
      grid: {
        vertLines: {
          color: 'rgba(42, 46, 57, 0.5)',
        },
        horzLines: {
          color: 'rgba(42, 46, 57, 0.5)',
        },
      },
      width: window.innerWidth - 648,
      height: chartContainerRef.current.clientHeight,
    });

    const newSeries = (window as any).chart.addCandlestickSeries({
      upColor: '#32B379',
      downColor: '#F5483D',
      borderVisible: true,
      wickUpColor: '#32B379',
      wickDownColor: '#F5483D',
    });

    lastCandleRef.current = dataChart[dataChart.length - 1];
    newSeries.setData(dataChart as any);
    newSeries.applyOptions({
      priceFormat: {
        type: 'price',
        precision: 8,
        minMove: 0.00000001,
      },
    });

    (window as any).chart.applyOptions({
      timeScale: {
        secondsVisible: false,
        timeVisible: true,
      },
    });

    (window as any).chart.timeScale().fitContent();
    (window as any).chart.timeScale().scrollToPosition(1, false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (chartContainerRef.current) {
        setTimeout(() => {
          (window as any).chart.applyOptions({
            width: window.innerWidth - 648,
            height: chartContainerRef.current.clientHeight,
          });
        }, 100);
      }
    };
    setupChart();

    window.addEventListener('resize', handleResize);
    AppBroadcast.on('LAYOUT_CHANGE', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      AppBroadcast.remove('LAYOUT_CHANGE');

      (window as any).chart.remove();
    };
  }, [isFullScreen, dataChart]);

  return (
    <div className="h-full mx-2 mt-2">
      <div className="mb-2">
        <NavBar
          isFullScreen={isFullScreen}
          onSelectInterval={setInterval}
          handleFullscreen={handleFullscreen}
          onSelectCandleType={setCandleType}
          candleType={candleType}
          setCandleUnit={setCandleUnit}
          candleUnit={candleUnit}
          pair={pair}
        />
      </div>
      <div
        ref={chartContainerRef}
        className={`${
          isFullScreen ? 'h-[calc(100vh-70px)]' : 'h-[calc(100%-60px)]'
        } w-full`}
      />
    </div>
  );
};
