import BigNumber from 'bignumber.js';
import * as React from 'react';
import Tooltip from 'rc-tooltip';
export const Progress = ({
  valueLeft,
  valueRight,
  nameLeft,
  nameRight,
}: {
  valueLeft: string | number;
  valueRight: string | number;
  nameLeft: string;
  nameRight: string;
}) => {
  const total = new BigNumber(valueLeft).plus(new BigNumber(valueRight));
  const percentLeft = valueLeft
    ? new BigNumber(valueLeft)
        .dividedBy(new BigNumber(total))
        .multipliedBy(100)
        .toFixed(2)
    : 0;
  const percentRight = valueLeft
    ? new BigNumber(100).minus(new BigNumber(percentLeft)).toFixed(2)
    : 0;

  return (
    <Tooltip
      placement={'bottom'}
      overlay={
        <div>
          <div className="text-green-500 flex gap-1">
            {percentLeft ? percentLeft : '0'}%<div> {nameLeft}</div>
          </div>
          <div className="text-red-500 flex gap-1">
            {percentRight}%<div>{nameRight}</div>
          </div>
        </div>
      }
    >
      <div className="flex gap-1 w-full cursor-pointer h-[2px] bg-neutral-alpha-50">
        <div
          className={`h-[2px] bg-green-600 rounded-[100px]`}
          style={{ width: `${percentLeft}%` }}
        />
        <div
          className={`h-[2px] bg-red-600 rounded-[100px]`}
          style={{ width: `${percentRight}%` }}
        />
      </div>
    </Tooltip>
  );
};
