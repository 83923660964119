import config from 'src/config';
import BigNumber from 'bignumber.js';
import { NETWORKS } from 'src/utils/contants';

export const getLinkAddressExplorer = (network: string, address: string) => {
  if (!address) return '#';

  if (network === NETWORKS.SUI) {
    return `${config.explorerSuiUrl}/address/${address}`;
  }

  return `${config.explorerAptosUrl}/account/${address}?network=mainnet`;
};

export const getLinkTokenExplorer = (network: string, address: string) => {
  if (!address) return '#';

  if (network === NETWORKS.SUI) {
    return `${config.explorerSuiUrl}/coin/${address}`;
  }

  return `${config.explorerAptosUrl}/coin/${address}?network=mainnet`;
};

export const getLinkTxExplorer = (
  network: string,
  version: number | string,
) => {
  if (!version) return '#';

  if (network === NETWORKS.SUI) {
    return `${config.explorerSuiUrl}/txblock/${version}`;
  }

  return `${config.explorerAptosUrl}/txn/${version}?network=mainnet`;
};

export const filterParams = (params: any) => {
  return Object.fromEntries(Object.entries(params).filter(([_, v]) => v));
};

export const minusNumber = (
  a: number | string | BigNumber,
  b: number | string | BigNumber,
) => {
  return new BigNumber(a).abs().minus(new BigNumber(b).abs()).toString();
};

export const multipliedByNumber = (
  a: number | string | BigNumber,
  b: number | string | BigNumber,
) => {
  return new BigNumber(a).multipliedBy(b).toString();
};

export const dividedByNumber = (
  a: number | string | BigNumber,
  b: number | string | BigNumber,
) => {
  return new BigNumber(a).dividedBy(b).toString();
};

export const convertUnit2Real = (
  val: number | string | BigNumber,
  decimals: number | string | BigNumber,
) => {
  return new BigNumber(val)
    .dividedBy(new BigNumber(10).pow(decimals || 0))
    .toString();
};

export const sleep = async (ms: number) =>
  new Promise((r) => setTimeout(r, ms));
