import React from 'react';
import { useState } from 'react';
import { FilterIcon } from 'src/assets/icons';
import { ModalFilterMarker } from 'src/modals';

export const FilterByMaker = ({
  params,
  setParams,
}: {
  params: any;
  setParams: (data: any) => void;
}) => {
  const [isShowModalFilter, setIsShowModalFilter] = useState<boolean>(false);
  return (
    <div className="cursor-pointer hover:text-neutral-alpha-1000">
      <FilterIcon
        onClick={() => setIsShowModalFilter(true)}
        className={!!params.makerAddress ? 'text-neutral-alpha-1000' : ''}
      />

      <ModalFilterMarker
        setParams={setParams}
        params={params}
        isOpen={isShowModalFilter}
        onClose={() => setIsShowModalFilter(false)}
      />
    </div>
  );
};
