import React from 'react';
import { useState } from 'react';
import { AppDropdown } from 'src/components';
import { NumericFormat } from 'react-number-format';

export type TTarget = {
  targetMcPercentage: any;
  targetMc: any;
  targetLinePercentage: any;
};

const LIMIT_BY = {
  PERCENT: 'PERCENT',
  PRICE: 'PRICE',
  BY_CHART: 'BY_CHART',
};

const TYPE_LIMIT = {
  MAX: 'MAX',
  MIN: 'MIN',
};

export const PnLForm = ({
  data,
  setData,
  typeLimit,
}: {
  data: TTarget;
  typeLimit: string;
  setData: (value: TTarget) => void;
}) => {
  const [type, setType] = useState<string>(LIMIT_BY.PERCENT);

  const LIMIT_OPTIONS = [
    {
      name: typeLimit === TYPE_LIMIT.MAX ? 'MC ↑ by' : 'MC ↓ by',
      value: LIMIT_BY.PERCENT,
    },
    {
      name: 'MC is',
      value: LIMIT_BY.PRICE,
    },
    {
      name: 'By target line',
      value: LIMIT_BY.BY_CHART,
    },
  ];

  return (
    <div>
      <div className="border mb-4 border-neutral-alpha-500 flex gap-2 rounded-[100px] pl-2">
        <AppDropdown
          className={type === LIMIT_BY.BY_CHART ? '!w-full' : 'min-w-[80px]'}
          options={LIMIT_OPTIONS}
          onSelect={setType}
          value={type}
        />
        {type !== LIMIT_BY.BY_CHART && (
          <div className="px-3 flex items-center border-l border-neutral-alpha-500">
            <div className="px-2 text-[#8d93b7]">
              {typeLimit === TYPE_LIMIT.MAX ? '≥' : '≤'}
            </div>
            {type == LIMIT_BY.PERCENT ? (
              <NumericFormat
                value={data.targetMcPercentage ?? ''}
                allowLeadingZeros
                allowNegative={false}
                thousandSeparator=","
                className="h-10 px-2 bg-transparent outline-none w-full"
                decimalScale={2}
                onValueChange={({ floatValue }) => {
                  return setData({
                    ...data,
                    targetMcPercentage: floatValue,
                  });
                }}
              />
            ) : (
              <NumericFormat
                value={data.targetMc ?? ''}
                allowLeadingZeros
                allowNegative={false}
                className="h-10 px-2 bg-transparent outline-none w-full"
                thousandSeparator=","
                decimalScale={6}
                onValueChange={({ floatValue }) => {
                  return setData({
                    ...data,
                    targetMc: floatValue,
                  });
                }}
              />
            )}

            <div className="text-[#8d93b7]">
              {type == LIMIT_BY.PERCENT ? '%' : '$'}
            </div>
          </div>
        )}
      </div>

      {type !== LIMIT_BY.PRICE && (
        <div className="text-[#8d93b7] text-[12px] mb-4">
          Triggers on: <br />
          MC {typeLimit === TYPE_LIMIT.MAX ? '≥' : '≤'} $21.06, SOL{' '}
          {typeLimit === TYPE_LIMIT.MAX ? '≥' : '≤'} 0.0₈14726
        </div>
      )}
    </div>
  );
};
