import config from 'src/config';
import BaseRequest from './BaseRequest';

export default class HolderRequest extends BaseRequest {
  getUrlPrefix() {
    return config.apiUrl;
  }

  async getHolders(network: string, params: any) {
    const url = `/${network}/holders`;
    return this.get(url, params);
  }
}
