import config from 'src/config';
import BaseRequest from './BaseRequest';

export default class OrderRequest extends BaseRequest {
  getUrlPrefix() {
    return config.apiUrl;
  }

  async getOrders(params: any) {
    const url = `/orders`;
    return this.get(url, params);
  }

  async createOrderBuyDip(params: any) {
    const url = `/orders/buy-dip`;
    return this.post(url, params);
  }

  async createOrderQuickBuy(network: string, params: any) {
    const url = `/${network}/orders/quick-buy`;
    return this.post(url, params);
  }

  async createOrderAutoSell(params: any) {
    const url = `/orders/auto-sell`;
    return this.post(url, params);
  }

  async createOrderQuickSell(network: string, params: any) {
    const url = `/${network}/orders/quick-sell`;
    return this.post(url, params);
  }

  async revokeOrder(orderId: string) {
    const url = `/orders/${orderId}/revoke`;
    return this.post(url);
  }

  async updateTradeSettings(network: string, params: any) {
    const url = `/${network}/trade-settings`;
    return this.put(url, params);
  }

  async getTradeSettings(network: string) {
    const url = `/${network}/preset-settings`;
    return this.get(url);
  }
}
