import TransactionRequest from './TransactionRequest';
import CandleRequest from './CandleRequest';
import PairRequest from './PairRequest';
import UserRequest from './UserRequest';
import HolderRequest from './HolderRequest';
import WalletRequest from './WalletRequest';
import OrderRequest from './OrderRequest';
import TradersRequest from './TradersRequest';
import TrendingRequest from './TrendingRequest';
import CampaignsRequest from './CampaignsRequest';
const requestMap = {
  TransactionRequest,
  CandleRequest,
  PairRequest,
  UserRequest,
  HolderRequest,
  WalletRequest,
  OrderRequest,
  TradersRequest,
  TrendingRequest,
  CampaignsRequest,
};

const instances = {};

type RequestMap = typeof requestMap;

type RequestKey = keyof RequestMap;

export default class RequestFactory {
  static getRequest<T extends RequestKey>(
    classname: T,
  ): InstanceType<RequestMap[T]> {
    // @ts-ignore
    const RequestClass = requestMap[classname];
    if (!RequestClass) {
      throw new Error(`Invalid request class name: ${classname}`);
    }

    // @ts-ignore
    let requestInstance = instances[classname];
    if (!requestInstance) {
      requestInstance = new RequestClass();
      // @ts-ignore
      instances[classname] = requestInstance;
    }

    return requestInstance;
  }
}
