import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AppButton, AppCopy, AppLogoNetwork } from 'src/components';
import { formatNumber, formatShortAddress } from 'src/utils/format';
import {
  CheckboxIcon,
  CheckedIcon,
  MenuVertical,
  ReloadIcon,
  SuiIcon,
  AptosIcon,
} from 'src/assets/icons';
import { getLinkAddressExplorer } from 'src/utils/helper';
import { ModalConfirm } from 'src/modals/ModalConfirm';
import cx from 'classnames';
import { ModalEditWallet } from 'src/modals/ModalEditWallet';
import { TWallet } from 'src/types';
import rf from 'src/services/RequestFactory';
import { ModalImportWallet } from 'src/modals/ModalImportWallet';
import { toastError, toastSuccess } from 'src/libs/toast';
import { ModalGenerateNewWallet } from 'src/modals/ModalGenerateNewWallet';
import { NETWORKS } from 'src/utils/contants';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

const MenuWallet = ({
  wallet,
  fetchData,
  network,
}: {
  network: string;
  wallet: TWallet;
  fetchData: () => void;
}) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [isShowModalEditWallet, setIsShowModalEditWallet] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteWallet, setIsOpenConfirmDeleteWallet] =
    useState<boolean>(false);
  const menuRef = useRef<any>(null);

  const handleClickOutside = (event: Event) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const onRemoveWallet = async () => {
    try {
      await rf
        .getRequest('WalletRequest')
        .inactiveWallet(network, [wallet.address]);
      fetchData();
      toastSuccess('Success', 'Remove successfully!');
    } catch (e: any) {
      console.error(e);
      toastError('Error', e.message || 'Something went wrong!');
    }
  };

  return (
    <div className="relative" ref={menuRef}>
      <MenuVertical
        className="w-6 h-6 cursor-pointer"
        onClick={() => setShowMenu(true)}
      />
      {showMenu && (
        <div className={cx('absolute right-0 pt-[8px] z-[999] w-max')}>
          <div className="w-full border border-neutral-alpha-500 p-3 flex flex-col gap-2 bg-[#0f1018] rounded-[8px]">
            <div
              onClick={() => setIsShowModalEditWallet(true)}
              className="font-bold hover:bg-neutral-alpha-500 text-[#8d93b7] hover:text-neutral-0 rounded-[4px] cursor-pointer w-full flex justify-between items-center py-3 px-4"
            >
              Edit
            </div>
            <div
              onClick={() => {
                setIsOpenConfirmDeleteWallet(true);
                setShowMenu(false);
              }}
              className="text-red-500 font-bold hover:bg-neutral-alpha-500 text-[#8d93b7] rounded-[4px] cursor-pointer w-full flex justify-between items-center py-3 px-4"
            >
              Remove
            </div>
          </div>
        </div>
      )}

      {isShowModalEditWallet && (
        <ModalEditWallet
          network={network}
          fetchData={fetchData}
          wallet={wallet}
          onClose={() => setIsShowModalEditWallet(false)}
          isOpen={isShowModalEditWallet}
        />
      )}

      {isOpenConfirmDeleteWallet && (
        <ModalConfirm
          description={`Are you sure you want to delete this wallet?`}
          onConfirm={onRemoveWallet}
          isOpen={isOpenConfirmDeleteWallet}
          onClose={() => setIsOpenConfirmDeleteWallet(false)}
        />
      )}
    </div>
  );
};

const WalletItem = ({
  walletsSelected,
  setWalletsSelected,
  wallet,
  fetchData,
  no,
  network,
}: {
  no: number;
  network: string;
  wallet: TWallet;
  walletsSelected: string[];
  setWalletsSelected: (wallets: string[]) => void;
  fetchData: () => void;
}) => {
  const isSelected = walletsSelected.some((item) => item === wallet.address);

  const onSelectWallet = () => {
    if (isSelected) {
      const newWallets = walletsSelected.filter(
        (item) => item !== wallet.address,
      );
      setWalletsSelected(newWallets);
      return;
    }

    setWalletsSelected([...walletsSelected, wallet.address]);
  };

  return (
    <div className="flex border border-neutral-alpha-500 rounded-[8px] py-3 w-full text-[12px] hover:border-[#6a60e8]">
      <div className="px-3 w-[5%] flex justify-center">
        <div onClick={onSelectWallet} className="cursor-pointer">
          {isSelected ? (
            <CheckedIcon className="w-4" />
          ) : (
            <CheckboxIcon className="w-4" />
          )}
        </div>
      </div>
      <div className="px-3 w-[20%] flex items-center gap-2">
        {no}.
        <a
          href={getLinkAddressExplorer(network, wallet?.address)}
          className="text-blue-500"
          target="_blank"
        >
          {formatShortAddress(wallet?.address, 3, 3)}
        </a>
        <AppCopy message={wallet.address} />
      </div>
      <div className="px-3 w-[20%]">{wallet.aliasName}</div>
      <div className="px-3 w-[15%]">{formatNumber(wallet.balance || 0, 2)}</div>
      <div className="px-3 w-[15%] flex justify-center">
        <div onClick={onSelectWallet} className="cursor-pointer">
          {isSelected ? (
            <CheckedIcon className="w-4" />
          ) : (
            <CheckboxIcon className="w-4" />
          )}
        </div>
      </div>
      <div className="px-3 w-[15%] flex justify-center">
        <div onClick={onSelectWallet} className="cursor-pointer">
          {isSelected ? (
            <CheckedIcon className="w-4" />
          ) : (
            <CheckboxIcon className="w-4" />
          )}
        </div>
      </div>
      <div className="px-3 w-[5%]">
        <MenuWallet wallet={wallet} fetchData={fetchData} network={network} />
      </div>
    </div>
  );
};

export const WalletSettings = ({ onClose }: { onClose: () => void }) => {
  const [wallets, setWallets] = useState<TWallet[]>([]);
  const [walletsSelected, setWalletsSelected] = useState<string[]>([]);
  const [isOpenConfirmRemoveAll, setIsOpenConfirmRemoveAll] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteWallet, setIsOpenConfirmDeleteWallet] =
    useState<boolean>(false);
  const [isOpenImportWalletModal, setIsOpenImportWalletModal] =
    useState<boolean>(false);
  const [isOpenGenerateWalletModal, setIsOpenGenerateWalletModal] =
    useState<boolean>(false);
  const [newWallets, setNewWallets] = useState<TWallet[]>([]);
  const { network: networkActive } = useSelector(
    (state: RootState) => state.user,
  );
  const [network, setNetwork] = useState<string>(networkActive);

  const getWallets = async () => {
    try {
      const res = await rf.getRequest('WalletRequest').getWallets(network);
      setWallets(res);
    } catch (e) {
      console.error(e);
    }
  };

  const onGenerateWallet = async (numberWallets: number) => {
    try {
      const res = await rf
        .getRequest('WalletRequest')
        .getGenerateWallets(network, {
          numberWallets,
        });
      setNewWallets(res);
      await getWallets();
      setIsOpenGenerateWalletModal(true);
    } catch (e: any) {
      console.error(e);
      toastError('Error', e.message || 'Something went wrong!');
    }
  };

  const onRemoveWallet = async () => {
    try {
      await rf
        .getRequest('WalletRequest')
        .inactiveWallet(network, walletsSelected);
      getWallets().then();
      setWalletsSelected([]);
      toastSuccess('Success', 'Remove successfully!');
    } catch (e: any) {
      console.error(e);
      toastError('Error', e.message || 'Something went wrong!');
    }
  };

  const onRemoveAllWallets = async () => {
    try {
      await rf.getRequest('WalletRequest').inactiveAllWallets(network);
      getWallets().then();
      setWalletsSelected([]);
      toastSuccess('Success', 'Remove successfully!');
    } catch (e: any) {
      console.error(e);
      toastError('Error', e.message || 'Something went wrong!');
    }
  };

  useEffect(() => {
    getWallets().then();
  }, [network]);

  const totalBalance = useMemo(
    () => wallets.reduce((total, wallet) => +wallet.balance + total, 0),
    [wallets],
  );

  return (
    <div>
      <div className="flex gap-4 my-6 justify-center">
        <div
          className={`flex cursor-pointer items-center gap-2 px-4 py-2 rounded-[100px] ${
            network === NETWORKS.SUI ? 'bg-[#6a60e8]' : 'bg-neutral-alpha-100'
          }`}
          onClick={() => setNetwork(NETWORKS.SUI)}
        >
          <SuiIcon />
          Sui
        </div>
        <div
          className={`flex cursor-pointer items-center gap-2 px-4 py-2 rounded-[100px] ${
            network === NETWORKS.APTOS ? 'bg-[#6a60e8]' : 'bg-neutral-alpha-100'
          }`}
          onClick={() => setNetwork(NETWORKS.APTOS)}
        >
          <AptosIcon />
          Aptos
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4 mb-4">
        <div
          onClick={() => setIsOpenImportWalletModal(true)}
          className="border border-neutral-alpha-500 hover:bg-neutral-alpha-50 cursor-pointer rounded-[8px] text-center p-3 hover:border-[#6a60e8]"
        >
          Import Wallet
        </div>
        <div
          onClick={() => onGenerateWallet(1)}
          className="border border-neutral-alpha-500 hover:bg-neutral-alpha-50 cursor-pointer rounded-[8px] text-center p-3 hover:border-[#6a60e8]"
        >
          Generate New Wallet
        </div>
        <div
          onClick={() => onGenerateWallet(5)}
          className="border border-neutral-alpha-500 hover:bg-neutral-alpha-50 cursor-pointer rounded-[8px] text-center p-3 hover:border-[#6a60e8]"
        >
          Generate 5 Wallets
        </div>
        <div
          onClick={() => onGenerateWallet(10)}
          className="border border-neutral-alpha-500 hover:bg-neutral-alpha-50 cursor-pointer rounded-[8px] text-center p-3 hover:border-[#6a60e8]"
        >
          Generate 10 Wallets
        </div>
      </div>

      {!!walletsSelected.length && (
        <div className="flex justify-between items-center pb-4 border-b border-neutral-alpha-500">
          <div className="text-[12px] text-[#8d93b7] flex items-center gap-2">
            <CheckedIcon
              className="w-4 cursor-pointer"
              onClick={() => setWalletsSelected([])}
            />
            Unselected ({walletsSelected.length})
          </div>

          <div>
            <AppButton
              size="small"
              onClick={() => setIsOpenConfirmDeleteWallet(true)}
              variant="secondary"
              className="text-red-500 rounded-[8px]"
            >
              Delete {walletsSelected.length} wallets
            </AppButton>
          </div>
        </div>
      )}

      <div>
        <div className="flex py-3 w-full uppercase text-[12px] text-[#8d93b7]">
          <div className="px-3 w-[5%]" />
          <div className="px-3 w-[20%]">Wallet</div>
          <div className="px-3 w-[20%]">NAME</div>
          <div className="px-3 w-[15%]">Balance</div>
          <div className="px-3 w-[15%] text-center">Quick Buy</div>
          <div className="px-3 w-[15%] text-center">Quick Sell</div>
          <div className="px-3 w-[5%]" />
        </div>

        <div className="flex flex-col gap-3 max-h-[400px] overflow-auto customer-scroll">
          {wallets.map((wallet, index) => {
            return (
              <WalletItem
                no={index + 1}
                fetchData={getWallets}
                key={index}
                wallet={wallet}
                network={network}
                walletsSelected={walletsSelected}
                setWalletsSelected={setWalletsSelected}
              />
            );
          })}
        </div>
      </div>

      <div className="flex justify-between items-center my-4 bg-neutral-alpha-500 px-3 py-2 rounded-[8px]">
        <div className="text-[#8d93b7]">Total</div>
        <div className="flex gap-2 items-center">
          <div className="text-base text-[#8d93b7] flex gap-2 items-center">
            <div className="font-bold text-neutral-0">{totalBalance}</div>
            <AppLogoNetwork network={network} className="w-4 h-4" />
          </div>
          <ReloadIcon className="w-5 h-5 cursor-pointer" />
        </div>
      </div>

      <div className="flex justify-between items-center mt-8">
        <AppButton
          onClick={() => setIsOpenConfirmRemoveAll(true)}
          variant="secondary"
          className="text-red-500 rounded-[8px]"
        >
          Remove All
        </AppButton>

        <div className="flex gap-4 justify-end">
          <AppButton
            onClick={onClose}
            variant="secondary"
            className="min-w-[120px]  rounded-[8px]"
          >
            Close
          </AppButton>
          <AppButton className="min-w-[120px]  rounded-[8px]" onClick={onClose}>
            Save Settings
          </AppButton>
        </div>
      </div>

      {isOpenConfirmRemoveAll && (
        <ModalConfirm
          description="Are you sure you want to delete all wallets?"
          onConfirm={onRemoveAllWallets}
          isOpen={isOpenConfirmRemoveAll}
          onClose={() => setIsOpenConfirmRemoveAll(false)}
        />
      )}

      {isOpenGenerateWalletModal && (
        <ModalGenerateNewWallet
          wallets={newWallets}
          isOpen={isOpenGenerateWalletModal}
          onClose={() => setIsOpenGenerateWalletModal(false)}
        />
      )}

      {isOpenImportWalletModal && (
        <ModalImportWallet
          network={network}
          fetchData={getWallets}
          isOpen={isOpenImportWalletModal}
          onClose={() => setIsOpenImportWalletModal(false)}
        />
      )}

      {isOpenConfirmDeleteWallet && (
        <ModalConfirm
          description={`Are you sure you want to delete ${walletsSelected.length} wallet(s)?`}
          onConfirm={onRemoveWallet}
          isOpen={isOpenConfirmDeleteWallet}
          onClose={() => setIsOpenConfirmDeleteWallet(false)}
        />
      )}
    </div>
  );
};
