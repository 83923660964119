import React from 'react';
import { AppAvatarToken } from 'src/components';
import { copyToClipboard } from 'src/utils/copyToClipboard';
import { formatShortAddress } from 'src/utils/format';
import { Pumpfun } from 'src/assets/images';
import { useHistory } from 'react-router';
import { ROUTE_PATH } from 'src/routes';
import { CopyIcon } from 'src/assets/icons';

const Pool = () => {
  return (
    <div className="py-3 px-2 flex flex-col gap-2 border-b border-neutral-alpha-50">
      <div className="flex justify-between items-center">
        <div className="flex gap-2">
          <AppAvatarToken className="w-[35px] h-[35px]" />
          <div>
            <div className="body-sm-semibold-12 mb-[2px]">MONGX</div>
            <div
              onClick={() => copyToClipboard('22Wr...RUd')}
              className="flex items-center py-[2px] cursor-pointer gap-1 body-xs-regular-10 px-4 rounded-[4px] hover:bg-neutral-alpha-100 bg-neutral-alpha-50"
            >
              {formatShortAddress('22Wr...RUd', 3, 3)}
              <CopyIcon className="w-[12px] h-[12px]" />
            </div>
          </div>
        </div>

        <div className="px-2 py-[4px] rounded-[8px] bg-brand-900 flex items-center h-max gap-2 body-sm-semibold-12">
          <img src={Pumpfun} className="w-[18px] h-[18px]" />
          Pump
        </div>
      </div>

      <div className="flex justify-between">
        <div className="body-xs-regular-10 text-neutral-alpha-500">
          Liquidity{' '}
          <span className="text-semibold text-neutral-alpha-1000">
            $32.124K
          </span>
        </div>
        <div className="body-xs-regular-10 text-neutral-alpha-500">
          Price{' '}
          <span className="text-semibold text-neutral-alpha-1000">
            $32.124K
          </span>
        </div>
        <div className="body-xs-regular-10 text-neutral-alpha-500">
          Txns{' '}
          <span className="text-semibold text-neutral-alpha-1000">
            $32.124K
          </span>
        </div>
      </div>
    </div>
  );
};

export const PoolRelated = () => {
  const history = useHistory();
  return (
    <div className="py-3 px-2">
      <div className="flex justify-between body-sm-semibold-12">
        <div className="text-neutral-alpha-1000">Other Pool</div>
        <div
          className="text-brand-500 cursor-pointer"
          onClick={() => history.push(ROUTE_PATH.HOME)}
        >
          View All
        </div>
      </div>

      <Pool />
      <Pool />
      <Pool />
    </div>
  );
};
