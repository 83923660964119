import { FC, useEffect } from 'react';
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
  Redirect,
} from 'react-router-dom';
import {
  PoolDetail,
  NotFoundPage,
  ListPoolPage,
  TrendingPage,
  MemeScopePage,
  SettingsPage,
  OnBoardPage,
  ReferralPage,
  LeaderBoardPage,
} from './pages';
import * as React from 'react';
import {
  closeSocket,
  createSocketConnection,
  getSocketInstance,
  SOCKET_EVENTS,
  SOCKET_TYPE,
  subscribeOrder,
  subscribePublic,
} from './libs/socket';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { AppBroadcast } from 'src/libs/broadcast';
import { useInitialing } from 'src/hooks';
import { toastError, toastSuccess } from 'src/libs/toast';
import { getLinkTxExplorer } from 'src/utils/helper';
import { TWsOrder } from 'src/types/order.type';

export const ROUTE_PATH = {
  HOME: '/',
  NOT_FOUND: '/404',
  POOL_DETAIL: '/:network/:pairSlug',
  TRENDING: '/trending',
  MEME_SCOPE: '/memescope',
  SETTINGS: '/settings',
  ON_BOARD: '/onboarding',
  LEADER_BOARD: '/leaderboard',
  ASSET: '/asset',
  REFERRALS: '/referrals',
};

export const Routes: FC<RouteComponentProps> = () => {
  const { accessToken, network } = useSelector(
    (state: RootState) => state.user,
  );
  const { isInitialing } = useInitialing();

  useEffect(() => {
    createSocketConnection(SOCKET_TYPE.PUBLIC, network);
    subscribePublic();

    return () => {
      closeSocket(SOCKET_TYPE.PUBLIC);
    };
  }, [network]);

  useEffect(() => {
    createSocketConnection(SOCKET_TYPE.ORDER, network, accessToken);
    subscribeOrder();

    return () => {
      closeSocket(SOCKET_TYPE.ORDER);
    };
  }, [network, accessToken]);

  const subscribeNewOrder = () => {
    getSocketInstance(SOCKET_TYPE.ORDER)?.emit(SOCKET_EVENTS.SUBSCRIBE_ORDER);
  };

  const unsubscribeNewOrder = () => {
    getSocketInstance(SOCKET_TYPE.ORDER)?.emit(SOCKET_EVENTS.SUBSCRIBE_ORDER);
  };

  useEffect(() => {
    if (isInitialing) return;
    subscribeNewOrder();

    return () => {
      unsubscribeNewOrder();
    };
  }, [isInitialing]);

  useEffect(() => {
    if (isInitialing) return;
    const handleOrder = (data: TWsOrder) => {
      if (data.status === 'success') {
        const getMsg = () => {
          if (['quickBuy'].includes(data.orderType)) {
            return `You bought ${data.amountOut} ${data?.token?.symbol}`;
          }

          if (['quickSell'].includes(data.orderType)) {
            return `You sold ${data.amountIn} ${data?.token?.symbol}`;
          }

          return '';
        };
        toastSuccess('Success', getMsg(), {
          link: getLinkTxExplorer(data?.network, data.hash),
          text: 'View Explorer',
        });
      } else {
        toastError('Error', data?.reason || '');
      }
    };

    AppBroadcast.on(SOCKET_EVENTS.ORDER, handleOrder);

    return () => {
      AppBroadcast.remove(SOCKET_EVENTS.ORDER);
    };
  }, [isInitialing]);

  return (
    <Switch>
      <PrivateRoute
        path={ROUTE_PATH.REFERRALS}
        component={ReferralPage}
        exact={true}
      />
      <Route
        path={ROUTE_PATH.LEADER_BOARD}
        component={LeaderBoardPage}
        exact={true}
      />
      <PrivateRoute
        path={ROUTE_PATH.SETTINGS}
        component={SettingsPage}
        exact={true}
      />
      <Route path={ROUTE_PATH.HOME} component={ListPoolPage} exact={true} />
      <Route path={ROUTE_PATH.TRENDING} component={TrendingPage} exact={true} />
      <Route path={ROUTE_PATH.ON_BOARD} component={OnBoardPage} exact={true} />
      <Route
        path={ROUTE_PATH.MEME_SCOPE}
        component={MemeScopePage}
        exact={true}
      />
      <Route
        path={ROUTE_PATH.POOL_DETAIL}
        component={PoolDetail}
        exact={true}
      />
      <Route path={'*'} component={NotFoundPage} exact={false} />
    </Switch>
  );
};

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const { accessToken } = useSelector((state: RootState) => state.user);

  const canConnect = !!accessToken;

  return (
    <Route
      {...rest}
      render={(props) =>
        canConnect ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: ROUTE_PATH.HOME,
            }}
          />
        )
      }
    />
  );
};

export const RoutesHistory = withRouter(Routes);
