import config from 'src/config';
import BaseRequest from './BaseRequest';

export default class UserRequest extends BaseRequest {
  getUrlPrefix() {
    return config.referralApiUrl;
  }

  async getReferralCode() {
    const url = `/v1/users/code`;
    return this.get(url);
  }

  async registerReferralCode(params: { code: string }) {
    const url = `/v1/users/code`;
    return this.post(url, params);
  }

  async getReferralInfo() {
    const url = `/v1/users/referrals`;
    return this.get(url);
  }

  async getCommissions() {
    const url = `/referrals/commissions`;
    return this.get(url);
  }

  async getClaimRequests(params?: { page?: number; limit?: number }) {
    const url = `/referrals/claim-requests`;
    return this.get(url, params);
  }

  async createClaimRequest() {
    const url = `/referrals/claim-requests`;
    return this.post(url);
  }
}
