import * as React from 'react';
import { CheckCircle, WarningIcon, ArrowRight } from 'src/assets/icons';
import { ReactNode } from 'react';

export const AppToast: React.FC<{
  type: 'success' | 'error' | 'warning' | 'info';
  title: string;
  message: string | ReactNode;
  link?: { link: string; text: string };
}> = ({ type, title, message, link }) => {
  const getClassIcon = () => {
    if (type === 'success') {
      return 'border-green-100 bg-green-50 text-green-500';
    }

    if (type === 'info') {
      return 'border-blue-100 bg-blue-50 text-blue-500';
    }

    if (type === 'error') {
      return 'border-red-100 bg-red-50 text-red-500';
    }

    return 'border-yellow-100 bg-yellow-50 text-yellow-500';
  };

  const getClassType = () => {
    if (type === 'success') {
      return 'bg-green-500';
    }

    if (type === 'info') {
      return 'bg-blue-500';
    }

    if (type === 'error') {
      return 'bg-red-500';
    }

    return 'bg-yellow-500';
  };

  return (
    <div className="relative rounded-8 bg-brand-1000 px-4 py-3 flex gap-3">
      <div className={`p-1 rounded-8 border h-max ${getClassIcon()}`}>
        {type === 'warning' ? <WarningIcon /> : <CheckCircle />}
      </div>

      <div>
        <div className="body-small-medium text-neutral-0">{title}</div>
        {message && (
          <div className="body-small-regular text-white/70 max-w-[236px]">
            {message}
          </div>
        )}

        {link && (
          <a href={link?.link} target="_blank" className="block mt-2">
            <div className="flex gap-1 items-center text-xs text-accent-blue-500">
              {link?.text} <ArrowRight />
            </div>
          </a>
        )}
      </div>

      <div
        className={`${getClassType()} w-4 rounded-8 h-full absolute top-0 left-[-4px] z-[-1]`}
      />
    </div>
  );
};
