import * as React from 'react';
import { ROUTE_PATH } from 'src/routes';
import { useHistory, useLocation, useParams } from 'react-router';
import {
  Telegram,
  Logo,
  SearchIcon,
  BellIcon,
  StarIcon,
  SuiIcon,
  AptosIcon,
  ChevronDownIcon,
  TrendingIcon,
  CherronUpIcon,
} from 'src/assets/icons';
import config from 'src/config';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { TMeme } from 'src/types';
import { useEffect, useState } from 'react';
import { clearUser, setNetwork } from 'src/store/user.store';
import cx from 'classnames';
import { ModalSettings } from 'src/modals';
import { NETWORKS } from 'src/utils/contants';
import { AppAvatarToken } from 'src/components';
import rf from 'src/services/RequestFactory';
import { formatNumber } from 'src/utils/format';
import Marquee from 'react-fast-marquee';
import Storage from 'src/libs/storage';
import { jwtDecode } from 'jwt-decode';

const MENU = [
  {
    name: 'New Pairs',
    path: ROUTE_PATH.HOME,
    isPrivate: false
  },
  {
    name: 'Leaderboard',
    path: ROUTE_PATH.LEADER_BOARD,
    isPrivate: false
  },
  {
    name: 'Referral',
    path: ROUTE_PATH.REFERRALS,
    isPrivate: false
  },
];

const MENU_PROFILE = [
  {
    name: 'Referral Tracking',
    path: ROUTE_PATH.REFERRALS,
  },
  {
    name: 'Settings',
    path: ROUTE_PATH.SETTINGS,
  },
];

const OPTIONS_NETWORK = [
  {
    name: NETWORKS.SUI,
    icon: SuiIcon,
  },
  {
    name: NETWORKS.APTOS,
    icon: AptosIcon,
  },
];

const Profile = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [isShowModalSettings, setIsShowModalSettings] =
    useState<boolean>(false);

  const { accessToken } = useSelector((state: RootState) => state.user);
  const decodedInfo = jwtDecode(accessToken) as any;
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <>
      <div
        className="relative"
        onMouseEnter={() => setShowMenu(true)}
        onMouseLeave={() => setShowMenu(false)}
      >
        <div className="p-2 flex items-center gap-1 body-sm-semibold-12 border rounded-[6px] border-brand-900 cursor-pointer bg-brand-900 text-brand-500 ">
          <div className="max-w-[96px] truncate">
            @{decodedInfo?.displayName}
          </div>
          <ChevronDownIcon
            className={`w-4 h-4 ${
              showMenu ? 'rotate-[-180deg]' : ''
            } duration-500`}
          />
        </div>
        {showMenu && (
          <div className={cx('absolute right-0 pt-[8px] z-10 w-max')}>
            <div className="w-full border border-neutral-alpha-500 p-3 flex flex-col gap-2 bg-[#0f1018] rounded-[8px]">
              {MENU_PROFILE.map((menu, item) => {
                return (
                  <div
                    onClick={() => history.push(menu.path)}
                    key={item}
                    className="uppercase font-bold hover:bg-neutral-alpha-500 text-[#8d93b7] hover:text-neutral-0 rounded-[4px] cursor-pointer w-full flex justify-between items-center py-3 px-4"
                  >
                    {menu.name}
                  </div>
                );
              })}
              <div
                onClick={() => {
                  setIsShowModalSettings(true);
                  setShowMenu(false);
                }}
                className="uppercase font-bold hover:bg-neutral-alpha-500 text-[#8d93b7] hover:text-neutral-0 rounded-[4px] cursor-pointer w-full flex justify-between items-center py-3 px-4"
              >
                Wallets
              </div>
              <div
                onClick={() => dispatch(clearUser())}
                className="uppercase font-bold hover:bg-neutral-alpha-500 text-[#8d93b7] hover:text-neutral-0 rounded-[4px] cursor-pointer w-full flex justify-between items-center py-3 px-4"
              >
                Logout
              </div>
            </div>
          </div>
        )}
      </div>
      {isShowModalSettings && (
        <ModalSettings
          onClose={() => setIsShowModalSettings(false)}
          isOpen={isShowModalSettings}
        />
      )}
    </>
  );
};

const Network = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const { network } = useSelector((state: RootState) => state.user);
  const { network: networkPair } = useParams() as any;
  const [networkActive, setNetworkActive] = useState<string>('');

  useEffect(() => {
    if (!network) return;
    setNetworkActive(network);
  }, [network]);

  const history = useHistory();
  const dispatch = useDispatch();
  const networkSelected = OPTIONS_NETWORK.find(
    (item) => item.name === networkActive,
  ) as any;

  return (
    <div
      className="relative"
      onMouseEnter={() => setShowMenu(true)}
      onMouseLeave={() => setShowMenu(false)}
    >
      <div className="flex items-center gap-1 p-2 text-[12px] leading-[16px] border cursor-pointer uppercase border-neutral-alpha-50 bg-neutral-alpha-100 rounded-[6px]">
        {networkSelected && <networkSelected.icon className="w-4 h-4" />}
        {networkSelected?.name}
        <ChevronDownIcon
          className={`w-4 h-4 ${
            showMenu ? 'rotate-[-180deg]' : ''
          } duration-500`}
        />
      </div>
      {showMenu && (
        <div className={cx('absolute right-0 pt-[4px] z-10 w-max')}>
          <div className="w-full border border-neutral-alpha-50 p-2 flex flex-col gap-2 bg-[#0f1018] rounded-[6px]">
            {OPTIONS_NETWORK.map((network: any, item) => {
              return (
                <div
                  onClick={() => {
                    setNetworkActive(network.name);
                    if (network !== network.name && networkPair) {
                      history.push(ROUTE_PATH.HOME);
                    }
                    dispatch(setNetwork({ network: network.name }));
                    setShowMenu(false);
                  }}
                  key={item}
                  className="uppercase items-center text-[12px] hover:bg-neutral-alpha-50 text-neutral-alpha-800 hover:text-neutral-0 rounded-[4px] cursor-pointer flex gap-2 items-center py-2 px-2"
                >
                  <network.icon className="w-4 h-4" />
                  {network.name}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const TokenItem = ({ meme }: { meme: TMeme }) => {
  const history = useHistory();
  const { network } = useSelector((state: RootState) => state.user);

  const isUp = meme.pricePercentage > 0;

  return (
    <div
      onClick={() => history.push(`/${network}/${meme.slug}`)}
      className="flex items-center gap-1 px-2 py-1 rounded-[4px] cursor-pointer hover:bg-neutral-alpha-100 text-[12px] leading-[18px] font-normal"
    >
      <div className="text-neutral-alpha-700">#{meme.ranking}</div>
      <div className="w-4 h-4">
        <AppAvatarToken className="w-4 h-4" />
      </div>
      <div className="text-[12px] font-medium">{meme.symbol}</div>
      <div className={isUp ? 'text-green-500' : 'text-red-500'}>
        {isUp ? '+' : ''}
        {formatNumber(meme.pricePercentage, 2)}%
      </div>
    </div>
  );
};

const Trending = () => {
  const [memes, setMemes] = useState<TMeme[]>([]);
  const { network } = useSelector((state: RootState) => state.user);
  const history = useHistory();

  const getTrendingMeme = async () => {
    try {
      const res = await rf
        .getRequest('TrendingRequest')
        .getTrendingMeme(network);
      if (res.length < 10) {
        setMemes([...res, ...res, ...res]);
      } else {
        setMemes(res);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getTrendingMeme().then();
  }, [network]);

  return (
    <div className="p-1 rounded-[4px] bg-neutral-alpha-50 flex gap-1 items-center h-max">
      <div className="w-[22px] h-[22px]">
        <TrendingIcon />
      </div>

      <Marquee pauseOnHover className="w-[calc(100vw-1020px)] max-w-[420px]">
        <div className="flex">
          {memes.map((item, index) => {
            return <TokenItem key={index} meme={item} />;
          })}
        </div>
      </Marquee>

      <div
        className="cursor-pointer"
        onClick={() => history.push(ROUTE_PATH.TRENDING)}
      >
        <CherronUpIcon className="rotate-[90deg]" />
      </div>
    </div>
  );
};

export const Header = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { accessToken } = useSelector((state: RootState) => state.user);
  const [search, setSearch] = useState<string>('');

  const getLoginUrl = () => {
    const referralCode = Storage.getReferralCode();
    if (referralCode) {
      return `${config.link_telegram}?start=${referralCode}`;
    }
    return `${config.link_telegram}?start=login`;
  };

  return (
    <div className="px-6 flex gap-8 bg-neutral-beta-900 justify-between h-[52px] items-center border-b border-neutral-alpha-100">
      <div className="flex gap-4 items-center">
        <div
          className="cursor-pointer"
          onClick={() => history.push(ROUTE_PATH.HOME)}
        >
          <Logo />
        </div>

        <div className="flex gap-2 w-max">
          {MENU.map((menu, index) => {
            if (menu.isPrivate && !accessToken) return;
            return (
              <div
                key={index}
                onClick={() => history.push(menu.path)}
                className={`${
                  menu.path === pathname
                    ? 'text-neutral-alpha-1000 font-semibold border-b border-neutral-alpha-1000'
                    : 'text-neutral-alpha-800 font-medium'
                }  text-[14px] leading-[20px] pb-4 mb-[-15px] px-1 cursor-pointer hover:text-neutral-alpha-1000 tracking-[0.14px]`}
              >
                {menu.name}
              </div>
            );
          })}
        </div>

        <Trending />
      </div>

      <div className="flex gap-2 items-center">
        <div className="p-2 flex gap-2 items-center border border-neutral-alpha-100 rounded-[8px]">
          <SearchIcon />
          <input
            className="bg-transparent truncate w-[104px] text-[12px] leading-[16px] outline-none"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
          />
        </div>

        <div className="flex">
          <div className="flex items-center p-2 cursor-pointer">
            <BellIcon />
          </div>

          <div className="flex items-center p-2 cursor-pointer">
            <StarIcon />
          </div>
        </div>

        <Network />

        {accessToken && (
          <div className="rounded-[8px] h-[32px] cursor-pointer border-neutral-alpha-50 p-2 hover:bg-neutral-alpha-50 bg-neutral-alpha-100 text-[12px] leading-[16px] font-normal">
            Assets
          </div>
        )}

        {accessToken ? (
          <Profile />
        ) : (
          <a href={getLoginUrl()} target="_blank">
            <div className="w-max p-2 gap-2 flex items-center bg-neutral-alpha-1000 rounded-[6px] text-neutral-beta-900 font-medium text-[12px] leading-[16px]">
              <Telegram />
              Connect to Sign in
            </div>
          </a>
        )}
      </div>
    </div>
  );
};
