import * as React from 'react';
import { useEffect, useState } from 'react';
import { AppToggle } from 'src/components';

enum SPEED {
  DEFAULT,
  AUTO,
}

const TAB_SPEED = [
  {
    name: 'Default',
    value: SPEED.DEFAULT,
  },
  {
    name: 'Auto',
    value: SPEED.AUTO,
  },
];

export const QuickBuy = () => {
  const [slippage, setSlippage] = useState<number | string>('20');
  const [isFast, setIsFast] = useState<boolean>(true);
  const [speed, setSpeed] = useState<number>(SPEED.DEFAULT);
  const [priorityFee, setPriorityFee] = useState<number | string>('0.008');
  const [briberyAmount, setBriberyAmount] = useState<number | string>('0.012');

  useEffect(() => {
    if (speed === SPEED.AUTO) {
      setPriorityFee('0.008');
      setBriberyAmount('0.012');
    }
  }, [speed]);

  return (
    <div>
      <div className="pb-6 border-b border-neutral-alpha-500">
        <div className="uppercase font-bold">Slippage</div>
        <div className="text-[#8d93b7] text-[12px]">
          How much less tokens you're willing to receive from a trade due to
          price volatility.
        </div>

        <div className="flex gap-2 items-center mt-4">
          <input
            value={slippage}
            onChange={(e) => {
              setSlippage(e.target.value);
            }}
            className="px-4 border border-neutral-alpha-500 rounded-[100px] h-[42px] bg-transparent "
          />
          <div className="text-[#8d93b7]">%</div>
        </div>
      </div>

      <div className="pb-6 mt-6 border-b border-neutral-alpha-500">
        <div className="uppercase font-bold">Smart-Mev protection </div>
        <div className="text-[#8d93b7] text-[12px]">
          Choose between Fast and Secure to fully customize how you send
          transactions
        </div>
        <div className="flex gap-2 items-center mt-4">
          Fast
          <AppToggle value={isFast} onChange={() => setIsFast(!isFast)} />
          Secure
        </div>
      </div>

      <div className="pb-6 mt-6 border-b border-neutral-alpha-500">
        <div className="uppercase font-bold">Set Speed</div>
        <div className="text-[#8d93b7] text-[12px]">
          Switch between default and auto to customize the speed of your
          transactions.
        </div>

        <div className="flex gap-4 mt-3">
          {TAB_SPEED.map((tab, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  setSpeed(tab.value);
                }}
                className={`${
                  speed === tab.value
                    ? 'border-[#6a60e8]'
                    : 'border-neutral-alpha-500'
                } border px-6 py-3 rounded-[100px] cursor-pointer`}
              >
                {tab.name}
              </div>
            );
          })}
        </div>
      </div>

      <div className="pb-6 mt-6 border-b border-neutral-alpha-500">
        <div className="uppercase font-bold">Priority Fee</div>
        <div className="text-[#8d93b7] text-[12px]">
          The amount you send to validators to pick up your transaction. We
          recommend using an amount that's equal to or higher than the median.
        </div>

        <div className="flex gap-2 items-center mt-4">
          <input
            disabled={speed === SPEED.AUTO}
            value={priorityFee}
            onChange={(e) => {
              setPriorityFee(e.target.value);
            }}
            className="px-4 border border-neutral-alpha-500 rounded-[100px] h-[42px] bg-transparent disabled:text-neutral-grey-500"
          />
          <div className="text-[#8d93b7]">APT</div>
        </div>
      </div>

      <div className="pb-6 mt-6 border-b border-neutral-alpha-500">
        <div className="uppercase font-bold">Bribery Amount</div>
        <div className="text-[#8d93b7] text-[12px]">
          An additional tip to incentivize validators to pick up your
          transactions faster
        </div>

        <div className="flex gap-2 items-center mt-4">
          <input
            disabled={speed === SPEED.AUTO}
            value={briberyAmount}
            onChange={(e) => {
              setBriberyAmount(e.target.value);
            }}
            className="px-4 border border-neutral-alpha-500 rounded-[100px] h-[42px] bg-transparent disabled:text-neutral-grey-500"
          />
          <div className="text-[#8d93b7]">APT</div>
        </div>
      </div>

      <div className="pb-6 mt-6 border-b border-neutral-alpha-500">
        <div className="uppercase font-bold">Customize</div>
        <div className="text-[#8d93b7] text-[12px]">
          Customize your Quick Buy buttons with your own preset amounts.
        </div>
      </div>

      <div className="h-[40px] w-[200px] mt-4 px-4 cursor-pointer hover:bg-[#7970ea] font-medium text-[15px] flex justify-center items-center bg-[#6a60e8] rounded-[100px]">
        Save
      </div>
    </div>
  );
};
