import { createContext, ReactNode, useEffect, useState } from 'react';
import * as React from 'react';
import rf from 'src/services/RequestFactory';
import { useParams } from 'react-router';
import { TPair } from 'src/types';
import { AppBroadcast } from 'src/libs/broadcast';
import { SOCKET_EVENTS } from 'src/libs/socket';
import { formatUsdNumber } from 'src/utils/format';

type PoolContextType = {
  pair?: TPair;
  setPair?: (value: TPair) => void;
  walletsActive?: string[];
  setWalletsActive?: (wallets: string[]) => void;
};

export const PoolContext = createContext<PoolContextType>({});

export const PoolProvider = ({ children }: { children: ReactNode }) => {
  const [pair, setPair] = useState<any>({});
  const [walletsActive, setWalletsActive] = useState<string[]>([]);
  const { pairSlug, network } = useParams() as any;

  const getPair = async () => {
    try {
      const res = await rf.getRequest('PairRequest').getPair(network, pairSlug);
      setPair(res);
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    if (!!pair) {
      const tokenBase = pair?.tokenBase || {};
      const price = pair?.tokenBase?.priceUsd || 0;
      tokenBase?.symbol && price &&(document.title = `${tokenBase?.symbol?.toUpperCase()} $${formatUsdNumber(price)} USD | RaidenX`);
    } else {
      document.title = `RaidenX`
    }
  }, [pair]);

  useEffect(() => {
    getPair().then();
  }, [pairSlug, network]);
  useEffect(() => {
    const handleNewTransaction = (data: any) => {
      setPair({
        ...pair,
        tokenBase: {
          ...pair.tokenBase,
          price: data.price,
          priceUsd: data.priceUsd,
        },
      });
    };

    AppBroadcast.on(SOCKET_EVENTS.TRANSACTION, handleNewTransaction);

    return () => {
      AppBroadcast.remove(SOCKET_EVENTS.TRANSACTION);
    };
  }, [pair]);

  return (
    <PoolContext.Provider
      value={{ pair, setPair, walletsActive, setWalletsActive }}
    >
      {children}
    </PoolContext.Provider>
  );
};
