import { Timezone } from 'src/libs/charting_library/charting_library.min';
import { formatUnixTimestamp } from 'src/utils/format';
import {
  DEFAULT_INTERVAL,
  LIST_RESOLUTION_METADATA,
  LIST_RESOLUTION_VALUE_LOOKUP_MAP,
} from '../consts';
export const DEFAULT_TRADING_VIEW_INTERVAL = '1S';

export type ICandle = {
  close: number;
  high: number;
  low: number;
  open: number;
  time: number;
  volume: number;
};

// create function convert resolution to minutes
type ResolutionType =
  | '1S'
  | '1m'
  | '3m'
  | '5m'
  | '15m'
  | '30m'
  | '1h'
  | '2h'
  | '4h'
  | '6h'
  | '8h'
  | '12h'
  | '1D'
  | '3D'
  | '1W'
  | '1M'
  | 'D';

export const getCandleTime = (
  timestamp: number,
  resolutionMilliseconds: number,
): number => {
  return (
    Math.floor(timestamp / resolutionMilliseconds) * resolutionMilliseconds
  );
};

export const addTradeToLastCandle = (
  trade: any,
  lastCandle: ICandle,
  intervalInMilliseconds: number,
  chartRealtimeCallback: (candle: ICandle) => void,
): ICandle | void => {
  const tradeTime = new Date(trade.timestamp || 0).getTime();
  if (!lastCandle) {
    console.log('addTradeToLastCandle empty lastCandle', {
      lastCandle: lastCandle,
      intervalInMilliseconds: intervalInMilliseconds,
      tradeTime,
    });
    return;
  }

  console.log('addTradeToLastCandle', {
    lastCandleTime: formatUnixTimestamp(lastCandle.time),
    intervalInMilliseconds: intervalInMilliseconds,
    tradeTime: formatUnixTimestamp(tradeTime),
    tradePrice: trade.price,
  });
  const tradePrice = Number(trade.price);
  const volume = !!trade.quantity ? +trade.quantity : 0;

  const candleTimeOfTrade = getCandleTime(tradeTime, intervalInMilliseconds);
  const lastCandleTimeOfChart = getCandleTime(
    lastCandle.time,
    intervalInMilliseconds,
  );

  if (candleTimeOfTrade > lastCandleTimeOfChart) {
    console.log('================= create new candle', {
      tradeTime: formatUnixTimestamp(tradeTime),
      lastCandleEndTime: formatUnixTimestamp(lastCandle.time),
      x: getCandleTime(tradeTime, intervalInMilliseconds),
    });
    fillCompletedCandles(
      lastCandle,
      intervalInMilliseconds,
      candleTimeOfTrade,
      chartRealtimeCallback,
    );
    const newCandle: ICandle = {
      open: Number(lastCandle.close),
      close: tradePrice,
      high: Math.max(tradePrice, lastCandle.close),
      low: Math.min(tradePrice, lastCandle.close),
      time: getCandleTime(tradeTime, intervalInMilliseconds),
      volume: volume,
    };
    chartRealtimeCallback(newCandle);
    return newCandle;
  } else if (candleTimeOfTrade === lastCandleTimeOfChart) {
    console.log('================= update current candle', {
      tradeTime: formatUnixTimestamp(tradeTime),
      lastCandleEndTime: formatUnixTimestamp(lastCandle.time),
      x: getCandleTime(tradeTime, intervalInMilliseconds),
    });
    lastCandle.low = Math.min(tradePrice, lastCandle.low);
    lastCandle.high = Math.max(tradePrice, lastCandle.high);
    lastCandle.close = tradePrice;
    lastCandle.volume += volume;
    chartRealtimeCallback(lastCandle);
    return lastCandle;
  }
};

export const createEmptyCandleIfNeeded = (
  lastCandle: ICandle,
  intervalInMilliseconds: number,
  chartRealtimeCallback: (candle: ICandle) => void,
): ICandle | void => {
  if (!lastCandle) {
    return;
  }
  const completedCandleTime =
    getCandleTime(Date.now(), intervalInMilliseconds) - intervalInMilliseconds;
  if (completedCandleTime > lastCandle.time) {
    console.log('add new empty at candle time', {
      completedCandleTime: formatUnixTimestamp(completedCandleTime),
    });
    const newCandle: ICandle = {
      open: lastCandle.close,
      close: lastCandle.close,
      high: lastCandle.close,
      low: lastCandle.close,
      time: completedCandleTime,
      volume: 0,
    };
    if (chartRealtimeCallback) {
      chartRealtimeCallback(newCandle);
    }
    return newCandle;
  }
  return lastCandle;
};

const fillCompletedCandles = (
  previousCandle: ICandle,
  resolutionMilliseconds: number,
  to: number,
  chartRealtimeCallback: (candle: ICandle) => void,
): void => {
  const toCandleTime = getCandleTime(to, resolutionMilliseconds);
  for (
    let time = previousCandle.time + resolutionMilliseconds;
    time < toCandleTime;
    time += resolutionMilliseconds
  ) {
    const newCandle: ICandle = {
      open: previousCandle.close,
      close: previousCandle.close,
      high: previousCandle.close,
      low: previousCandle.close,
      time,
      volume: 0,
    };
    console.log('createCompletedCandles', formatUnixTimestamp(time));
    chartRealtimeCallback(newCandle);
  }
};

export function getClientTimezone(): Timezone {
  const timezones: { [key: string]: number } = {
    'America/New_York': -5,
    'America/Los_Angeles': -8,
    'America/Chicago': -6,
    'America/Phoenix': -7,
    'America/Toronto': -5,
    'America/Vancouver': -8,
    'America/Argentina/Buenos_Aires': -3,
    'America/El_Salvador': -6,
    'America/Sao_Paulo': -3,
    'America/Bogota': -5,
    'America/Caracas': -4,
    'Europe/Moscow': 3,
    'Europe/Athens': 2,
    'Europe/Belgrade': 1,
    'Europe/Berlin': 1,
    'Europe/London': 0,
    'Europe/Luxembourg': 1,
    'Europe/Madrid': 1,
    'Europe/Paris': 1,
    'Europe/Rome': 1,
    'Europe/Warsaw': 1,
    'Europe/Istanbul': 3,
    'Europe/Zurich': 1,
    'Australia/Sydney': 10,
    'Australia/Brisbane': 10,
    'Australia/Adelaide': 9.5,
    'Australia/ACT': 10,
    'Asia/Almaty': 6,
    'Asia/Ashkhabad': 5,
    'Asia/Tokyo': 9,
    'Asia/Taipei': 8,
    'Asia/Singapore': 8,
    'Asia/Shanghai': 8,
    'Asia/Seoul': 9,
    'Asia/Tehran': 3.5,
    'Asia/Dubai': 4,
    'Asia/Kolkata': 5.5,
    'Asia/Hong_Kong': 8,
    'Asia/Bangkok': 7,
    'Asia/Chongqing': 8,
    'Asia/Jerusalem': 2,
    'Asia/Kuwait': 3,
    'Asia/Muscat': 4,
    'Asia/Qatar': 3,
    'Asia/Riyadh': 3,
    'Pacific/Auckland': 12,
    'Pacific/Chatham': 12.75,
    'Pacific/Fakaofo': 13,
    'Pacific/Honolulu': -10,
    'America/Mexico_City': -6,
    'Africa/Cairo': 2,
    'Africa/Johannesburg': 2,
    'Asia/Kathmandu': 5.75,
    'US/Mountain': -7,
  };

  const timezone = (new Date().getTimezoneOffset() * -1) / 60;
  for (const key in timezones) {
    if (timezones[key] == timezone) {
      return key as Timezone;
    }
  }
  return 'Etc/UTC';
}

export const convertResolution2Minutes = (
  interval: ResolutionType | undefined,
): number => {
  return getResolution(interval || DEFAULT_INTERVAL).valueCompare;
};

export const convertResolution2Seconds = (
  interval: ResolutionType | undefined,
): number => {
  return getResolution(interval || DEFAULT_INTERVAL).valueCompare * 60;
};

export const convertResolution2MilliSeconds = (
  interval: ResolutionType | undefined,
): number => {
  return getResolution(interval || DEFAULT_INTERVAL).valueCompare * 60 * 1000;
};

export const getResolutionInterval = (
  value: ResolutionType | undefined,
): string => {
  return getResolution(value || DEFAULT_INTERVAL).value;
};

export const getResolution = (input: keyof typeof LIST_RESOLUTION_METADATA) => {
  // Check if input matches any key
  if (!!LIST_RESOLUTION_METADATA[input]) {
    return LIST_RESOLUTION_METADATA[input];
  }

  // Check if input matches any value
  if (LIST_RESOLUTION_VALUE_LOOKUP_MAP[input]) {
    return LIST_RESOLUTION_VALUE_LOOKUP_MAP[input];
  }

  // Return null if no match is found
  return LIST_RESOLUTION_METADATA[DEFAULT_INTERVAL];
};

export const getMaxYAxis = (max: number | string) => {
  const convertNumber = Number(max);
  if (!convertNumber)
    return {
      max: 10,
      decimalPlace: 0,
    };

  let result = 0;
  let exp = 0;
  let decimalPlace = 0;
  const log10: number = Math.log10(convertNumber);

  if (convertNumber >= 1) {
    exp = Math.ceil(log10);
    result = Math.pow(10, exp);
    decimalPlace = 1;
  } else {
    exp = Math.abs(Math.floor(log10)) - 1;
    result = Number(Math.pow(10, -exp).toFixed(exp));
    decimalPlace = Math.abs(Math.floor(log10)) + 2;
  }
  // result to find the nearest power of 10 greater than 1 number

  // Compare to find nearest
  if (convertNumber < result / 4) {
    result = result / 4;
  } else if (convertNumber < result / 2) {
    result = result / 2;
  } else {
    result = result;
  }

  return {
    max: result,
    decimalPlace,
  };
};
