import { BaseModal } from 'src/modals/BaseModal';
import React, { useEffect, useState } from 'react';
import { AppButton } from 'src/components';

export const ModalFilterMarker = ({
  isOpen,
  onClose,
  params,
  setParams,
}: {
  isOpen: boolean;
  onClose: () => void;
  params: any;
  setParams: (data: any) => void;
}) => {
  const [address, setAddress] = useState<string>('');

  useEffect(() => {
    setAddress(params.makerAddress);
  }, [params.makerAddress]);

  return (
    <BaseModal isOpen={isOpen} title={'Filter Marker'} onClose={onClose}>
      <div>
        <input
          placeholder="Enter Wallet Address"
          value={address}
          onChange={(e) => setAddress(e.target.value.trim())}
          className="border border-neutral-alpha-500 outline-none bg-transparent rounded-[100px] px-4 py-3 flex items-center w-full"
        />
      </div>

      <div className="flex gap-4 justify-center mt-8">
        <AppButton
          onClick={() => {
            setAddress('');
            setParams({
              ...params,
              makerAddress: '',
            });
            onClose();
          }}
          variant="secondary"
          className="min-w-[120px]"
        >
          Clear
        </AppButton>
        <AppButton
          className="min-w-[120px]"
          onClick={() => {
            setParams({
              ...params,
              makerAddress: address,
            });
            onClose();
          }}
        >
          Apply
        </AppButton>
      </div>
    </BaseModal>
  );
};
