import React, { ReactNode } from 'react';

export const AppButton = ({
  children,
  onClick,
  size = 'medium',
  className,
  variant = 'primary',
}: {
  children: ReactNode;
  onClick?: () => void;
  className?: string;
  variant?: 'primary' | 'secondary';
  size?: 'small' | 'medium' | 'large';
}) => {
  const getClassByVariant = () => {
    switch (variant) {
      case 'primary': {
        return 'bg-[#6a60e8] hover:bg-[#7970ea]';
      }
      case 'secondary': {
        return 'bg-neutral-grey-700 hover:bg-neutral-grey-800';
      }
      default: {
        return 'bg-primary-500 hover:bg-[#7970ea]';
      }
    }
  };

  const getClassSize = () => {
    switch (size) {
      case 'medium': {
        return 'px-4 py-3 text-[14px]';
      }
      case 'small': {
        return 'px-3 py-1 text-[12px]';
      }
      default: {
        return 'px-4 py-3';
      }
    }
  };

  return (
    <div
      onClick={() => {
        onClick && onClick();
      }}
      className={` ${getClassSize()} rounded-[100px] cursor-pointer text-center ${getClassByVariant()} ${className}`}
    >
      {children}
    </div>
  );
};
