import { ChevronDownIcon, IconSettings } from 'src/assets/icons';
import * as React from 'react';
import { useState } from 'react';
import { AppButton, AppDropdown } from 'src/components';
import { OrderFormSetting, TOrderSetting } from './OrderFormSetting';
import { NumericFormat } from 'react-number-format';
import rf from 'src/services/RequestFactory';
import { toastError, toastInfo } from 'src/libs/toast';
import { useContext } from 'react';
import { PoolContext } from 'src/pages/pool/context';
import { TPair } from 'src/types';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { filterParams } from 'src/utils/helper';

const INPUT_TYPE = {
  AMOUNT: 'AMOUNT',
  PERCENT: 'PERCENT',
};

const DEFAULT_PERCENT = [25, 50, 100];

const InputAmount = ({
  amount,
  setAmount,
  percent,
  setPercent,
  pair,
}: {
  pair: TPair;
  amount: string | number;
  setAmount: (value: any) => void;
  percent: string | number;
  setPercent: (value: any) => void;
}) => {
  const INPUT_OPTIONS = [
    {
      name: pair?.tokenQuote?.symbol || '',
      value: INPUT_TYPE.AMOUNT,
    },
    {
      name: '%',
      value: INPUT_TYPE.PERCENT,
    },
  ];

  const [type, setType] = useState<string>(INPUT_TYPE.PERCENT);

  return (
    <div className="mb-4 mt-3 flex items-center gap-2 ">
      <div className="text-[#8d93b7]">or</div>

      <div className="border border-neutral-alpha-500 flex gap-2 rounded-[100px] pl-2">
        <AppDropdown
          value={type}
          options={INPUT_OPTIONS}
          onSelect={(value: string) => {
            setType(value);
            if (value === INPUT_TYPE.AMOUNT) {
              setPercent('');
            } else {
              setAmount('');
            }
          }}
          className="min-w-[70px]"
        />

        {type === INPUT_TYPE.AMOUNT ? (
          <NumericFormat
            placeholder={`Amount to sell in ${pair?.tokenQuote?.symbol || ''}`}
            value={amount ?? ''}
            allowLeadingZeros
            allowNegative={false}
            thousandSeparator=","
            decimalScale={6}
            className="h-10 px-4 border-neutral-alpha-500 placeholder:text-[#8d93b7] bg-transparent outline-none border-l w-full"
            onValueChange={({ floatValue }) => {
              setAmount(floatValue?.toString());
              setPercent('');
            }}
          />
        ) : (
          <NumericFormat
            placeholder={'Percentage to sell'}
            value={percent ?? ''}
            allowLeadingZeros
            allowNegative={false}
            thousandSeparator=","
            decimalScale={2}
            className="h-10 px-4 border-neutral-alpha-500 placeholder:text-[#8d93b7] bg-transparent outline-none border-l w-full"
            onValueChange={({ floatValue }) => {
              return setPercent(floatValue);
            }}
          />
        )}
      </div>
    </div>
  );
};

export const OrderFormSellNow = () => {
  const [sellAmount, setSellAmount] = useState<string | number>('');
  const [sellPercent, setSellPercent] = useState<string | number>('');
  const [isShowAdvancedSettings, setIsShowAdvancedSettings] =
    useState<boolean>(false);
  const [orderSetting, setOrderSetting] = useState<TOrderSetting>({
    slippage: 20,
    priorityFee: '0.008',
  });

  const { accessToken } = useSelector((state: RootState) => state.user);
  const { pair } = useContext(PoolContext) as { pair: TPair };
  const { walletsActive } = useContext(PoolContext);

  const createOrder = async () => {
    try {
      if (!accessToken) {
        throw Error('Please login!');
      }

      if (!sellPercent && !sellAmount) {
        throw Error('Please input amount!');
      }

      if (!walletsActive?.length) {
        throw Error('Please select a wallet to start trading!');
      }

      await rf.getRequest('OrderRequest').createOrderQuickSell(
        pair.network,
        filterParams({
          orderSetting,
          pairId: pair?.pairId,
          sellAmount,
          sellPercent,
          tokenAddress: pair?.tokenBase?.address,
          wallets: walletsActive,
        }),
      );
      setSellAmount('');
      setSellPercent('');
      toastInfo('Submitting', 'Submitting Order!');
    } catch (e: any) {
      console.error(e);
      toastError('Error', e.message || 'Something went wrong!');
    }
  };

  return (
    <div>
      <div className="flex gap-3 mt-4 flex-wrap">
        {DEFAULT_PERCENT.map((item, index) => {
          return (
            <div
              className={`px-4 h-9 flex items-center py-1 cursor-pointer w-[80px] border ${
                item === +sellPercent
                  ? 'border-[#6a60e8] text-[#8e9dff]'
                  : 'border-neutral-alpha-500'
              } rounded-[100px]`}
              key={index}
              onClick={() => {
                setSellAmount('');
                setSellPercent(item);
              }}
            >
              {item}%
            </div>
          );
        })}
      </div>

      <InputAmount
        amount={sellAmount}
        setAmount={setSellAmount}
        percent={sellPercent}
        setPercent={setSellPercent}
        pair={pair}
      />

      <div
        className={`${
          isShowAdvancedSettings ? 'border-t pt-3' : 'border-y py-3'
        } flex hover:text-neutral-0 font-bold text-[13px] text-[#8d93b7] justify-between items-center w-full cursor-pointer border-neutral-alpha-500`}
        onClick={() => setIsShowAdvancedSettings(!isShowAdvancedSettings)}
      >
        <div className="flex gap-2 items-center">
          <IconSettings />
          Advanced Settings
        </div>
        <div>
          <ChevronDownIcon
            className={`${
              isShowAdvancedSettings ? 'rotate-[180deg]' : ' '
            } w-4 h-4`}
          />
        </div>
      </div>

      {isShowAdvancedSettings && (
        <OrderFormSetting
          setOrderSetting={setOrderSetting}
          orderSetting={orderSetting}
        />
      )}

      <AppButton className="mt-4" onClick={createOrder}>
        Quick Sell
      </AppButton>

      <div className="text-[12px] text-[#8d93b7] mt-3">
        Estimation of expected payout incl. price impact and fees is only
        enabled for Raydium AMM. Once you click on <b>Quick Sell</b>, your
        transaction is sent immediately.
      </div>
    </div>
  );
};
