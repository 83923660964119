import { BaseModal } from '../BaseModal';
import React, { useState } from 'react';
import { WalletSettings, TradeSettings } from 'src/modals/ModalSettings/parts';

const TAB = {
  WALLET: 'WALLET',
  TRADE: 'TRADE',
};

export const ModalSettings = ({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) => {
  const [tab, setTab] = useState<string>(TAB.WALLET);
  return (
    <BaseModal isOpen={isOpen} title={'Settings'} onClose={onClose}>
      <div className="grid grid-cols-2 mb-4">
        <div
          className={`text-center py-3 cursor-pointer ${
            tab === TAB.WALLET ? 'bg-[#6a60e8]' : 'bg-neutral-alpha-500'
          }`}
          onClick={() => setTab(TAB.WALLET)}
        >
          Wallet
        </div>
        <div
          className={`text-center py-3 cursor-pointer ${
            tab === TAB.TRADE ? 'bg-[#6a60e8]' : 'bg-neutral-alpha-500'
          }`}
          onClick={() => setTab(TAB.TRADE)}
        >
          Trade
        </div>
      </div>

      <div className="w-[700px]">
        {tab === TAB.WALLET ? (
          <WalletSettings onClose={onClose} />
        ) : (
          <TradeSettings />
        )}
      </div>
    </BaseModal>
  );
};
