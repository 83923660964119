import cx from 'classnames';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { ChevronDownIcon } from 'src/assets/icons';

export const AppDropdown: React.FC<{
  options: { name: string; value: string }[];
  onSelect: (value: string) => void;
  value?: string;
  className?: string;
}> = ({ options, onSelect, value, className }) => {
  const [selected, setSelected] = useState<any>(options[0]);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  useEffect(() => {
    const optionSelected = options.find((item) => item.value === value);
    setSelected(optionSelected);
  }, [value, options]);

  return (
    <div
      className={cx('relative', className)}
      onMouseEnter={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
    >
      <div
        className={cx(
          'flex flex-row items-center justify-between',
          'p-2 rounded-[8px] gap-2',
          'cursor-pointer w-max',
          className,
        )}
      >
        <div className="body-small-regular">{selected?.name}</div>
        <ChevronDownIcon
          className={`w-[14px] h-[14px] duration-500 ${
            showDropdown ? 'rotate-[-180deg]' : 'rotate-0'
          }`}
        />
      </div>

      {showDropdown && (
        <div className={cx('absolute right-0 pt-[8px] z-10 w-max', className)}>
          <div className="w-full border border-neutral-alpha-500 p-2 bg-[#0f1018] rounded-[8px]">
            {options.map((option, index) => {
              const isActive = option.value === value;
              return (
                <div
                  key={index}
                  className={cx(
                    'flex justify-between items-center py-2 px-3',
                    'cursor-pointer w-full',
                    'rounded-[4px]',
                    'hover:bg-neutral-alpha-500 text-neutral-0',
                    isActive ? 'bg-neutral-alpha-500' : '',
                  )}
                  onClick={() => {
                    setSelected(option);
                    onSelect(option.value);
                    setShowDropdown(false);
                  }}
                >
                  {option.name}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
