import axios from 'axios';
import config from 'src/config';
import { EInternalEvent, AppBroadcast } from 'src/libs/broadcast';
import { setAuthorizationToRequest } from 'src/utils/authenticate';

export default class BaseRequest {
  protected accessToken = '';
  constructor(accessToken?: string | undefined) {
    if (accessToken) {
      this.accessToken = accessToken;
      setAuthorizationToRequest(accessToken);
    }
  }

  getUrlPrefix() {
    return config.apiUrl;
  }

  async get(url: string, params?: any) {
    try {
      const config = {
        params,
      };
      const response = await axios.get(this.getUrlPrefix() + url, config);
      return this._responseHandler(response);
    } catch (error) {
      return this._errorHandler(error);
    }
  }

  async put(url: any, data?: any) {
    try {
      const response = await axios.put(this.getUrlPrefix() + url, data);
      return this._responseHandler(response);
    } catch (error) {
      return this._errorHandler(error);
    }
  }

  async patch(url: any, data?: any) {
    try {
      const response = await axios.patch(this.getUrlPrefix() + url, data);
      return this._responseHandler(response);
    } catch (error) {
      return this._errorHandler(error);
    }
  }

  async post(url: any, data = {}, options?: any) {
    try {
      const response = await axios.post(
        this.getUrlPrefix() + url,
        data,
        options,
      );
      return this._responseHandler(response);
    } catch (error) {
      return this._errorHandler(error);
    }
  }

  async delete(url: any, data?: any) {
    try {
      const config = {
        data,
      };
      const response = await axios.delete(this.getUrlPrefix() + url, config);
      return this._responseHandler(response);
    } catch (error) {
      return this._errorHandler(error);
    }
  }

  async download(url: any, data?: any) {
    try {
      const config = {
        ...data,
        responseType: 'blob',
      };
      const response = await axios.get(this.getUrlPrefix() + url, config);
      return this._responseHandler(response);
    } catch (error) {
      return this._errorHandler(error);
    }
  }

  async _responseHandler(response: any) {
    return response.data;
  }

  _error401Handler() {
    AppBroadcast.dispatch(EInternalEvent.LOGOUT, {});
    throw Error('Unauthorized');
  }

  _error403Handler() {
    // TODO: make broadcast event
    AppBroadcast.dispatch(EInternalEvent.LOGOUT, {});
  }

  async _errorHandler(err: any) {
    if (err.response?.status === 401) {
      return this._error401Handler();
    }

    if (err.response?.status === 403) {
      return this._error403Handler();
    }

    if (err.response && err.response.data && err.response.data.data) {
      if (typeof err.response.data.data.message === 'string') {
        throw new Error(err.response.data.data.message);
      }
    }

    if (err.response && err.response.data && err.response.data.message) {
      if (typeof err.response.data.message === 'string') {
        throw new Error(err.response.data.message);
      }
      throw new Error(err.response.data.message[0]);
    }

    if (err.response && err.response.data && err.response.data.error) {
      throw new Error(err.response.data.error);
    }

    throw err;
  }
}
