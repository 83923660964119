import { Bluemove, Liquidswap, Cetus, Movepump } from 'src/assets/images';

interface IDex {
  [key: string]: {
    id: string;
    name: string;
    logoUrl: string;
    website: string;
  };
}

export const CATEGORY_MARKER = {
  SHRIMP: 'shrimp',
  WHALE: 'whale',
  DOLPHIN: 'dolphin',
  PLANKTON: 'plankton',
  FISH: 'fish',
};

export const NETWORKS = {
  SUI: 'sui',
  APTOS: 'aptos',
};

export const DEXS = {
  cetus: {
    id: 'cetus',
    name: 'Cetus',
    logoUrl: Cetus,
    website: 'https://app.cetus.zone/swap',
  },
  bluemove: {
    id: 'bluemove',
    name: 'BlueMove',
    logoUrl: Bluemove,
    website: 'https://sui.bluemove.net/inscription/swap',
  },
  movepump: {
    id: 'movepump',
    name: 'Move Pump',
    logoUrl: Movepump,
    website: 'https://movepump.com/swap',
  },
  liquids: {
    id: 'liquids',
    name: 'Liquidswap',
    logoUrl: Liquidswap,
    website: 'https://liquidswap.com',
  },
} as IDex;
