import React, { useState } from 'react';
import { AppToggle } from 'src/components';

export const MyHoldings = ({isExpand}: {isExpand?: boolean}) => {
  const [isShowHidden, setIsShowHidden] = useState<boolean>(false);
  return (
    <div className="mt-4">
      <div className="flex item gap-3">
        <AppToggle
          value={isShowHidden}
          onChange={() => setIsShowHidden(!isShowHidden)}
        />
        Show Hidden
      </div>
      <div className="grid grid-cols-6 uppercase py-3 text-[12px] mr-[6px] text-[#8d93b7]">
        <div className="px-3">Token</div>
        <div className="px-3">invested</div>
        <div className="px-3">Remaining</div>
        <div className="px-3">Sold</div>
        <div className="px-3">Change in P&L</div>
        <div className="px-3" />
      </div>
      <div className="flex justify-center mt-10 text-[#8d93b7]">
        You currently do not have any token holdings in your Dex trading wallet.
      </div>
    </div>
  );
};
