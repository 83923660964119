import * as React from 'react';
import { toast } from 'react-toastify';
import { AppToast } from 'src/components';

export const toastSuccess = (
  title: string,
  message: string,
  link?: { link: string; text: string },
) => {
  toast(
    React.createElement(
      AppToast,
      { type: 'success', title, message, link },
      null,
    ),
  );
};

export const toastError = (title: string, message: string) => {
  toast(React.createElement(AppToast, { type: 'error', title, message }, null));
};

export const toastInfo = (title: string, message: string) => {
  toast(React.createElement(AppToast, { type: 'info', title, message }, null));
};
