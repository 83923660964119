import { copyToClipboard } from 'src/utils/copyToClipboard';
import { CopyIcon } from 'src/assets/icons';
import React from 'react';

export const AppCopy = ({
  className,
  message,
}: {
  className?: string;
  message: string;
}) => {
  return (
    <CopyIcon
      className={`w-4 cursor-pointer ${className}`}
      onClick={() => copyToClipboard(message)}
    />
  );
};
