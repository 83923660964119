import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ArrowDownIcon, TickSuccess, ZoomIn, ZoomOut } from 'src/assets/icons';
import {
  CANDLE_TYPE,
  CANDLE_UNIT,
  DEFAULT_LIST_INTERVAL_METADATA,
  intervalMasterArr,
  LIST_RESOLUTION_METADATA,
} from 'src/components/TradingView/consts';
import Storage from 'src/libs/storage';
import * as _ from 'lodash';
import { TPair } from 'src/types';

interface INavBar {
  handleFullscreen: () => void;
  isFullScreen: boolean;
  onSelectInterval: any;
  onSelectCandleType: (value: string) => void;
  candleType: string;
  candleUnit: string;
  setCandleUnit: (value: string) => void;
  pair: TPair;
}

interface IntervalObj {
  key: string;
  value: string;
}

const DEFAULT_INTERVAL_METADATA = LIST_RESOLUTION_METADATA['1S'];

const GroupIntervalTime = ({ setInterval, setIntervalToList }: any) => {
  const [isShowGroupInterval, setIsShowGroupInterval] =
    useState<boolean>(false);
  const [isEditBtn, setIsEditBtn] = useState<boolean>(true);
  const groupIntervalRef = useRef<any>(null);

  const onEdit = () => {
    setIsEditBtn(!isEditBtn);
    if (!isEditBtn) {
      setIsShowGroupInterval(!isShowGroupInterval);
    }
  };

  const handleClickOutside = (event: Event) => {
    if (
      groupIntervalRef.current &&
      !groupIntervalRef.current.contains(event.target as Node)
    ) {
      setIsShowGroupInterval(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <div className="relative">
      <div
        onClick={() => setIsShowGroupInterval(true)}
        className="min-w-9 flex justify-center items-center rounded-[4px] h-8 cursor-pointer hover:bg-neutral-alpha-50"
      >
        <ArrowDownIcon fill="#A0A3BD" />
      </div>
      {isShowGroupInterval && (
        <div
          ref={groupIntervalRef}
          className="absolute p-3 bg-neutral-800 border border-neutral-alpha-500 z-[99] rounded-[8px] w-[220px]"
        >
          <div className="flex justify-between text-[13px] mb-3">
            <div>Select Interval</div>
            <div onClick={onEdit} className="cursor-pointer">
              {isEditBtn ? 'Edit' : 'Save'}
            </div>
          </div>
          {intervalMasterArr.map((row, idx) => {
            return (
              <div className="flex flex-wrap gap-2 mt-2" key={idx}>
                {row.row.map((it) => {
                  return (
                    <div
                      key={it.key}
                      className={`relative p-2 ${
                        it.status
                          ? 'bg-neutral-grey-800'
                          : 'bg-neutral-alpha-50 hover:bg-neutral-grey-300'
                      }  leading-none w-[44px] text-[12px] text-center rounded-[4px] cursor-pointer`}
                      onClick={() => {
                        !isEditBtn ? setIntervalToList(it) : setInterval(it);
                      }}
                    >
                      <div>{it.key}</div>
                      {!isEditBtn && it.status && (
                        <TickSuccess className="absolute top-[-5px] right-0" />
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const CANDLE_TYPE_OPTIONS = [
  {
    name: 'Price',
    value: CANDLE_TYPE.PRICE,
  },
  {
    name: 'MCap',
    value: CANDLE_TYPE.MCAP,
  },
];

export const NavBar = ({
  handleFullscreen,
  isFullScreen,
  onSelectInterval,
  candleType,
  onSelectCandleType,
  candleUnit,
  setCandleUnit,
  pair,
}: INavBar) => {
  const [intervalList, setIntervalList] = useState<Array<IntervalObj>>(
    DEFAULT_LIST_INTERVAL_METADATA,
  );
  const [currentInterval, setCurrentInterval] = useState(
    LIST_RESOLUTION_METADATA['1S'],
  );

  const selectedTimeFrame = Storage.getTimeFrame();

  const UNIT_OPTIONS = [
    {
      name: 'USD',
      value: CANDLE_UNIT.USD,
    },
    {
      name: pair?.tokenQuote?.symbol,
      value: CANDLE_UNIT.TOKEN_QUOTE,
    },
  ];

  useEffect(() => {
    if (selectedTimeFrame && !!LIST_RESOLUTION_METADATA[selectedTimeFrame]) {
      setInterval(LIST_RESOLUTION_METADATA[selectedTimeFrame]);
    }
  }, [selectedTimeFrame]);

  const setInterval = (param: any) => {
    setCurrentInterval(param);
    onSelectInterval(param.value);
    Storage.setTimeFrame(param.key);
  };

  const isShowCurrentResolution = useMemo(() => {
    const intervalDefault = intervalList.slice(0, 7);
    return !intervalDefault.some((interval) =>
      interval.value.includes(currentInterval.value),
    );
  }, [intervalList, currentInterval]);

  const setIntervalToList = (intervalObj: IntervalObj) => {
    intervalMasterArr.map((row) => {
      row.row.map((item) => {
        if (item.key === intervalObj.key) {
          item.status = !item.status;
          if (item.status) {
            setIntervalList((intervalArr) =>
              _.sortBy([...intervalArr, item], 'valueCompare'),
            );
          } else {
            setIntervalList(
              intervalList.filter((item) => item.key !== intervalObj.key),
            );
          }
        }
      });
    });
  };

  return (
    <div className="p-3 bg-[#0f1018] flex justify-between">
      <div className="flex gap-1 items-center">
        {intervalList.slice(0, 7).map((interval, idx) => {
          const isActive =
            interval.value.toLowerCase() ===
            currentInterval?.value?.toLowerCase();
          return (
            <div
              className={`min-w-9 text-[12px] h-8 cursor-pointer hover:bg-neutral-alpha-50 flex justify-center items-center rounded-[4px] ${
                isActive ? 'text-white' : 'text-neutral-400'
              }`}
              onClick={() => {
                setInterval(interval);
              }}
              key={idx}
            >
              {interval.key}
            </div>
          );
        })}

        {isShowCurrentResolution && (
          <div className="text-white text-[12px] min-w-9 text-center">
            {currentInterval.key}
          </div>
        )}

        <GroupIntervalTime
          setInterval={setInterval}
          setIntervalToList={setIntervalToList}
        />

        <div className="flex items-center leading-none text-sm ml-2 pl-2 border-l border-neutral-alpha-500 text-[#828282]">
          {CANDLE_TYPE_OPTIONS.map((item, index) => {
            return (
              <div key={index} className="flex">
                {index === 1 && <div className="mx-1">/</div>}
                <div
                  className={`cursor-pointer ${
                    candleType === item.value ? 'text-neutral-0' : ''
                  }`}
                  onClick={() => {
                    setInterval(DEFAULT_INTERVAL_METADATA);
                    onSelectCandleType(item.value);
                  }}
                >
                  {item.name}
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex items-center leading-none text-sm ml-2 pl-2 border-l border-neutral-alpha-500 text-[#828282]">
          {UNIT_OPTIONS.map((item, index) => {
            return (
              <div key={index} className="flex">
                {index === 1 && <div className="mx-1">/</div>}
                <div
                  className={`cursor-pointer ${
                    candleUnit === item.value ? 'text-neutral-0' : ''
                  }`}
                  onClick={() => {
                    setInterval(DEFAULT_INTERVAL_METADATA);
                    setCandleUnit(item.value);
                  }}
                >
                  {item.name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div
        className="w-8 h-8 cursor-pointer hover:bg-neutral-alpha-50 flex justify-center items-center rounded-[4px]"
        onClick={handleFullscreen}
      >
        <div>{isFullScreen ? <ZoomIn /> : <ZoomOut />}</div>
      </div>
    </div>
  );
};
