import {
  AreaChart,
  BarsChart,
  BaseLine,
  CandleChart,
  HeikinAshi,
  HollowCandle,
  LineChart,
} from 'src/assets/icons';

export enum THEME_MODE {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum CANDLE_TYPE {
  PRICE = 'price',
  MCAP = 'marketCap',
  PRICE_USD = 'priceUSD',
  MCAP_USD = 'marketCapUSD',
}

export enum CANDLE_UNIT {
  USD = 'USD',
  TOKEN_QUOTE = 'TOKEN_QUOTE',
}

export enum SYMBOL_TYPE {
  stock = 'stock',
  bitcoin = 'bitcoin',
}

export const LIST_RESOLUTION_METADATA: {
  [key: string]: {
    key: string;
    value: string;
    valueCompare: number;
    status: boolean;
  };
} = {
  '1S': { key: '1s', value: '1S', valueCompare: 1 / 60, status: true },
  '1m': { key: '1m', value: '1', status: true, valueCompare: 1 },
  '3m': { key: '3m', value: '3', status: false, valueCompare: 3 },
  '5m': { key: '5m', value: '5', status: true, valueCompare: 5 },
  '15m': { key: '15m', value: '15', status: true, valueCompare: 15 },
  '30m': {
    key: '30m',
    value: '30',
    status: false,
    valueCompare: 30,
  },
  '1h': {
    key: '1h',
    value: '60',
    status: true,
    valueCompare: 60,
  },
  '2h': {
    key: '2h',
    value: '2h',
    status: false,
    valueCompare: 120,
  },
  '4h': { key: '4h', value: '240', status: true, valueCompare: 240 },
  '6h': { key: '6h', value: '360', status: false, valueCompare: 360 },
  '8h': { key: '8h', value: '480', status: false, valueCompare: 480 },
  '12h': { key: '12h', value: '720', status: false, valueCompare: 720 },
  '1D': { key: '1D', value: '1D', status: true, valueCompare: 1440 },
  D: { key: '3D', value: '3D', status: false, valueCompare: 4320 }, // it should be '3D', but there is a bug of TradingView, it call get bars with resolution D
  '3D': { key: '3D', value: '3D', status: false, valueCompare: 4320 },
  '1W': { key: '1W', value: '1W', status: false, valueCompare: 10080 },
  '1M': { key: '1M', value: '1M', status: false, valueCompare: 43200 },
};

export const LIST_RESOLUTION_VALUE_LOOKUP_MAP = Object.values(
  LIST_RESOLUTION_METADATA,
).reduce((acc: any, item) => {
  acc[item.value] = item;
  return acc;
}, {});

export const DEFAULT_INTERVAL = '1S';

export const DEFAULT_INTERVAL_METADATA =
  LIST_RESOLUTION_METADATA[DEFAULT_INTERVAL];

export const DEFAULT_LIST_INTERVAL_METADATA = [
  LIST_RESOLUTION_METADATA['1S'],
  LIST_RESOLUTION_METADATA['1m'],
  LIST_RESOLUTION_METADATA['5m'],
  LIST_RESOLUTION_METADATA['15m'],
  LIST_RESOLUTION_METADATA['1h'],
  LIST_RESOLUTION_METADATA['4h'],
  LIST_RESOLUTION_METADATA['1D'],
];

export const intervalMasterArr = [
  {
    row: [
      LIST_RESOLUTION_METADATA['1S'],
      LIST_RESOLUTION_METADATA['1m'],
      LIST_RESOLUTION_METADATA['3m'],
      LIST_RESOLUTION_METADATA['5m'],
    ],
  },
  {
    row: [
      LIST_RESOLUTION_METADATA['15m'],
      LIST_RESOLUTION_METADATA['30m'],
      LIST_RESOLUTION_METADATA['1h'],
      LIST_RESOLUTION_METADATA['2h'],
    ],
  },
  {
    row: [
      LIST_RESOLUTION_METADATA['4h'],
      LIST_RESOLUTION_METADATA['6h'],
      LIST_RESOLUTION_METADATA['8h'],
      LIST_RESOLUTION_METADATA['12h'],
    ],
  },
  {
    row: [
      LIST_RESOLUTION_METADATA['1D'],
      LIST_RESOLUTION_METADATA['3D'],
      LIST_RESOLUTION_METADATA['1W'],
      LIST_RESOLUTION_METADATA['1M'],
    ],
  },
];

export const CHART_TYPE = [
  {
    type_number: 0,
    type_name: 'Bars',
    src_type: BarsChart,
  },
  {
    type_number: 1,
    type_name: 'Candles',
    src_type: CandleChart,
  },
  {
    type_number: 2,
    type_name: 'Line',
    src_type: LineChart,
  },
  {
    type_number: 3,
    type_name: 'Area',
    src_type: AreaChart,
  },
  {
    type_number: 8,
    type_name: 'Heiken ashi',
    src_type: HeikinAshi,
  },
  {
    type_number: 9,
    type_name: 'Hollow candles',
    src_type: HollowCandle,
  },
  {
    type_number: 10,
    type_name: 'Baseline',
    src_type: BaseLine,
  },
];

export const SI = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'K' },
  { value: 1e6, symbol: 'M' },
  { value: 1e9, symbol: 'B' },
  { value: 1e12, symbol: 't' },
  { value: 1e15, symbol: 'q' },
  { value: 1e18, symbol: 'Q' },
  { value: 1e21, symbol: 's' },
  { value: 1e24, symbol: 'S' },
  { value: 1e27, symbol: 'o' },
  { value: 1e30, symbol: 'n' },
  { value: 1e33, symbol: 'd' },
  { value: 1e36, symbol: 'U' },
  { value: 1e39, symbol: 'D' },
  { value: 1e42, symbol: 'T' },
  { value: 1e45, symbol: 'Qt' },
  { value: 1e48, symbol: 'Qd' },
  { value: 1e51, symbol: 'Sd' },
  { value: 1e54, symbol: 'St' },
  { value: 1e57, symbol: 'O' },
  { value: 1e60, symbol: 'N' },
  { value: 1e63, symbol: 'v' },
  { value: 1e66, symbol: 'c' },
];
