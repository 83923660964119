import { default as React, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { AppButton } from 'src/components';
import rf from 'src/services/RequestFactory';
import { toastError, toastSuccess } from 'src/libs/toast';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { NETWORKS } from 'src/utils/contants';
import { AptosIcon, SuiIcon } from 'src/assets/icons';

type TTradeSettings = {
  buySlippage?: any;
  buyTipAmount?: any;
  quickBuyAmount?: any;
  quickSellPercent?: any;
  sellSlippage?: any;
  sellTipAmount?: any;
};

export const TradeSettings = () => {
  const [tradeSettings, setTradeSettings] = useState<TTradeSettings>({});
  const [data, setData] = useState<TTradeSettings>({});
  const { network: networkActive } = useSelector(
    (state: RootState) => state.user,
  );
  const [network, setNetwork] = useState<string>(networkActive);

  const getTradeSettings = async () => {
    try {
      const res = await rf.getRequest('OrderRequest').getTradeSettings(network);
      setData(res);
    } catch (e: any) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!Object.keys(data).length) return;

    setTradeSettings({
      sellSlippage: data.sellSlippage,
      sellTipAmount: data.sellTipAmount,
      quickSellPercent: data.quickSellPercent,
      buyTipAmount: data.buyTipAmount,
      buySlippage: data.buySlippage,
      quickBuyAmount: data.quickBuyAmount,
    });
  }, [data]);

  useEffect(() => {
    getTradeSettings().then();
  }, [network]);

  const onUpdateTradeSetting = async () => {
    try {
      await rf
        .getRequest('OrderRequest')
        .updateTradeSettings(network, tradeSettings);
      toastSuccess('Success', 'Update successfully!');
    } catch (e: any) {
      console.error(e);
      toastError('Error', e.message || 'Something went wrong!');
    }
  };

  return (
    <div className="mt-4">
      <div className="flex gap-4 my-6 justify-center">
        <div
          className={`flex cursor-pointer items-center gap-2 px-4 py-2 rounded-[100px] ${
            network === NETWORKS.SUI ? 'bg-[#6a60e8]' : 'bg-neutral-alpha-100'
          }`}
          onClick={() => setNetwork(NETWORKS.SUI)}
        >
          <SuiIcon />
          Sui
        </div>
        <div
          className={`flex cursor-pointer items-center gap-2 px-4 py-2 rounded-[100px] ${
            network === NETWORKS.APTOS ? 'bg-[#6a60e8]' : 'bg-neutral-alpha-100'
          }`}
          onClick={() => setNetwork(NETWORKS.APTOS)}
        >
          <AptosIcon />
          Aptos
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col gap-3">
          <div>Sell</div>
          <div>
            <div>Slippage</div>
            <NumericFormat
              value={tradeSettings.sellSlippage ?? ''}
              allowLeadingZeros
              allowNegative={false}
              thousandSeparator=","
              className="h-10 px-4 border-neutral-alpha-500 placeholder:text-[#8d93b7] bg-transparent outline-none border mt-3 w-full focus:border-[#6a60e8] rounded-[8px]"
              decimalScale={2}
              onValueChange={({ floatValue }) => {
                return setTradeSettings({
                  ...tradeSettings,
                  sellSlippage: floatValue,
                });
              }}
            />
          </div>
          <div>
            <div>Tip Amount</div>
            <NumericFormat
              value={tradeSettings.sellTipAmount ?? ''}
              allowLeadingZeros
              allowNegative={false}
              thousandSeparator=","
              className="h-10 px-4 border-neutral-alpha-500 placeholder:text-[#8d93b7] bg-transparent outline-none border mt-3 w-full focus:border-[#6a60e8] rounded-[8px]"
              decimalScale={6}
              onValueChange={({ floatValue }) => {
                return setTradeSettings({
                  ...tradeSettings,
                  sellTipAmount: floatValue?.toString(),
                });
              }}
            />
          </div>

          <div>
            <div>Quick Sell Percent</div>
            <NumericFormat
              value={tradeSettings.quickSellPercent ?? ''}
              allowLeadingZeros
              allowNegative={false}
              thousandSeparator=","
              className="h-10 px-4 border-neutral-alpha-500 placeholder:text-[#8d93b7] bg-transparent outline-none border mt-3 w-full focus:border-[#6a60e8] rounded-[8px]"
              decimalScale={2}
              onValueChange={({ floatValue }) => {
                return setTradeSettings({
                  ...tradeSettings,
                  quickSellPercent: floatValue,
                });
              }}
            />
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div>Buy</div>
          <div>
            <div>Slippage</div>
            <NumericFormat
              value={tradeSettings.buySlippage ?? ''}
              allowLeadingZeros
              allowNegative={false}
              thousandSeparator=","
              className="h-10 px-4 border-neutral-alpha-500 placeholder:text-[#8d93b7] bg-transparent outline-none border mt-3 w-full focus:border-[#6a60e8] rounded-[8px]"
              decimalScale={6}
              onValueChange={({ floatValue }) => {
                return setTradeSettings({
                  ...tradeSettings,
                  buySlippage: floatValue,
                });
              }}
            />
          </div>
          <div>
            <div>Tip Amount</div>
            <NumericFormat
              value={tradeSettings.buyTipAmount ?? ''}
              allowLeadingZeros
              allowNegative={false}
              thousandSeparator=","
              className="h-10 px-4 border-neutral-alpha-500 placeholder:text-[#8d93b7] bg-transparent outline-none border mt-3 w-full focus:border-[#6a60e8] rounded-[8px]"
              decimalScale={6}
              onValueChange={({ floatValue }) => {
                return setTradeSettings({
                  ...tradeSettings,
                  buyTipAmount: floatValue?.toString(),
                });
              }}
            />
          </div>

          <div>
            <div>Quick Buy Amount</div>
            <NumericFormat
              value={tradeSettings.quickBuyAmount ?? ''}
              allowLeadingZeros
              allowNegative={false}
              thousandSeparator=","
              className="h-10 px-4 border-neutral-alpha-500 placeholder:text-[#8d93b7] bg-transparent outline-none border mt-3 w-full focus:border-[#6a60e8] rounded-[8px]"
              decimalScale={6}
              onValueChange={({ floatValue }) => {
                return setTradeSettings({
                  ...tradeSettings,
                  quickBuyAmount: floatValue?.toString(),
                });
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-10">
        <AppButton
          onClick={onUpdateTradeSetting}
          className="rounded-[8px] w-max"
        >
          Save Settings
        </AppButton>
      </div>
    </div>
  );
};
