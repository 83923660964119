import config from 'src/config';
import BaseRequest from './BaseRequest';

export default class TrendingRequest extends BaseRequest {
  getUrlPrefix() {
    return config.apiUrl;
  }

  async getTrendingMeme(network: string) {
    const url = `/${network}/trending/memes`;
    return this.get(url);
  }
}
