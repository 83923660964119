import * as React from 'react';
import { ChevronDownIcon, IconSettings } from 'src/assets/icons';
import { useState } from 'react';
import { OrderFormSetting, TOrderSetting } from './OrderFormSetting';
import { PnLForm, TTarget } from './PnLForm';
import { NumericFormat } from 'react-number-format';
import rf from 'src/services/RequestFactory';
import { toastError, toastSuccess } from 'src/libs/toast';
import { useContext } from 'react';
import { PoolContext } from 'src/pages/pool/context';
import { TPair } from 'src/types';
import { AppButton } from 'src/components';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

const DEFAULT_AMOUNT = [0.25, 0.5, 1, 2, 5, 10];

export const OrderFormBuyDip = () => {
  const [amount, setAmount] = useState<any>('');
  const [isShowAdvancedSettings, setIsShowAdvancedSettings] =
    useState<boolean>(false);
  const [hoursExpires, setHoursExpires] = useState<any>(24);
  const [tagert, setTarget] = useState<TTarget>({
    targetMc: '',
    targetMcPercentage: 20,
    targetLinePercentage: 0,
  });
  const [orderSetting, setOrderSetting] = useState<TOrderSetting>({
    slippage: 20,
    priorityFee: '0.008',
  });
  const { pair } = useContext(PoolContext) as { pair: TPair };
  const { accessToken } = useSelector((state: RootState) => state.user);

  const createOrder = async () => {
    try {
      if (!accessToken) {
        throw Error('Please login!');
      }
      await rf.getRequest('OrderRequest').createOrderBuyDip({
        buyAmount: amount.toString(),
        expiredAt: moment().add(hoursExpires, 'hour').valueOf(),
        orderSetting,
        pairId: pair?.pairId,
        tokenAddress: pair?.tokenBase?.address,
        ...tagert,
      });
      setAmount('');
      toastSuccess('Success', 'Created successfully!');
    } catch (e: any) {
      console.error(e);
      toastError('Error', e.message || 'Something went wrong!');
    }
  };

  return (
    <div>
      <div className="flex gap-3 mt-4 flex-wrap">
        {DEFAULT_AMOUNT.map((item, index) => {
          return (
            <div
              className={`px-4 h-9 flex items-center py-1 cursor-pointer w-[80px] border ${
                item === amount
                  ? 'border-[#6a60e8] text-[#8e9dff]'
                  : 'border-neutral-alpha-500'
              } rounded-[100px]`}
              key={index}
              onClick={() => setAmount(item)}
            >
              {item}
            </div>
          );
        })}
      </div>

      <div className="mb-4">
        <NumericFormat
          placeholder={`Amount to buy in ${pair?.tokenQuote?.symbol}`}
          value={amount ?? ''}
          allowLeadingZeros
          allowNegative={false}
          thousandSeparator=","
          className="h-10 px-4 border-neutral-alpha-500 placeholder:text-[#8d93b7] bg-transparent outline-none border mt-3 w-full focus:border-[#6a60e8] rounded-[100px]"
          decimalScale={6}
          onValueChange={({ floatValue }) => {
            return setAmount(floatValue);
          }}
        />
      </div>

      <div>
        <PnLForm data={tagert} typeLimit="MIN" setData={setTarget} />

        <div className="my-3">
          <div className="text-[12px] mb-1 text-[#8d93b7]">Expires in hrs</div>
          <div className="flex gap-3 items-center">
            <NumericFormat
              value={hoursExpires ?? ''}
              allowLeadingZeros
              allowNegative
              thousandSeparator=","
              className="h-10 px-4 border-neutral-alpha-500 placeholder:text-[#8d93b7] bg-transparent outline-none border mt-3 w-full focus:border-[#6a60e8] rounded-[100px]"
              decimalScale={0}
              onValueChange={({ floatValue }) => {
                return setHoursExpires(floatValue);
              }}
            />
          </div>
        </div>
      </div>

      <div
        className={`${
          isShowAdvancedSettings ? 'border-t pt-3' : 'border-y py-3'
        } flex hover:text-neutral-0 font-bold text-[13px] text-[#8d93b7] justify-between items-center w-full cursor-pointer border-neutral-alpha-500`}
        onClick={() => setIsShowAdvancedSettings(!isShowAdvancedSettings)}
      >
        <div className="flex gap-2 items-center">
          <IconSettings />
          Advanced Settings
        </div>
        <div>
          <ChevronDownIcon
            className={`${
              isShowAdvancedSettings ? 'rotate-[180deg]' : ' '
            } w-4 h-4`}
          />
        </div>
      </div>

      {isShowAdvancedSettings && (
        <OrderFormSetting
          setOrderSetting={setOrderSetting}
          orderSetting={orderSetting}
        />
      )}

      <AppButton onClick={createOrder} className="mt-4">
        Quick Buy {amount}
      </AppButton>

      <div className="text-[12px] text-[#8d93b7] mt-3">
        Once you click on <b>Quick Buy</b>, your transaction is sent
        immediately.
      </div>
    </div>
  );
};
