import BigNumber from 'bignumber.js';
import * as commaNumber from 'comma-number';
import { SI } from 'src/components/TradingView/consts';
import * as moment from 'moment';

BigNumber.config({EXPONENTIAL_AT: 18});

export const roundNumberWithBase = (n: number, base: number): number => {
  return Math.floor(n / base) * base;
};

export const formatUnixTimestamp = (
  timestamp: number,
  formatDate = 'YYYY-MM-DD HH:mm:ss',
) => {
  if (!timestamp) return '--';

  return moment(+timestamp).format(formatDate);
};

export const formatShortAddress = (
  address: string | undefined,
  digits = 8,
  digitsAfter = 6,
): string => {
  if (!address) {
    return '--';
  }
  return `${address?.substring(0, digits)}...${address.substring(
    address.length - digitsAfter,
    address.length,
  )}`;
};

export function formatNumber(
  value: string | number | BigNumber,
  decimalPlaces = 8,
): string {
  if (!value || new BigNumber(value || 0).isZero()) {
    return '--';
  }

  if (
    new BigNumber(value).isGreaterThan(0) &&
    new BigNumber(value).isLessThan(0.00000001)
  ) {
    return '<' + new BigNumber(0.00000001).toFixed(decimalPlaces);
  }

  return _formatLargeNumberIfNeed(
    roundNumber(value, BigNumber.ROUND_DOWN, decimalPlaces),
    decimalPlaces,
  );
}

export function formatUsdNumber(
  value: string | number | BigNumber
): string {
  if (!value || new BigNumber(value || 0).isZero()) {
    return '--';
  }

  if (new BigNumber(value).isGreaterThan(1)) {
    return formatNumber(value, 2);
  }

  const numStr = new BigNumber(value).toExponential();
  const exponent = parseInt(numStr.split('e-')[1]);

  if (Number(exponent) > 3) {
    return formatNumber(value);
  }

  return formatNumber(value, 4);
}

export const roundNumber = (
  number: number | string | BigNumber,
  roundMode = BigNumber.ROUND_DOWN,
  decimals = 18,
) => {
  const newNumber = new BigNumber(number).toFixed(decimals, roundMode);
  return new BigNumber(newNumber).toString();
};

const _formatLargeNumberIfNeed = (number: string, digits = 0) => {
  if (new BigNumber(number).comparedTo(1000) < 0) {
    return commaNumber(
      new BigNumber(Number(number).toFixed(digits)).toString(),
      ',',
      '.',
    );
  }
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const num = parseFloat(number);
  let i;
  for (i = SI.length - 1; i > 0; i--) {
    if (num >= SI[i].value) {
      break;
    }
  }
  return (
    BigNumber(num / SI[i].value)
      .toFixed(digits, BigNumber.ROUND_DOWN)
      .toString()
      .replace(rx, '$1') + SI[i].symbol
  );
};

export function formatToPercent(
  number: string | number | BigNumber,
  decimalPlaces = 2,
): string {
  if (!number || new BigNumber(number || 0).isZero()) {
    return '--';
  }
  const newValue = new BigNumber(number)
    .multipliedBy(100)
    .toFixed(decimalPlaces);
  return new BigNumber(newValue).toString() + '%';
}

export const formatAgeTime = (timestamp: number, suffix: string = '') => {
  const duration = moment.duration(moment().diff(moment(timestamp)));
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (days >= 1) {
    return `${days}d ${suffix}`;
  }

  if (hours >= 1) {
    return `${hours}h ${suffix}`;
  }

  if (minutes >= 1) {
    return `${minutes}m ${suffix}`;
  }

  return `${seconds}s ${suffix}`;
};
