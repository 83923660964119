import * as _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import {
  ArrowDownIcon,
  Indicator,
  TickSuccess,
  ZoomIn,
  ZoomOut,
} from 'src/assets/icons';
import 'src/styles/components/TopBarTradingView.scss';
import Storage from 'src/libs/storage';
import {
  CANDLE_TYPE,
  CHART_TYPE,
  DEFAULT_LIST_INTERVAL_METADATA,
  intervalMasterArr,
  LIST_RESOLUTION_METADATA,
} from '../consts';
import * as React from 'react';

export interface IntervalObj {
  key: string;
  value: string;
}

interface Props {
  [key: string]: any;
  containerId?: any;
  isFullScreen: boolean;
  candleType: string;
  setCandleType: (candleType: string) => void;
}

interface IItemNavBar {
  content: React.ReactNode;
  active?: boolean;
  onClick?: () => void;
}

const ItemNavBar: React.FC<IItemNavBar> = ({
  content = '',
  active = false,
  onClick,
}) => {
  return (
    <div
      className={`item-navbar ${active ? 'item-active' : ''}`}
      onClick={onClick}
    >
      {content}
    </div>
  );
};

const DEFAULT_INTERVAL_METADATA = LIST_RESOLUTION_METADATA['1S'];

const NavbarTradingView: React.FC<Props> = (props) => {
  const [currentInterval, setCurrentInterval] = useState(
    LIST_RESOLUTION_METADATA['1S'],
  );

  const [isShowGroupInterval, setIsShowGroupInterval] =
    useState<boolean>(false);
  const [isShowChartType, setIsShowChartType] = useState<boolean>(false);
  const [chartTypeObj, setChartTypeObj] = useState<any>(CHART_TYPE[1]);
  const [isEditBtn, setIsEditBtn] = useState<boolean>(true);
  const [intervalList, setIntervalList] = useState<Array<IntervalObj>>(
    DEFAULT_LIST_INTERVAL_METADATA,
  );
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const [isShowCurrentInterval, setIsShowCurrentInterval] = useState(false);
  const selectedTimeFrame = Storage.getTimeFrame();

  useEffect(() => {
    if (selectedTimeFrame && !!LIST_RESOLUTION_METADATA[selectedTimeFrame]) {
      setInterval(LIST_RESOLUTION_METADATA[selectedTimeFrame]);
    }
  }, [selectedTimeFrame]);

  const useComponentVisible = (initialIsVisible: any) => {
    const [isCptVisible, setIsCptVisible] = useState(initialIsVisible);
    const ref = useRef<HTMLDivElement>(null);
    const handleClickOutside = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsCptVisible(false);
        if (isEditBtn) {
          setIsShowGroupInterval(false);
        }
        setIsShowChartType(false);
      }
    };

    useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    });

    return { ref, isCptVisible, setIsCptVisible };
  };
  const { ref } = useComponentVisible(true);

  const setInterval = (param: any) => {
    setCurrentInterval(param);
    setIsShowGroupInterval(false);
    props.onSelectInterval(param.value);
    Storage.setTimeFrame(param.key);
  };
  const showOrHideGroupItv = () => {
    setIsShowGroupInterval(!isShowGroupInterval);
  };

  const openIndicatorPopup = () => {
    props.openIndicatorPopup();
  };

  const showOrHideChartType = () => {
    setIsShowChartType(!isShowChartType);
  };

  const isEditOrSave = () => {
    setIsEditBtn(!isEditBtn);
    if (!isEditBtn) {
      setIsShowGroupInterval(!isShowGroupInterval);
    }
  };

  const setChartType = (chartType: number) => {
    props.setChartType(chartType);
    setIsShowChartType(!setIsShowChartType);
  };

  const setIntervalToList = (intervalObj: any) => {
    intervalMasterArr.map((row) => {
      row.row.map((item) => {
        if (item.key === intervalObj.key) {
          item.status = !item.status;
          if (item.status) {
            setIntervalList((intervalArr) =>
              _.sortBy([...intervalArr, item], 'valueCompare'),
            );
          } else {
            setIntervalList(
              intervalList.filter((item) => item.key !== intervalObj.key),
            );
          }
        }
      });
    });
  };

  const handleShowCurrentResolution = () => {
    return (
      intervalList
        .slice(0, 7)
        .map((interval) => interval.key)
        .includes(currentInterval.key) ||
      !isShowCurrentInterval ||
      currentInterval.value === intervalMasterArr[0].row[0].value
    );
  };

  const onMouseEnter = () => setIsShowTooltip(true);
  const onMouseLeave = () => setIsShowTooltip(false);

  return (
    <div>
      <div className={'navbar-tradingview'}>
        <div className={'navbar-tradingview__left'}>
          {intervalList.slice(0, 7).map((interval, idx) => {
            return (
              <ItemNavBar
                content={interval.key}
                onClick={() => {
                  setInterval(interval);
                  setIsShowCurrentInterval(() => false);
                }}
                key={idx}
                active={
                  interval.value.toLowerCase() ===
                  currentInterval?.value?.toLowerCase()
                }
              />
            );
          })}
          <div className="current-interval">
            {handleShowCurrentResolution()
              ? ''
              : currentInterval.key.toLowerCase()}
          </div>

          <div className={'parent-position'}>
            <ItemNavBar
              content={<ArrowDownIcon fill="#A0A3BD" />}
              onClick={showOrHideGroupItv}
            />
            <div className={'group-interval-parent'}>
              {isShowGroupInterval ? (
                <div className={'group-interval'} ref={ref}>
                  <div className={'group-interval-header'}>
                    <div>Select Interval</div>
                    <div
                      onClick={isEditOrSave}
                      className={!isEditBtn ? 'btn-edit' : ''}
                    >
                      {isEditBtn ? 'Edit' : 'Save'}
                    </div>
                  </div>
                  {intervalMasterArr.map((row, idx) => {
                    return (
                      <div
                        className={`${'group-interval-line'} ${'group-interval-line-config'}`}
                        key={idx}
                      >
                        {row.row.map((it) => {
                          return (
                            <div
                              key={it.key}
                              className={'interval-button'}
                              onClick={() => {
                                setIsShowCurrentInterval(() => true);
                                !isEditBtn
                                  ? setIntervalToList(it)
                                  : setInterval(it);
                              }}
                            >
                              <div
                                className={
                                  it.status ? 'interval-btn-active' : ''
                                }
                              >
                                {it.key}
                              </div>
                              {!isEditBtn && it.status ? (
                                <TickSuccess className="interval-img" />
                              ) : null}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>

          <div
            className="topbar-indicator"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <ItemNavBar content={<Indicator />} onClick={openIndicatorPopup} />
            {isShowTooltip && (
              <div className="indicator-tooltip">Technical Indicator</div>
            )}
          </div>

          <div className={'parent-position'}>
            <ItemNavBar
              content={<>{<chartTypeObj.src_type />}</>}
              onClick={showOrHideChartType}
            />
            <div className={'chart-stype-popup'}>
              {isShowChartType ? (
                <div className={'chart-type-parent'} ref={ref}>
                  {CHART_TYPE.map((item) => {
                    return (
                      <div
                        onClick={() => {
                          setChartType(item.type_number);
                          setChartTypeObj(item);
                        }}
                        key={item.type_number}
                        className={'chart-type-child'}
                      >
                        <>
                          {<item.src_type />}
                          <div style={{ marginLeft: '5px' }}>
                            {item.type_name}
                          </div>
                        </>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>

          <div className="flex gap-1 text-sm ml-2 pl-2 border-l border-neutral-grey-500 text-[#828282]">
            <div
              className={`cursor-pointer ${
                props.candleType === CANDLE_TYPE.PRICE ? 'text-neutral-0' : ''
              }`}
              onClick={() => {
                setInterval(DEFAULT_INTERVAL_METADATA);
                props.setCandleType(CANDLE_TYPE.PRICE);
              }}
            >
              Price
            </div>{' '}
            /
            <div
              className={`cursor-pointer ${
                props.candleType === CANDLE_TYPE.MCAP ? 'text-neutral-0' : ''
              }`}
              onClick={() => {
                setInterval(DEFAULT_INTERVAL_METADATA);
                props.setCandleType(CANDLE_TYPE.MCAP);
              }}
            >
              MCap
            </div>
          </div>
        </div>

        <div onClick={props.handleFullscreen}>
          <ItemNavBar
            content={<div>{props.isFullScreen ? <ZoomIn /> : <ZoomOut />}</div>}
          />
        </div>
      </div>
    </div>
  );
};

export default NavbarTradingView;
