import { useEffect, useState } from 'react';

export const useInitialing = () => {
  const [isInitialing, setIsInitialing] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setIsInitialing(false);
    }, 300);
  }, []);

  return {
    isInitialing,
    setIsInitialing,
  };
};
