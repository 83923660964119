import {
  AptosIcon,
  SuiIcon,
  SuiIconBase,
  AptosBaseIcon,
} from 'src/assets/icons';
import React from 'react';
import { NETWORKS } from 'src/utils/contants';

export const AppLogoNetwork = ({
  network,
  className,
  isBase = false,
}: {
  network: string;
  className?: string;
  isBase?: boolean;
}) => {
  if (network === NETWORKS.SUI) {
    if (isBase) {
      return <SuiIconBase className={className} />;
    }
    return <SuiIcon className={className} />;
  }

  if (network === NETWORKS.APTOS) {
    if (isBase) {
      return <AptosBaseIcon className={className} />;
    }
    return <AptosIcon className={className} />;
  }

  return <div />;
};
