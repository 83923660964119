import config from 'src/config';
import BaseRequest from './BaseRequest';

export default class WalletRequest extends BaseRequest {
  getUrlPrefix() {
    return config.apiUrl;
  }

  async getWallets(network: string) {
    const url = `/${network}/user-wallets`;
    return this.get(url);
  }

  async getGenerateWallets(network: string, params: { numberWallets: number }) {
    const url = `/${network}/user-wallets/generate`;
    return this.post(url, params);
  }

  async editNameWallet(
    network: string,
    walletAddress: string,
    params: { newAlias: string },
  ) {
    const url = `/${network}/user-wallets/${walletAddress}`;
    return this.put(url, params);
  }

  async getImportWallet(network: string, params: { privateKey: string }) {
    const url = `/${network}/user-wallets/import`;
    return this.post(url, params);
  }

  async inactiveAllWallets(network: string) {
    const url = `/${network}/user-wallets/inactive-all`;
    return this.put(url);
  }

  async inactiveWallet(network: string, listWalletAddress: string[]) {
    const url = `/${network}/user-wallets/inactive`;
    return this.put(url, { listWalletAddress });
  }
}
