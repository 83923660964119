import * as React from 'react';
import { Oval } from 'react-loader-spinner';

export const LoadingPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <Oval
        visible={true}
        height="60"
        width="60"
        color="#000000"
        ariaLabel="oval-loading"
      />
      <div className="text-lg font-medium mt-10">Loading</div>
    </div>
  );
};
