import React from 'react';
import BigNumber from 'bignumber.js';
import { formatNumber } from 'src/utils/format';

const AppNumberForUSD = ({
  value,
  className = '',
}: {
  value: number | BigNumber | string;
  className?: string;
}) => {
  if (new BigNumber(value).isGreaterThan(1)) {
    return (
      <div className={className} title={formatNumber(value, 2)}>
        ${formatNumber(value, 2)}
      </div>
    );
  }

  const numStr = new BigNumber(value).toExponential();
  const exponent = parseInt(numStr.split('e-')[1]);

  if (Number(exponent) > 3) {
    const significantDigits = new BigNumber(value)
      .toFixed()
      .toString()
      .replace(/0+\.0*/, '');

    return (
      <div className={`flex ${className}`} title={formatNumber(value)}>
        $0.0
        <div className={`text-[10px] mt-1 ${className}`}>{exponent - 1}</div>
        <div className={className}>{significantDigits.slice(0, 4)}</div>
      </div>
    );
  }

  return (
    <div className={className} title={formatNumber(value, 4)}>
      ${formatNumber(value, 4)}
    </div>
  );
};

export const AppNumber = ({
  value,
  decimals = 4,
  isForUSD = false,
  className = '',
}: {
  value: number | BigNumber | string | undefined;
  decimals?: number;
  isForUSD?: boolean;
  className?: string;
}) => {
  if (
    !value ||
    new BigNumber(value || 0).isZero() ||
    new BigNumber(value).isNaN()
  ) {
    return <>--</>;
  }

  if (isForUSD) {
    return <AppNumberForUSD value={value} className={className} />;
  }

  const numStr = new BigNumber(value).toExponential();
  const exponent = parseInt(numStr.split('e-')[1]);

  if (Number(exponent) > 3) {
    const significantDigits = new BigNumber(value)
      .toFixed()
      .toString()
      .replace(/0+\.0*/, '');

    return (
      <div
        className={`flex ${className}`}
        title={formatNumber(value, decimals)}
      >
        0.0
        <div className={`text-[10px] mt-1 ${className}`}>{exponent - 1}</div>
        <div className={className}>{significantDigits.slice(0, 4)}</div>
      </div>
    );
  }

  return (
    <div title={formatNumber(value, decimals)} className={className}>
      {formatNumber(value, decimals > 4 ? 4 : decimals)}
    </div>
  );
};
