import * as copy from 'copy-to-clipboard';
import { toastError, toastSuccess } from '../libs/toast';

export const copyToClipboard = (message: string) => {
  try {
    copy(message);
    toastSuccess('Success', 'Copied!');
  } catch (error: any) {
    toastError('Error', error.message || 'Something went wrong!');
  }
};
