import * as React from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';
import { LoadingPage, ErrorPage } from './pages';
import { BrowserRouter, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { RoutesHistory } from './routes';
import 'react-virtualized/styles.css';
import { Provider } from 'react-redux';
import configureStore from './store';
import { setUserAuth } from 'src/store/user.store';
import Storage from 'src/libs/storage';
import 'rc-tooltip/assets/bootstrap.css';


const extractReferralCodeFromUrl = () => {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const value = searchParams.get('start');
  if (value && value.startsWith('ref')) {
    return value;
  }
  return null;
}


const App = () => {
  const history = createBrowserHistory();
  const { store } = configureStore();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const accessToken = urlParams.get('accessToken') || Storage.getAccessToken();

  const referralCode = extractReferralCodeFromUrl();
  // if user have referral code before, not overwrite it
  if (referralCode && !Storage.getReferralCode()) {
    Storage.setReferralCode(referralCode);
  }

  if (accessToken) {
    store.dispatch(setUserAuth({ accessToken }));
    history.replace({ state: {} });
  }

  return (
    <Suspense fallback={<LoadingPage />}>
      <ErrorBoundary fallback={<ErrorPage />}>
        <ToastContainer
          icon={false}
          autoClose={3000}
          position="top-right"
          hideProgressBar
          pauseOnHover
          closeButton={false}
          toastStyle={{
            position: 'relative',
            overflow: 'visible',
          }}
        />
        <Provider store={store}>
          <BrowserRouter>
            <Router history={history}>
              <RoutesHistory />
            </Router>
          </BrowserRouter>
        </Provider>
      </ErrorBoundary>
    </Suspense>
  );
};

export default App;
