import React, { useEffect, useState } from 'react';
import { PoolProvider } from './context';
import { BasePage } from 'src/layout';
import {
  AuditCheck,
  InfoPair,
  PartPoolChart,
  PartPoolInsights,
  PartPoolMetrics,
  Wallets,
  PoolRelated,
} from './parts';
import { OrderForm } from './components/OrderForm';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { useParams } from 'react-router';
import { TwitterIcon, Telegram, DiscordIcon } from 'src/assets/icons';
import config from 'src/config';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { AppBroadcast } from 'src/libs/broadcast';
import { ArrowDownIcon } from 'src/assets/icons';
import { setNetwork } from 'src/store/user.store';

export const PoolDetail = () => {
  const [sectionExpanded, setSectionExpanded] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<string>('transactions');
  const { accessToken, network } = useSelector(
    (state: RootState) => state.user,
  );
  const { network: pairNetwork } = useParams() as any;
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(pairNetwork, network);
    if (pairNetwork && network !== pairNetwork) {
      dispatch(setNetwork({ network: pairNetwork }));
    }
  }, [network, pairNetwork]);

  const onLayoutChange = (layout: any) => {
    AppBroadcast.dispatch('LAYOUT_CHANGE', { layout });
  };

  const toggleSection = (component: string) => {
    setSectionExpanded((prevSection) => {
      if (!prevSection) return component;
      if (prevSection === component) return null;
      if (prevSection === 'CHART' && component === 'TABLE') return null;
      return component;
    });
  };

  const expand = (component: string) => toggleSection(component);

  const hide = (component: string) => {
    if (!sectionExpanded) {
      setSectionExpanded(component === 'TABLE' ? 'CHART' : null);
    } else if (
      sectionExpanded === component ||
      (sectionExpanded === 'CHART' && component === 'CHART')
    ) {
      setSectionExpanded(null);
    }
  };

  const bottomPanelHeight = Math.floor((380 / (window.innerHeight - 52)) * 100);

  const renderBodyContent = () => {
    if (!sectionExpanded) {
      return (
        <PanelGroup direction="vertical" onLayout={onLayoutChange}>
          <Panel
            id="top-panel"
            defaultSize={100 - bottomPanelHeight}
            minSize={15}
          >
            <PartPoolChart />
          </Panel>
          <PanelResizeHandle
            id="resize-handle"
            className="bg-neutral-alpha-300 h-[2px] relative my-1"
          >
            <div className="z-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-1">
              <ArrowDownIcon className="rotate-180 text-neutral-alpha-300" />
              <ArrowDownIcon className="text-neutral-alpha-300 " />
            </div>
          </PanelResizeHandle>
          <Panel
            id="bottom-panel"
            defaultSize={bottomPanelHeight}
            minSize={bottomPanelHeight}
          >
            <PartPoolInsights
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              sectionExpanded={sectionExpanded}
              expand={expand}
              hide={hide}
            />
          </Panel>
        </PanelGroup>
      );
    } else if (sectionExpanded === 'TABLE') {
      return (
        <PartPoolInsights
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          sectionExpanded={sectionExpanded}
          expand={expand}
          hide={hide}
        />
      );
    } else if (sectionExpanded === 'CHART') {
      return (
        <>
          <div className="h-[calc(100vh-40px)]">
            <PartPoolChart />
          </div>

          <div className="mt-3">
            <PartPoolInsights
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              sectionExpanded={sectionExpanded}
              isHidden={true}
              expand={expand}
              hide={hide}
            />
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <BasePage>
      <PoolProvider>
        <div className="flex h-[calc(100vh-52px)]">
          <div className="part-info w-[324px] h-full overflow-auto customer-scroll border-r border-neutral-alpha-50 bg-neutral-alpha-50">
            <InfoPair />
            <PartPoolMetrics />
            <AuditCheck />
          </div>
          <div className="h-full flex-1 flex flex-col w-[calc(100vw-648px)]">
            {renderBodyContent()}
          </div>

          <div className="w-[325px] h-full relative border-l border-neutral-alpha-50 bg-neutral-alpha-50">
            <div className="h-[calc(100%-40px)] w-[324px] overflow-auto customer-scroll">
              {!!accessToken && <Wallets />}
              <OrderForm />
              <PoolRelated />
            </div>

            <div
              className="flex border-t border-neutral-alpha-50 justify-between items-center absolute bottom-0 w-full py-[10px] px-[12px]"
              style={{
                backdropFilter: 'blur(7.5px)',
                background:
                  'linear-gradient(0deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%)',
              }}
            >
              <div className="text-[12px] leading-[18px] text-neutral-alpha-500">
                RaidenX Communities
              </div>
              <div className="flex gap-6">
                <a
                  href={config.linkSocial.twitter}
                  target="_blank"
                  className="text-neutral-alpha-800 hover:text-neutral-alpha-1000 "
                >
                  <TwitterIcon className="w-5 h-5" />
                </a>
                <a
                  href={config.linkSocial.telegram}
                  target="_blank"
                  className="text-neutral-alpha-800 hover:text-neutral-alpha-1000 "
                >
                  <Telegram className="w-5 h-5" />
                </a>
                <a
                  href={config.linkSocial.discord}
                  target="_blank"
                  className="text-neutral-alpha-800 hover:text-neutral-alpha-1000 "
                >
                  <DiscordIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
      </PoolProvider>
    </BasePage>
  );
};
