import { BaseModal } from 'src/modals/BaseModal';
import React, { useState } from 'react';
import { EyeHideIcon, EyeShowIcon } from 'src/assets/icons';
import { TWallet } from 'src/types';
import { AppCopy } from 'src/components';

const WalletInfo = ({ wallet }: { wallet: TWallet }) => {
  const [isShowPrivateKey, setIsShowPrivateKey] = useState<boolean>(false);
  return (
    <div className="px-4 text-[12px] py-3 border border-neutral-alpha-500 rounded-[8px]">
      <div className="flex gap-2 mb-2">
        <div className="w-[70px]">Address:</div>
        <div className="text-blue-500 max-w-[350px] break-all">
          {wallet.address}
        </div>
        <AppCopy message={wallet.address} />
      </div>
      <div className="flex gap-2">
        <div className="w-[70px]">Private Key:</div>
        <div className="text-blue-500 max-w-[350px] break-all">
          {isShowPrivateKey
            ? wallet.privateKey
            : '***************************************************************************************'}
        </div>

        <div>
          <div
            className="cursor-pointer"
            onClick={() => setIsShowPrivateKey(!isShowPrivateKey)}
          >
            {isShowPrivateKey ? (
              <EyeHideIcon className="w-4" />
            ) : (
              <EyeShowIcon className="w-4" />
            )}
          </div>
          <AppCopy message={wallet.privateKey} />
        </div>
      </div>
    </div>
  );
};

export const ModalGenerateNewWallet = ({
  isOpen,
  onClose,
  wallets,
}: {
  isOpen: boolean;
  wallets: TWallet[];
  onClose: () => void;
}) => {
  return (
    <BaseModal isOpen={isOpen} title={'Generate New Wallet'} onClose={onClose}>
      <div>
        <div className="mb-2">
          Please copy the private key below and store it in a safe location.
        </div>
        <div className="text-red-500">
          Your private key will NOT be displayed again.
        </div>

        <div className="mt-4 flex flex-col gap-3 max-h-[400px] overflow-auto customer-scroll">
          {wallets.map((wallet, index: number) => {
            return <WalletInfo wallet={wallet} key={index} />;
          })}
        </div>
      </div>
    </BaseModal>
  );
};
