import { createSlice } from '@reduxjs/toolkit';
import { setAuthorizationToRequest } from 'src/utils/authenticate';
import Storage from 'src/libs/storage';
import { EInternalEvent, AppBroadcast } from 'src/libs/broadcast';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import moment from 'moment';
import { NETWORKS } from 'src/utils/contants';

export type UserState = {
  accessToken: string;
  network: string;
};

const isValidAccessToken = (accessToken: string | undefined) => {
  if (!accessToken) return false;

  let decodedInfo: JwtPayload;

  try {
    decodedInfo = jwtDecode(accessToken);
  } catch (e) {
    console.log('jwt decode error', e);
    return false;
  }

  const currentTime = moment().unix();

  if (!decodedInfo.exp || +decodedInfo.exp < currentTime) {
    console.log('The user JWT is expired');
    return false;
  }

  return true;
};

export const getValidAccessTokenFromStorage = () => {
  const accessToken = Storage.getAccessToken();
  if (!accessToken) return '';

  const isValid = isValidAccessToken(accessToken);

  if (!isValid) {
    Storage.clearAccessToken();
    AppBroadcast.dispatch(EInternalEvent.LOGOUT, {});
    return '';
  }

  return accessToken || '';
};

const initialState: UserState = {
  accessToken: getValidAccessTokenFromStorage(),
  network: Storage.getNetwork() || NETWORKS.APTOS,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserAuth: (state, action) => {
      const { accessToken } = action.payload;
      if (accessToken) {
        Storage.setAccessToken(accessToken);
        setAuthorizationToRequest(accessToken);
      }

      state.accessToken = accessToken;
    },

    setNetwork: (state, action) => {
      const { network } = action.payload;
      Storage.setNetwork(network);
      state.network = network;
    },

    clearUser: () => {
      setAuthorizationToRequest(null);
      Storage.clearAccessToken();
      return {
        accessToken: '',
        network: Storage.getNetwork() || NETWORKS.SUI,
      };
    },
  },
});

export const { setUserAuth, clearUser, setNetwork } = userSlice.actions;

export default userSlice.reducer;
