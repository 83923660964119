import * as React from 'react';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useContext } from 'react';
import { PoolContext } from 'src/pages/pool/context';
import { TPair } from 'src/types';

enum SPEED {
  DEFAULT,
  AUTO,
}

const TAB_SPEED = [
  {
    name: 'Default',
    value: SPEED.DEFAULT,
  },
  {
    name: 'Auto',
    value: SPEED.AUTO,
  },
];

export type TOrderSetting = {
  slippage: any;
  priorityFee: any;
};

export const OrderFormSetting = ({
  orderSetting,
  setOrderSetting,
}: {
  orderSetting: TOrderSetting;
  setOrderSetting: (data: TOrderSetting) => void;
}) => {
  const [speed, setSpeed] = useState<number>(SPEED.DEFAULT);
  const { pair } = useContext(PoolContext) as { pair: TPair };

  useEffect(() => {
    if (speed === SPEED.AUTO) {
      setOrderSetting({
        ...orderSetting,
        priorityFee: '0.01',
      });
    }
  }, [speed]);

  return (
    <div className="p-3 mt-3 bg-neutral-alpha-500 flex flex-col gap-3 rounded-[4px]">
      <div>
        <div className="text-[12px] mb-2 text-[#8d93b7]">Slippage %</div>
        <NumericFormat
          value={orderSetting.slippage ?? ''}
          allowLeadingZeros
          allowNegative={false}
          thousandSeparator=","
          decimalScale={2}
          className="h-9 px-4 border-neutral-alpha-500 bg-transparent outline-none border w-full focus:border-[#6a60e8] rounded-[100px]"
          onValueChange={({ floatValue }) => {
            return setOrderSetting({
              ...orderSetting,
              slippage: floatValue,
            });
          }}
        />
      </div>
      <div>
        <div className="text-[12px] mb-2 text-[#8d93b7]">Set Speed</div>
        <div className="grid grid-cols-2 gap-3">
          {TAB_SPEED.map((tab, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  setSpeed(tab.value);
                }}
                className={`${
                  speed === tab.value
                    ? 'border-[#6a60e8]'
                    : 'border-neutral-alpha-500'
                } border text-[13px] rounded-[100px] h-9 cursor-pointer justify-center items-center flex`}
              >
                {tab.name}
              </div>
            );
          })}
        </div>
      </div>

      <div>
        <div className="text-[12px] mb-1 text-[#8d93b7]">Priority Fee</div>
        <div className="flex gap-3 items-center">
          <NumericFormat
            disabled={speed === SPEED.AUTO}
            value={orderSetting.priorityFee ?? ''}
            allowLeadingZeros
            allowNegative={false}
            thousandSeparator=","
            decimalScale={pair?.tokenQuote?.decimals}
            className="h-9 px-4 border-neutral-alpha-500 bg-transparent outline-none border focus:border-[#6a60e8] rounded-[100px] disabled:text-neutral-grey-500"
            onValueChange={({ floatValue }) => {
              return setOrderSetting({
                ...orderSetting,
                priorityFee: floatValue?.toString(),
              });
            }}
          />

          <div className="text-[#8d93b7]">{pair?.tokenQuote?.symbol}</div>
        </div>
      </div>
    </div>
  );
};
