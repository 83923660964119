import cx from 'classnames';
import * as React from 'react';

export const AppToggle = ({
  value,
  onChange,
  disabled = false,
}: {
  value: boolean;
  onChange?: () => void;
  disabled?: boolean;
}) => {
  return (
    <div
      role="group"
      className={cx(
        'flex flex-row items-center duration-500',
        value ? 'justify-end' : 'justify-start',
        'p-[2px]',
        'w-[38px] h-[22px]',
        'rounded-[18px]',
        value ? 'bg-[#6a60e8]' : 'bg-neutral-100',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
      )}
      onClick={() => {
        if (disabled) return;
        onChange && onChange();
      }}
    >
      <div className={cx('w-[18px] h-[18px] bg-neutral-0 rounded-[16px]')} />
    </div>
  );
};
