import React, { useContext, useEffect, useState } from 'react';
import { PoolContext } from 'src/pages/pool/context';
import {
  ChevronDownIcon,
  ExternalLink,
  InfoIcon,
  PassIcon,
  IssueIcon,
  CopyIcon,
} from 'src/assets/icons';
import { TPair, TAudit } from 'src/types';
import { formatAgeTime, formatShortAddress } from 'src/utils/format';
import { AppNumber } from 'src/components';
import {
  convertUnit2Real,
  getLinkAddressExplorer,
  getLinkTokenExplorer,
} from 'src/utils/helper';
import { copyToClipboard } from 'src/utils/copyToClipboard';
import rf from 'src/services/RequestFactory';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

const ItemInfo = ({
  children,
  className,
}: {
  children: any;
  className?: string;
}) => {
  return (
    <div
      className={`flex gap-2 p-2.5 justify-between items-center border-b border-neutral-alpha-50 border-dashed ${className}`}
    >
      {children}
    </div>
  );
};

const Label = ({ label, info }: { label: string; info?: string }) => {
  return (
    <div className="flex gap-1 font-normal items-center text-[12px] leading-[1.5] text-neutral-alpha-800">
      {label}{' '}
      {info && <InfoIcon className="w-[12px] h-[12px] cursor-pointer" />}
    </div>
  );
};

const PoolInfor = () => {
  const [show, setShow] = useState<boolean>(true);
  const { pair } = useContext(PoolContext) as { pair: TPair };

  return (
    <div
      className={`px-2  ${show ? 'pb-3' : ''} border-b border-neutral-alpha-50`}
    >
      <div
        onClick={() => setShow(!show)}
        className="flex py-3 gap-2 justify-between cursor-pointer"
      >
        <div className="flex items-center gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.50239 4.5C3.45643 4.5 1.8335 6.08629 1.8335 8C1.8335 9.91371 3.45643 11.5 5.50239 11.5C5.75348 11.5 5.99819 11.476 6.23429 11.4304C6.50542 11.3781 6.76765 11.5555 6.81998 11.8266C6.87232 12.0977 6.69494 12.36 6.4238 12.4123C6.12551 12.4699 5.81734 12.5 5.50239 12.5C2.94352 12.5 0.833496 10.5046 0.833496 8C0.833496 5.49543 2.94352 3.5 5.50239 3.5C5.81734 3.5 6.12551 3.53012 6.4238 3.58769C6.69494 3.64003 6.87232 3.90225 6.81998 4.17339C6.76765 4.44453 6.50542 4.62191 6.23428 4.56957C5.99819 4.524 5.75348 4.5 5.50239 4.5ZM10.4979 4.5C8.45197 4.5 6.82904 6.08629 6.82904 8C6.82904 9.91371 8.45197 11.5 10.4979 11.5C12.5439 11.5 14.1668 9.91371 14.1668 8C14.1668 6.08629 12.5439 4.5 10.4979 4.5ZM5.82904 8C5.82904 5.49543 7.93907 3.5 10.4979 3.5C13.0568 3.5 15.1668 5.49543 15.1668 8C15.1668 10.5046 13.0568 12.5 10.4979 12.5C7.93907 12.5 5.82904 10.5046 5.82904 8Z"
              fill="white"
            />
          </svg>

          <div className="text-[12px] font-medium leading-[1.5]">
            Pool Infor
          </div>
        </div>
        <div className="flex items-center text-[12px] leading-[1.5] gap-1 text-neutral-alpha-500 font-normal">
          {show ? 'Hide' : 'Show'}
          <ChevronDownIcon
            className={`w-[14px] h-[14px] ${!show ? '' : 'rotate-[180deg]'}`}
          />
        </div>
      </div>
      {show && (
        <div className="bg-neutral-beta-500 rounded-[4px]">
          <ItemInfo>
            <Label label={`Pooled ${pair?.tokenBase?.symbol}`} />
            <div className="text-[12px] leading-[1.5] text-neutral-alpha-1000 font-normal">
              <AppNumber
                value={convertUnit2Real(
                  pair?.reserveBase,
                  pair?.tokenBase?.decimals,
                )}
              />
            </div>
          </ItemInfo>

          <ItemInfo>
            <Label label={`Pooled ${pair?.tokenQuote?.symbol}`} />
            <div className="text-[12px] leading-[1.5] text-neutral-alpha-1000 font-normal">
              <AppNumber
                value={convertUnit2Real(
                  pair?.reserveQuote,
                  pair?.tokenQuote?.decimals,
                )}
              />
            </div>
          </ItemInfo>

          <ItemInfo>
            <Label label={pair?.tokenBase?.symbol} />
            <div className="text-[12px] leading-[1.5] flex items-center gap-2 font-normal">
              <div
                onClick={() => copyToClipboard(pair?.tokenBase?.address)}
                className="flex items-center py-[2px] cursor-pointer gap-1 body-xs-regular-10 px-4 rounded-[4px] hover:bg-neutral-alpha-100 bg-neutral-alpha-50"
              >
                {formatShortAddress(pair?.tokenBase?.address, 4, 3)}
                <CopyIcon className="w-[12px] h-[12px]" />
              </div>

              <a
                className="text-neutral-alpha-1000"
                target="_blank"
                href={getLinkTokenExplorer(
                  pair?.network,
                  pair?.tokenBase?.address,
                )}
              >
                <div className="text-neutral-alpha-1000 flex gap-1 items-center">
                  <ExternalLink className="w-[14px] h-[14px]" />
                </div>
              </a>
            </div>
          </ItemInfo>
          <ItemInfo>
            <Label label={'Deployer'} />
            {pair.deployer ? (
              <a
                className="text-neutral-alpha-1000"
                target="_blank"
                href={getLinkAddressExplorer(pair.network, pair.deployer)}
              >
                <div className="text-[12px] leading-[1.5] font-normal">
                  {formatShortAddress(pair.deployer, 6, 6)}
                </div>
              </a>
            ) : (
              <div className="text-[12px] leading-[1.5] font-normal">--</div>
            )}
          </ItemInfo>
          <ItemInfo className="border-b-0">
            <Label label={'Open trading'} />
            <div className="text-[12px] leading-[1.5] text-neutral-alpha-1000 font-normal">
              {formatAgeTime(pair.timestamp * 1000, 'ago')}
            </div>
          </ItemInfo>
        </div>
      )}
    </div>
  );
};

export const AuditCheck = () => {
  const [show, setShow] = useState<boolean>(true);
  const [audit, setAudit] = useState<TAudit>();
  const { pair } = useContext(PoolContext) as { pair: TPair };
  const { network } = useSelector((state: RootState) => state.user);

  const getAuditPair = async () => {
    try {
      const res = await rf
        .getRequest('PairRequest')
        .getPairAudit(network, pair.slug);
      setAudit(res);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!pair.network || !network) return;
    getAuditPair().then();
  }, [pair, network]);

  return (
    <div>
      <div
        className={`px-2  border-b border-neutral-alpha-50 ${
          show ? 'pb-3' : ''
        }`}
      >
        <div
          onClick={() => setShow(!show)}
          className="py-3 cursor-pointer flex justify-between gap-2"
        >
          <div className="flex items-center gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.8499 2.44223C8.315 2.17478 7.68539 2.17478 7.15049 2.44223L3.7002 4.16737V9.21036C3.7002 9.73762 4.01603 10.3908 4.79933 11.1967C5.52701 11.9453 6.59892 12.7648 8.00098 13.666C9.41955 12.7647 10.4865 12.0375 11.212 11.3358C11.9687 10.6037 12.3002 9.9512 12.3002 9.21036V4.16783L8.8499 2.44223ZM13.3002 3.8584V9.21036C13.3002 10.3175 12.7775 11.2127 11.9072 12.0545C11.0506 12.8831 9.80611 13.7092 8.26718 14.6811C8.10409 14.7841 7.8963 14.7841 7.73321 14.6811C6.17468 13.6968 4.93626 12.7722 4.08227 11.8937C3.2385 11.0256 2.7002 10.1311 2.7002 9.21036V3.85836C2.7002 3.66897 2.8072 3.49584 2.97659 3.41114L6.70328 1.5478C7.51971 1.13958 8.48069 1.13958 9.29711 1.5478L13.0238 3.41114C13.1932 3.49584 13.3002 3.66901 13.3002 3.8584ZM10.3537 5.9048C10.549 6.10007 10.549 6.41665 10.3537 6.61191L7.95375 9.01191C7.75849 9.20717 7.4419 9.20717 7.24664 9.01191L6.04664 7.81191C5.85138 7.61665 5.85138 7.30007 6.04664 7.1048C6.2419 6.90954 6.55849 6.90954 6.75375 7.1048L7.6002 7.95125L9.64664 5.9048C9.8419 5.70954 10.1585 5.70954 10.3537 5.9048Z"
                fill="white"
              />
            </svg>

            <div className="text-[12px] font-medium leading-[1.5]">
              Audit Check
            </div>

            <div className="px-1 flex gap-1 py-[2px] items-center text-yellow-500 bg-yellow-900 rounded-[4px]">
              <IssueIcon />
              <div className="text-[10px] leading-[14px]">0 issues</div>
            </div>
          </div>

          <div className="flex items-center text-[12px] leading-[1.5] gap-1 text-neutral-alpha-500 font-normal">
            {show ? 'Hide' : 'Show'}
            <ChevronDownIcon
              className={`w-[14px] h-[14px] ${!show ? '' : 'rotate-[180deg]'}`}
            />
          </div>
        </div>
        {show && (
          <div className="bg-neutral-beta-500 rounded-[4px]">
            <ItemInfo>
              <Label label={'Mint authority'} info={'Mint authority'} />
              <div className="text-[12px] flex items-center gap-1 leading-[1.5] text-neutral-alpha-1000 font-normal">
                {audit?.mintAuthority ? (
                  <>
                    Enabled
                    <IssueIcon />
                  </>
                ) : (
                  <>
                    Disabled
                    <PassIcon />
                  </>
                )}
              </div>
            </ItemInfo>
            <ItemInfo>
              <Label label={'Freeze authority'} info={'Freeze authority'} />
              <div className="text-[12px] flex items-center gap-1  leading-[1.5] text-neutral-alpha-1000 font-normal">
                {audit?.freezeAuthority ? (
                  <>
                    Enabled
                    <IssueIcon />
                  </>
                ) : (
                  <>
                    Disabled
                    <PassIcon />
                  </>
                )}
              </div>
            </ItemInfo>
            <ItemInfo>
              <Label label={'LP Burned'} info={'LP Burned'} />
              <div className="text-[12px] leading-[1.5] font-normal">
                {audit?.lpBurned?.toFixed(2)}%
              </div>
            </ItemInfo>
            <ItemInfo>
              <Label label={'Top 10 holder'} info={'Top 10 holder'} />
              <div className="text-[12px] leading-[1.5] font-normal">
                {audit?.top10HolderPercent?.toFixed(2)}%
              </div>
            </ItemInfo>
            <ItemInfo className="border-b-0">
              <Label label={'Dev balance'} info={'Dev balance'} />
              <div className="text-[12px] leading-[1.5] font-normal">
                {audit?.devBalancePercent?.toFixed(2)}%
              </div>
            </ItemInfo>
          </div>
        )}
      </div>
      <PoolInfor />
      <div className="text-[10px] leading-[14px] pb-[56px] text-neutral-alpha-300 font-normal pt-2 text-center">
        Crypto charts by TradingView
      </div>
    </div>
  );
};
