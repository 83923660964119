import config from 'src/config';
import BaseRequest from './BaseRequest';

export default class TradersRequest extends BaseRequest {
  getUrlPrefix() {
    return config.appInsightUrl;
  }
  async getTopTraders(network: string, pairId: string) {
    const url = `/${network}/traders/${pairId}`;
    return this.get(url);
  }

  async getInfoTraders(network: string, pairId: string, params: any) {
    const url = `/${network}/traders/${pairId}/addresses`;
    return this.get(url, params);
  }
}
