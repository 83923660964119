import React, { useContext } from 'react';
import {
  StarIcon,
  WebsiteIcon,
  TwitterIcon,
  Telegram,
  FireIcon,
} from 'src/assets/icons';
import { BgToken } from 'src/assets/images';
import { PoolContext } from 'src/pages/pool/context';
import { TPair } from 'src/types';
import { AppCopy, AppAvatarToken } from 'src/components';
import { DEXS } from 'src/utils/contants';
import { getLinkSocialPair, getLinkWebsitePair } from 'src/utils/pair';

export const InfoPair = () => {
  const { pair } = useContext(PoolContext) as { pair: TPair };

  const scrollToBottom = () => {
    const contentElement = document.querySelector('.part-info');
    if (contentElement) {
      contentElement.scrollTo({
        top: contentElement.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="pt-3">
      <div className="px-2.5 flex justify-between items-center mb-3">
        <div className="flex items-center gap-2">
          <AppAvatarToken className="w-10 h-10" image={pair?.info?.imageUrl} />
          <div>
            <div className="flex items-center gap-1 text-[16px] leading-[24px] font-medium mb-[2px]">
              <div className="max-w-[160px] truncate">
                {pair?.tokenBase?.name}
              </div>
              <AppCopy
                message={pair?.tokenBase?.address}
                className="w-[14px] h-[14px]"
              />
              / {pair?.tokenQuote?.symbol}
              <div className="text-yellow-500 flex items-center text-[14px] leading-[20px]">
                <FireIcon /> #1
              </div>
            </div>
            <div className="text-[12px] flex gap-2 items-center leading-[18px] text-neutral-alpha-800 font-medium">
              ${pair?.tokenBase?.symbol}{' '}
              <a
                href={DEXS[pair?.dex?.dex]?.website}
                target="_blank"
                className="text-neutral-alpha-500"
              >
                <div className="flex gap-1 items-center">
                  <img
                    className="w-[16px] h-[16px] rounded-[100px]"
                    src={DEXS[pair?.dex?.dex]?.logoUrl}
                    alt={DEXS[pair?.dex?.dex]?.name}
                  />
                  <div className="text-[12px] leading-[18px]">
                    {DEXS[pair?.dex?.dex]?.name}
                  </div>
                  {pair?.dex?.version && (
                    <div className="text-[10px] leading-[16px] text-neutral-alpha-300">
                      {pair?.dex?.version || ''}
                    </div>
                  )}
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="bg-neutral-alpha-100 hover:bg-neutral-alpha-50 w-[30px] h-[30px] flex justify-center items-center rounded-[4px] cursor-pointer">
          <StarIcon className="w-[14px] h-[14px]" />
        </div>
      </div>

      <div
        className="overflow-hidden cursor-pointer"
        style={{
          background:
            'linear-gradient(180deg, rgba(0, 0, 0, 0.30) -7.03%, rgba(0, 0, 0, 0.00) 14.38%, rgba(0, 0, 0, 0.00) 77.22%, rgba(0, 0, 0, 0.30) 100%)',
        }}
      >
        <img
          src={BgToken}
          className="w-full h-[89px] hover:scale-110 duration-700 transition-all"
        />
      </div>

      <div
        className="flex rounded-[4px] mx-2.5 mt-[-14px]"
        style={{
          backdropFilter: 'blur(7.5px)',
          background: `linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), rgba(6, 7, 14, 0.80)`,
        }}
      >
        <a
          href={getLinkWebsitePair(pair?.info?.websites)}
          target="_blank"
          className="hover:bg-neutral-alpha-50"
        >
          <div className="text-[12px] leading-[18px] flex items-center gap-1 py-2 w-[90px] flex justify-center border-r border-neutral-beta-500">
            <WebsiteIcon /> Website
          </div>
        </a>
        <a
          href={getLinkSocialPair(pair?.info?.socials, 'twitter')}
          target="_blank"
          className="hover:bg-neutral-alpha-50"
        >
          <div className="text-[12px] leading-[18px] flex items-center gap-1 py-2 w-[90px] flex justify-center border-r border-neutral-beta-500">
            <TwitterIcon className="w-4 h-4" /> Twitter
          </div>
        </a>
        <a
          href={getLinkSocialPair(pair?.info?.socials, 'telegram')}
          target="_blank"
          className="hover:bg-neutral-alpha-50"
        >
          <div className="text-[12px] leading-[18px] flex items-center gap-1 py-2 w-[90px] flex justify-center border-r border-neutral-beta-500">
            <Telegram className="w-4 h-4" />
            Telegram
          </div>
        </a>

        <div
          className="cursor-pointer flex items-center justify-center flex-1 hover:bg-neutral-alpha-10"
          onClick={scrollToBottom}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.35714 3.77397C4.528 3.60311 4.80501 3.60311 4.97586 3.77397L6.99984 5.79794L9.02381 3.77397C9.19467 3.60311 9.47168 3.60311 9.64253 3.77397C9.81338 3.94482 9.81338 4.22183 9.64253 4.39269L7.3092 6.72602C7.13834 6.89688 6.86133 6.89688 6.69048 6.72602L4.35714 4.39269C4.18629 4.22183 4.18629 3.94482 4.35714 3.77397ZM4.35714 7.27397C4.528 7.10312 4.80501 7.10312 4.97586 7.27397L6.99984 9.29794L9.02381 7.27397C9.19467 7.10312 9.47168 7.10312 9.64253 7.27397C9.81338 7.44482 9.81338 7.72183 9.64253 7.89269L7.3092 10.226C7.13834 10.3969 6.86133 10.3969 6.69048 10.226L4.35714 7.89269C4.18629 7.72183 4.18629 7.44482 4.35714 7.27397Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
