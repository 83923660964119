import { BaseModal } from 'src/modals/BaseModal';
import React, { useContext, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { AppButton } from 'src/components';
import { PoolContext } from 'src/pages/pool/context';
import { TPair } from 'src/types';

const InputAmount = ({
  value,
  onChange,
  placeholder,
}: {
  value: any;
  onChange: (value: any) => void;
  placeholder: string;
}) => {
  return (
    <div className="border border-neutral-alpha-500 rounded-[100px] px-4 py-3 flex items-center">
      <NumericFormat
        placeholder={placeholder}
        value={value ?? ''}
        allowLeadingZeros
        allowNegative={false}
        thousandSeparator=","
        className="placeholder:text-neutral-grey-400 w-full outline-none bg-transparent "
        decimalScale={6}
        onValueChange={({ floatValue }) => {
          return onChange(floatValue);
        }}
      />
    </div>
  );
};

export const ModalFilterAmount = ({
  isOpen,
  onClose,
  isBaseAmount,
  params,
  setParams,
}: {
  isOpen: boolean;
  isBaseAmount?: boolean;
  onClose: () => void;
  params: any;
  setParams: (data: any) => void;
}) => {
  const [min, setMin] = useState<any>('');
  const [max, setMax] = useState<any>('');
  const { pair } = useContext(PoolContext) as { pair: TPair };

  const onClear = (key: string) => {
    setMax('');
    setMin('');
    setParams({
      ...params,
      [key]: '',
    });
    onClose();
  };

  const onApply = (key: string) => {
    if (!min && !max) {
      setParams({
        ...params,
        [key]: '',
      });
      onClose();
      return;
    }
    setParams({
      ...params,
      [key]: `${min}-${max}`,
    });
    onClose();
  };

  return (
    <BaseModal
      isOpen={isOpen}
      title={`Filter ${
        isBaseAmount ? pair?.tokenBase?.symbol : pair?.tokenQuote?.symbol
      } Amount`}
      onClose={onClose}
    >
      <div className="flex flex-col gap-4 mt-4">
        <InputAmount value={min} onChange={setMin} placeholder="min" />
        <InputAmount value={max} onChange={setMax} placeholder="max" />
      </div>

      <div className="flex gap-4 justify-center mt-8">
        <AppButton
          onClick={() => {
            onClear(isBaseAmount ? 'baseAmount' : 'quoteAmount');
          }}
          variant="secondary"
          className="min-w-[120px]"
        >
          Clear
        </AppButton>
        <AppButton
          className="min-w-[120px]"
          onClick={() => {
            onApply(isBaseAmount ? 'baseAmount' : 'quoteAmount');
          }}
        >
          Apply
        </AppButton>
      </div>
    </BaseModal>
  );
};
