import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BasePage } from 'src/layout';
import { RootState } from 'src/store';
import {
  TLeaderboardInfo,
  TLeaderboardEntry,
  Mission,
  TActiveCampaigns,
} from 'src/types';
import rf from 'src/services/RequestFactory';
import { ArrowRight, CheckCircle } from 'src/assets/icons';
import { toastError, toastSuccess } from 'src/libs/toast';

export const LeaderBoardPage: React.FC = () => {
  const [campaignId, setCampaignId] = useState<string | null>(null);
  const [leaderboardInfo, setLeaderboardInfo] = useState<TLeaderboardInfo>();
  const [leaderboardEntries, setLeaderboardEntries] = useState<
    TLeaderboardEntry[]
  >([]);
  const [missions, setMissions] = useState<Mission[]>([]);
  const { accessToken } = useSelector((state: RootState) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages] = useState(1);
  const entriesPerPage = 10;

  useEffect(() => {
    getCampaign().then(campaignId => {
      if (campaignId) {
        Promise.all([
          getLeaderBoardData(campaignId),
        ]);
      }
    });
  }, []);

  useEffect(() => {
    if (!campaignId || !accessToken) return;
    getUserAirdropStats(campaignId);
    getMissionData(campaignId);
  }, [campaignId, accessToken]);

  const getCampaign = async (): Promise<any> => {
    try {
      const { campaign } = (await rf.getRequest('CampaignsRequest').getActiveCampaigns()) as {
        campaign: TActiveCampaigns;
      };
      if (campaign) {
        setCampaignId(campaign.campaignId);
      }
      return campaign.campaignId;
    } catch (error) {
      setCampaignId(null);
    }
  };

  const getLeaderBoardData = async (campaignId: string): Promise<void> => {
    try {
      const leaderboardRes = await rf.getRequest('CampaignsRequest').getLeaderboard(campaignId);
      if (leaderboardRes.docs) {
        setLeaderboardEntries(leaderboardRes.docs as TLeaderboardEntry[]);
      }
    } catch (error) {
      console.error('Error fetching leaderboard entries:', error);
    }
  };

  const getUserAirdropStats = async (campaignId: string): Promise<void> => {
    try {
      const leaderboardInfoRes =
        await rf.getRequest('CampaignsRequest').getUserAirdropStats(campaignId);
      if (leaderboardInfoRes) {
        setLeaderboardInfo(leaderboardInfoRes as TLeaderboardInfo);
      }
    } catch (error) {
      console.error('Error fetching user airdrop stats:', error);
    }
  };

  const getMissionData = async (campaignId: string): Promise<void> => {
    try {
      const missionsRes = await rf.getRequest('CampaignsRequest').getMissions(campaignId);
      if (missionsRes) {
        setMissions(missionsRes as Mission[]);
      }
    } catch (error) {
      console.error('Error fetching mission data:', error);
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleResolveMission = async (missionId: string) => {
    try {
      const campaignsRequest = rf.getRequest('CampaignsRequest');
      await campaignsRequest.resolveMission(missionId);
      toastSuccess('Claim Success', 'Mission resolved successfully');
      if (campaignId) {
        await getMissionData(campaignId);
      }
    } catch (error) {
      toastError('Claim Failed', 'Error resolving mission');
    }
  };

  return (
    <BasePage>
      <div className="max-w-[1200px] mx-auto w-full px-9 mt-8 relative">
        <div className="w-full">
          <h1 className="font-bold text-[32px]">RaidenX Points Leaderboard</h1>
          <p className="text-[#8d93b7] mt-2">
            Earn points by trading and completing missions.
          </p>

          {accessToken && (
            <div className="bg-neutral-alpha-50 border border-neutral-alpha-500 rounded-[8px] p-6 mt-10">
              <h2 className="pb-3 border-b border-neutral-alpha-500 uppercase font-bold">
                Your Stats
              </h2>
              <div className="grid grid-cols-3 gap-4 mt-4">
                <StatsItem
                  label="Ranking"
                  value={leaderboardInfo?.rank || '-'}
                  tooltip="Your current ranking on the leaderboard"
                />
                <StatsItem
                  label="Total Points"
                  value={leaderboardInfo?.totalPoints || '-'}
                  tooltip="Your total accumulated Photon points"
                />
                <StatsItem
                  label="7-Days Points"
                  value={leaderboardInfo?.cyclePoints || '-'}
                  tooltip="Photon points earned in the last 7 days"
                />
              </div>
            </div>
          )}
        </div>

        <div className="mt-10 flex gap-4 items-start justify-between">
          <div className="flex-1">
            <LeaderboardTable
              entries={leaderboardEntries}
              currentPage={currentPage}
              entriesPerPage={entriesPerPage}
            />
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
          {accessToken && ( 
            <LeaderboardSidebar
              missions={missions}
              handleResolveMission={handleResolveMission}
            />
          )}
        </div>
      </div>
    </BasePage>
  );
};

interface LeaderboardTableProps {
  entries: TLeaderboardEntry[];
  currentPage: number;
  entriesPerPage: number;
}

const LeaderboardTable: React.FC<LeaderboardTableProps> = ({ entries }) => (
  <div className="rounded-lg overflow-auto">
    <h2 className="rounded-t-lg bg-gradient-to-r from-primary-500 via-neutral-800 p-4 font-bold text-[24px]">
      LEADERBOARD
    </h2>
    <table className="w-full">
      <thead className="text-xs text-[#8d93b7] uppercase bg-neutral-alpha-50">
        <tr>
          <th scope="col" className="px-6 py-3">
            RANKING
          </th>
          <th scope="col" className="px-6 py-3">
            USER
          </th>
          <th scope="col" className="px-6 py-3">
            TOTAL POINTS
          </th>
          <th scope="col" className="px-6 py-3">
            7-DAYS POINTS
          </th>
        </tr>
      </thead>
      <tbody>
        {entries.map((entry, index) => (
          <tr
            key={entry.userId}
            className={`text-center ${
              index % 2 === 0 ? 'bg-neutral-alpha-100' : 'bg-neutral-alpha-50'
            }`}
          >
            <td className="py-4 px-4 text-[#8d93b7]">{entry.rank}</td>
            <td className="px-6 py-4">{entry.userId}</td>
            <td className="px-6 py-4">
              {Number(entry.totalPoints).toFixed(5)}
            </td>
            <td className="px-6 py-4">
              {Number(entry.cyclePoints).toFixed(5)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex justify-center items-center mt-4 space-x-2">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="px-2 py-1 rounded-full bg-indigo-600 text-white hover:bg-indigo-700 disabled:opacity-50 transition-colors duration-200"
      >
        &lt;
      </button>
      {pageNumbers.map((number) => (
        <button
          key={number}
          onClick={() => onPageChange(number)}
          className={`px-3 py-1 rounded-full transition-colors duration-200 ${
            currentPage === number
              ? 'bg-teal-400 text-gray-900 hover:bg-teal-500'
              : 'bg-gray-700 text-white hover:bg-gray-600'
          }`}
        >
          {number}
        </button>
      ))}
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="px-2 py-1 rounded-full bg-indigo-600 text-white hover:bg-indigo-700 disabled:opacity-50 transition-colors duration-200"
      >
        &gt;
      </button>
    </div>
  );
};

interface StatsItemProps {
  label: string;
  value: number | string;
  tooltip: string;
}

const StatsItem: React.FC<StatsItemProps> = ({ label, value, tooltip }) => (
  <div>
    <div className="uppercase font-bold text-[#8d93b7] text-sm flex items-center">
      {label}
    </div>
    <div className="text-[16px] font-bold mt-2">{value}</div>
  </div>
);

const LeaderboardSidebar: React.FC<{
  missions: Mission[];
  handleResolveMission: (missionId: string) => void;
}> = ({ missions, handleResolveMission }) => {
  return (
    <div className="min-w-[280px] h-full bg-neutral-alpha-50 rounded-xl">
      <h3 className="flex items-center mb-4 text-sm font-semibold text-neutral-alpha-900 rounded-t-xl bg-gradient-to-r from-primary-500 via-neutral-800 p-4">
        MISSIONS
      </h3>
      <div className="flex flex-col gap-2 p-4">
        {missions.map((mission, index) => (
          <div
            key={index}
            className="bg-neutral-900 rounded-xl flex items-center justify-start px-4 py-2 gap-2"
          >
            <div className="flex-1 flex items-center gap-2">
              {mission.resolved ? (
                <div className="w-6 h-6 flex items-center justify-center">
                  <CheckCircle className="w-6 h-6 text-brand-500" />
                </div>
              ) : (
                <div className="w-6 h-6 border rounded-full border-neutral-alpha-500"></div>
              )}
              <div className="flex-1">
                <p className="text-neutral-0">{mission.name}</p>
                <p className="text-sm text-neutral-alpha-800">
                  {mission.points} points
                </p>
              </div>
            </div>
            {mission.resolved ? (
              <button
                className="px-3 py-1 text-sm text-neutral-800 transition-colors bg-brand-300 rounded-full"
                onClick={() => handleResolveMission(mission.missionId)}
              >
                Claim
              </button>
            ) : (
              <button className="px-3 py-1 transition-colors text-primary-500 rounded-full">
                <ArrowRight className="w-6 h-6" />
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
