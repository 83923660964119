import * as React from 'react';
import { formatNumber } from 'src/utils/format';
import { useContext, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { TPair } from 'src/types';
import { useEffect, useState } from 'react';
import { AppLogoNetwork, AppNumber, Progress } from 'src/components';
import { useParams } from 'react-router';
import rf from 'src/services/RequestFactory';
import { TPoolStats } from 'src/types';
import { PoolContext } from '../context';
import { AppBroadcast } from 'src/libs/broadcast';
import { SOCKET_EVENTS } from 'src/libs/socket';
import { sleep } from 'src/utils/helper';
import Tooltip from 'rc-tooltip';

const TABS_CHANGE = [
  {
    value: '5m',
    name: '5M',
  },
  {
    value: '1h',
    name: '1H',
  },
  {
    value: '6h',
    name: '6H',
  },
  {
    value: '24h',
    name: '24H',
  },
];

export const PartPoolMetrics = () => {
  const { pair } = useContext(PoolContext) as { pair: TPair };
  const { pairSlug, network } = useParams() as any;
  const [resolution, setResolution] = useState<string>('5m');
  const [stats, setStats] = useState<TPoolStats>();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const getPairStats = async () => {
    try {
      const res = await rf
        .getRequest('PairRequest')
        .getPairStats(network, pairSlug);
      setStats(res);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getPairStats().then();
    const getInfo = setInterval(getPairStats, 15000);
    return () => clearInterval(getInfo);
  }, [pairSlug]);

  const mCap = useMemo(() => {
    if (!!pair) {
      return new BigNumber(pair?.tokenBase?.priceUsd).multipliedBy(
        new BigNumber(pair.tokenBase?.totalSupply),
      );
    }
    return '0';
  }, [pair]);

  useEffect(() => {
    const handleNewTransaction = async (data: any) => {
      setIsUpdating(true);
      await sleep(600).then();
      setIsUpdating(false);
    };

    AppBroadcast.on(SOCKET_EVENTS.TRANSACTION, handleNewTransaction);

    return () => {
      AppBroadcast.remove(SOCKET_EVENTS.TRANSACTION);
    };
  }, []);

  const ItemMetadata: React.FC<any> = ({
    title,
    content,
    decimals,
    isUSD,
    isNeedUpdate,
    info,
    isShowLogo,
  }) => {
    return (
      <div className="border border-neutral-alpha-50 h-[50px] bg-neutral-alpha-50 rounded-[4px] px-[8px] py-[6px] flex items-center flex-col">
        {info ? (
          <Tooltip overlay={info} placement="top">
            <div className="border-b border-dashed w-max border-neutral-alpha-100 uppercase cursor-pointer text-[10px] leading-[1.6] text-neutral-alpha-500 mb-[2px]">
              {title}
            </div>
          </Tooltip>
        ) : (
          <div className="uppercase text-[10px] leading-[1.6] text-neutral-alpha-500 mb-[2px]">
            {title}
          </div>
        )}

        <div
          className={`font-semibold leading-[20px] font-[14px] flex items-center gap-1 w-max duration-300 transition-all ${
            isNeedUpdate && isUpdating
              ? 'bg-neutral-alpha-600'
              : 'bg-transparent'
          }`}
        >
          {isShowLogo && (
            <AppLogoNetwork
              network={network}
              isBase
              className="w-[14px] h-[14px] mt-[-2px]"
            />
          )}

          <AppNumber
            className="font-semibold"
            value={content}
            decimals={decimals || 8}
            isForUSD={isUSD}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="px-2.5 mt-3">
        <div className="grid grid-cols-2 gap-1 mt-2">
          <ItemMetadata
            title="Price USD"
            content={pair?.tokenBase?.priceUsd}
            decimals={pair?.tokenBase?.decimals}
            isUSD={!!+pair?.tokenBase?.priceUsd}
            isNeedUpdate
          />
          <ItemMetadata
            isShowLogo
            title={`Price ${pair?.tokenQuote?.symbol || ''}`}
            content={pair?.tokenBase?.price}
            decimals={pair?.tokenBase?.decimals}
            isNeedUpdate
          />
          <ItemMetadata
            title="Liquidity"
            content={pair?.liquidityUsd}
            decimals={pair?.tokenBase?.decimals}
            isUSD={!!+pair?.liquidityUsd}
          />
          <ItemMetadata
            title="MKT Cap"
            content={mCap}
            decimals={2}
            isUSD={!!+mCap}
            info={
              <div className="text-center">
                Assuming circulating supply = total supply <br /> (includes
                locked, excludes burned)
              </div>
            }
          />
        </div>
      </div>

      <div className="border-y mt-3 border-neutral-alpha-50">
        <div className="grid grid-cols-4 border-b border-neutral-alpha-50">
          {TABS_CHANGE.map((tab, index) => {
            return (
              <div
                key={index}
                className={`flex justify-center  flex-col items-center py-1 px-2 border-neutral-alpha-50 cursor-pointer ${
                  resolution === tab.value
                    ? 'bg-neutral-alpha-50'
                    : 'hover:bg-neutral-alpha-20'
                } ${index === TABS_CHANGE.length - 1 ? '' : 'border-r'}`}
                onClick={() => setResolution(tab.value)}
              >
                <div className="uppercase text-[12px] leading-[1.5] text-neutral-alpha-500 font-normal">
                  {tab.name}
                </div>

                <div
                  className={`${
                    (stats?.percent[tab.value] ?? 0) > 0
                      ? 'text-green-500'
                      : 'text-red-500'
                  } text-[14px] leading-[20px] font-semibold`}
                >
                  {stats?.percent[tab.value]
                    ? `${formatNumber(stats?.percent[tab.value], 2)}%`
                    : '-'}
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex py-3 px-2">
          <div className="border-r border-neutral-alpha-50 flex flex-col">
            <div className="px-2 py-2.5 w-[94px]">
              <div className="text-[12px] uppercase leading-[1.5] text-neutral-alpha-500 mb-[2px]">
                Txns
              </div>
              <div className="text-[14px] leading-[20px] font-semibold">
                {stats?.totalNumTxn[resolution] || '--'}
              </div>
            </div>
            <div className="px-2 py-2.5 w-[94px]">
              <div className="text-[12px] uppercase leading-[1.5] text-neutral-alpha-500 mb-[2px]">
                Volume
              </div>
              <div className="text-[14px] leading-[20px] font-semibold">
                <AppNumber
                  className="font-semibold"
                  value={stats?.volume[resolution]}
                  decimals={2}
                  isForUSD
                />
              </div>
            </div>
            <div className="px-2 py-2.5 w-[94px]">
              <Tooltip
                overlay={'Unique wallets that bought or sold this pair'}
                placement="top"
              >
                <div className="border-b border-dashed w-max border-neutral-alpha-100 cursor-pointer text-[12px] uppercase leading-[1.5] text-neutral-alpha-500 mb-[2px]">
                  Makers
                </div>
              </Tooltip>
              <div className="text-[14px] leading-[20px] font-semibold">
                {stats?.maker[resolution] || '--'}
              </div>
            </div>
          </div>

          <div className="flex-1 flex flex-col gap-2 pl-3">
            <div className="py-1 font-normal">
              <div className="flex justify-between mb-1">
                <div>
                  <div className="text-[12px] leading-[1.5] text-neutral-alpha-500">
                    Buys
                  </div>
                  <div className="text-[14px] leading-[20px] text-neutral-alpha-800">
                    {stats?.buyTxn[resolution] || '--'}
                  </div>
                </div>
                <div>
                  <div className="text-[12px] leading-[1.5] text-neutral-alpha-500">
                    Sells
                  </div>
                  <div className="text-[14px] leading-[20px] text-neutral-alpha-800 text-right">
                    {stats?.sellTxn[resolution] || '--'}
                  </div>
                </div>
              </div>
              <Progress
                nameLeft="Buys"
                nameRight="Sells"
                valueLeft={stats?.buyTxn[resolution] ?? 0}
                valueRight={stats?.sellTxn[resolution] ?? 0}
              />
            </div>

            <div className="py-1 font-normal">
              <div className="flex justify-between mb-1">
                <div>
                  <div className="text-[12px] leading-[1.5] text-neutral-alpha-500">
                    Buy Vol
                  </div>
                  <div className="text-[14px] leading-[20px] text-neutral-alpha-800">
                    <AppNumber value={stats?.buyVolume[resolution]} isForUSD />
                  </div>
                </div>
                <div>
                  <div className="text-[12px] leading-[1.5] text-neutral-alpha-500 text-right">
                    Sell Vol
                  </div>
                  <div className="text-[14px] leading-[20px] text-neutral-alpha-800 text-right">
                    <AppNumber value={stats?.sellVolume[resolution]} isForUSD />
                  </div>
                </div>
              </div>
              <Progress
                nameLeft="Buy volume"
                nameRight="Sell volume"
                valueLeft={stats?.buyVolume[resolution] || 0}
                valueRight={stats?.sellVolume[resolution] || 0}
              />
            </div>

            <div className="py-1 font-normal">
              <div className="flex justify-between mb-1">
                <div>
                  <div className="text-[12px] leading-[1.5] text-neutral-alpha-500">
                    Buyers
                  </div>
                  <div className="text-[14px] leading-[20px] text-neutral-alpha-800">
                    {stats?.buyer[resolution] || '--'}
                  </div>
                </div>
                <div>
                  <div className="text-[12px] leading-[1.5] text-neutral-alpha-500">
                    Sellers
                  </div>
                  <div className="text-[14px] leading-[20px] text-neutral-alpha-800 text-right">
                    {stats?.seller[resolution] || '--'}
                  </div>
                </div>
              </div>
              <Progress
                nameLeft="Buyers"
                nameRight="Sellers"
                valueLeft={stats?.buyer[resolution] ?? 0}
                valueRight={stats?.seller[resolution] ?? 0}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
