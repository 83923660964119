import { useEffect, useRef, useState } from 'react';
import {
  HistoryCallback,
  IBasicDataFeed,
  LibrarySymbolInfo,
  ResolutionString,
  ResolveCallback,
  SubscribeBarsCallback,
  ThemeName,
  TradingTerminalWidgetOptions,
  widget,
} from 'src/libs/charting_library/charting_library.min';
import {
  convertResolution2MilliSeconds,
  convertResolution2Seconds,
  createEmptyCandleIfNeeded,
  getClientTimezone,
  getResolutionInterval,
} from './utils';
import { roundNumberWithBase } from 'src/utils/format';
import { ICandle } from './utils';
import NavbarTradingView from './parts/NavbarTradingView';
import axios from 'axios';
import config from 'src/config';
import { useEffectUnsafe } from 'src/hooks';
import * as React from 'react';
import Storage from 'src/libs/storage';
import { CANDLE_TYPE, SYMBOL_TYPE, THEME_MODE } from './consts';

interface obj {
  [key: string]: boolean | number | string;
}

const API_URL = `${config.apiUrl}/candles/615cdd69487a939a3fbc8e0981016cb3`;
const SYMBOL = 'DOODOO/APT';

interface Props {
  containerId: string;
  libraryPath?: string;
  chartsStorageUrl?: string;
  chartsStorageApiVersion?: '1.0' | '1.1';
  clientId?: string;
  userId?: string;
  fullscreen?: boolean;
  autosize?: boolean;
  studiesOverrides?: obj;
  className?: string;
  height?: number;
  handleFullscreen: any;
  isFullScreen: boolean;
}

const configurationData = {
  supports_search: true,
  supports_marks: true,
  intraday_multipliers: [
    '1S',
    '1',
    '3',
    '5',
    '15',
    '30',
    '60',
    '120',
    '240',
    '360',
    '480',
    '720',
  ],
  supported_resolutions: [
    '1S',
    '1',
    '3',
    '5',
    '15',
    '30',
    '60',
    '120',
    '240',
    '360',
    '480',
    '720',
    '1D',
    '3D',
    '1W',
    '1M',
  ],
};

const getBackgroundColor = (theme: string): string => {
  return theme === THEME_MODE.LIGHT ? '#fafafc' : '#121318';
};

export const TradingView: React.FC<Props> = (props) => {
  const listDisable = [
    'header_symbol_search',
    'header_undo_redo',
    'display_market_status',
    // 'timeframes_toolbar',
    'edit_buttons_in_legend',
    'volume_force_overlay',
    'legend_context_menu',
    'header_widget',
    'symbol_search_hot_key',
  ];

  const [chartReady, setChartReady] = useState(false);
  const [candleType, setCandleType] = useState<string>(CANDLE_TYPE.PRICE);

  const tradingViewChart = useRef<any>({});
  const lastCandleRef = useRef<ICandle>({} as ICandle);
  const chartRealtimeCallback = useRef<(candle: ICandle) => void>(() => {});

  const theme = THEME_MODE.DARK;
  const tradingViewTheme = (theme.charAt(0).toUpperCase() +
    theme.slice(1)) as ThemeName;
  const TRADING_VIEW_INTERVAL_KEY: any = Storage.getTimeFrame();

  console.log('TRADING_VIEW_INTERVAL_KEY', TRADING_VIEW_INTERVAL_KEY);
  console.log(
    'convertResolution2MilliSeconds',
    convertResolution2MilliSeconds(TRADING_VIEW_INTERVAL_KEY),
  );

  const intervalInMillisecondsRef = useRef<number>(
    convertResolution2MilliSeconds(TRADING_VIEW_INTERVAL_KEY),
  );

  const onReady = (callback: any) => {
    setTimeout(() => callback(configurationData));
  };

  const getCandleInfoType = () => {
    if (candleType === CANDLE_TYPE.PRICE) {
      return SYMBOL || '';
    }
    return `${SYMBOL} (Market Cap)`;
  };

  const getBars = async (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    from: number,
    to: number,
    onResult: HistoryCallback,
  ) => {
    const intervalInSeconds = convertResolution2Seconds(resolution as any);
    const startTime = roundNumberWithBase(from, intervalInSeconds) * 1000;
    const endTime = roundNumberWithBase(to, intervalInSeconds) * 1000;

    console.log('resolution', resolution);
    console.log('intervalInSeconds', intervalInSeconds);

    try {
      const { data: dataResponse } = await axios.get(API_URL, {
        params: {
          from: startTime,
          to: endTime,
          resolution,
        },
      } as any);

      const data = dataResponse.data;
      if (data.length === 0) {
        onResult([], {
          noData: true,
        });
        return;
      } else {
        if (data[data.length - 1].time > Date.now()) {
          data.pop();
        }
      }
      const bars: any = data.map((bar: any) => ({
        time: +bar.time,
        open: +bar.open,
        high: +bar.high,
        low: +bar.low,
        close: +bar.close,
        volume: +bar.volume,
      }));

      lastCandleRef.current = bars[bars.length - 1];
      onResult(bars, { noData: false });
    } catch (error) {
      onResult([], { noData: true });
    }
  };

  const resolveSymbol = async (
    symbolName: string,
    onSymbolResolvedCallback: ResolveCallback,
  ) => {
    const symbolInfo: LibrarySymbolInfo = {
      ticker: getCandleInfoType(),
      name: getCandleInfoType(),
      description: `${getCandleInfoType()} on Liquidswap`,
      pricescale: 10 ** 8, // need change precision
      volume_precision: 8, // need change precision
      minmov: 1,
      exchange: '',
      full_name: '',
      listed_exchange: '',
      session: '24x7',
      has_intraday: true,
      has_daily: true,
      has_seconds: true,
      seconds_multipliers: ['1'],
      has_weekly_and_monthly: false,
      intraday_multipliers: configurationData.intraday_multipliers,
      timezone: getClientTimezone(),
      type: SYMBOL_TYPE.bitcoin,
      supported_resolutions: configurationData.supported_resolutions,
    };
    onSymbolResolvedCallback(symbolInfo);
  };

  const subscribeBars = (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onRealtimeCallback: SubscribeBarsCallback,
  ) => {
    // chartRealtimeCallback.current = onRealtimeCallback;
    // AppBroadcast.on(SOCKET_EVENTS.CREATED_TRADE, (trade: any) => {
    //   //TODO Handle listen event ws
    //   const intervalInMilliseconds = intervalInMillisecondsRef.current;
    //   lastCandleRef.current = addTradeToLastCandle(
    //     trade,
    //     lastCandleRef.current,
    //     intervalInMilliseconds,
    //     onRealtimeCallback,
    //   );
    // });
    // AppBroadcast.remove(SOCKET_EVENTS.CREATED_TRADE);
  };

  const datafeed: IBasicDataFeed = {
    onReady,
    searchSymbols: () => {},
    resolveSymbol,
    getBars,
    subscribeBars,
    unsubscribeBars: () => {},
  };

  useEffect(() => {
    const intervalChart = window.setInterval(() => {
      const lastCandle = lastCandleRef.current;
      // console.log('intervalChart called', new Date().toISOString());
      if (!lastCandle) {
        console.log('lastCandle is empty in intervalChart');
        return;
      }

      const intervalInMilliseconds = intervalInMillisecondsRef.current;
      const newLatestCandle = createEmptyCandleIfNeeded(
        lastCandle,
        intervalInMilliseconds,
        chartRealtimeCallback.current,
      );
      if (newLatestCandle) {
        lastCandleRef.current = newLatestCandle;
      }
    }, 1000);

    return () => clearInterval(intervalChart);
  }, []);

  useEffectUnsafe(() => {
    console.log('xxxx', {
      x: TRADING_VIEW_INTERVAL_KEY,
      t: getResolutionInterval(TRADING_VIEW_INTERVAL_KEY),
    });
    const widgetOptions: TradingTerminalWidgetOptions = {
      locale: 'en',
      theme: tradingViewTheme,
      autosize: true,
      fullscreen: false,
      datafeed: datafeed,
      disabled_features: listDisable,
      interval: getResolutionInterval(TRADING_VIEW_INTERVAL_KEY),
      enabled_features: ['seconds_resolution'],
      overrides: {
        volumePaneSize: 'medium',
        'paneProperties.background': getBackgroundColor(theme),
        'mainSeriesProperties.candleStyle.upColor': '#16C782',
        'mainSeriesProperties.candleStyle.downColor': '#EA3943',
        'mainSeriesProperties.candleStyle.drawWick': true,
        'mainSeriesProperties.candleStyle.drawBorder': true,
        'mainSeriesProperties.candleStyle.borderColor': '#378658',
        'mainSeriesProperties.candleStyle.borderUpColor': '#16C782',
        'mainSeriesProperties.candleStyle.borderDownColor': '#EA3943',
        'mainSeriesProperties.candleStyle.wickUpColor': '#16C782',
        'mainSeriesProperties.candleStyle.wickDownColor': '#EA3943',
      },
      symbol: getCandleInfoType(),
      timezone: getClientTimezone(),
      library_path: props.libraryPath,
      charts_storage_api_version: props.chartsStorageApiVersion,
      charts_storage_url: props.chartsStorageUrl,
      user_id: props.userId,
      client_id: props.clientId,
      studies_overrides: {
        'volume.show ma': true,
      },
      container_id: props.containerId,
    };
    const chart = new widget(widgetOptions);
    tradingViewChart.current = chart;
    chart.onChartReady(() => {
      setChartReady(true);
      chart.applyOverrides({ 'paneProperties.topMargin': 15 });
      chart
        .chart()
        .onIntervalChanged()
        .subscribe(null, function (interval: any) {
          intervalInMillisecondsRef.current =
            convertResolution2MilliSeconds(interval);
        });
    });
  }, [candleType]);

  const selectInterval = (value: any) => {
    tradingViewChart.current.chart().setResolution(value, null);
  };

  const setChartType = (chartTypeNumber: number) => {
    tradingViewChart.current.chart().setChartType(chartTypeNumber);
  };

  const openIndicatorPopup = () => {
    tradingViewChart.current.chart().executeActionById('insertIndicator');
  };

  return (
    <div className="h-full">
      {chartReady ? (
        <NavbarTradingView
          isFullScreen={props.isFullScreen}
          onSelectInterval={selectInterval}
          openIndicatorPopup={openIndicatorPopup}
          setChartType={setChartType}
          handleFullscreen={props.handleFullscreen}
          setCandleType={setCandleType}
          candleType={candleType}
        />
      ) : (
        <div style={{ paddingTop: '64px' }} />
      )}
      <div
        style={{
          height: `${props.isFullScreen ? '95vh' : 'calc(100% - 64px)'}`,
        }}
        id={props.containerId}
      />
    </div>
  );
};

TradingView.defaultProps = {
  containerId: 'tv_chart_container',
  libraryPath: '/charting_library/',
  chartsStorageApiVersion: '1.1',
  clientId: 'tradingview.com',
  userId: 'public_user_id',
  fullscreen: true,
  // height: '100%',
  autosize: true,
  studiesOverrides: {
    'volume.volume.color.0': 'rgba(247, 73, 64, 0.19)',
    'volume.volume.color.1': 'rgba(41, 155, 130, 0.2)',
    'volume.volume.transparency': 15,
    'volume.volume ma.color': '#f74940',
    'volume.volume ma.transparency': 0,
    'volume.volume ma.linewidth': 1,
    'volume.volume ma.plottype': 'line',
    'volume.show ma': true,
  },
};
