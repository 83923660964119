import * as React from 'react';
import { useHistory } from 'react-router';
import { BasePage } from 'src/layout';
import rf from 'src/services/RequestFactory';
import { useEffect, useState } from 'react';
import { TPair } from 'src/types';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { formatAgeTime, formatNumber } from 'src/utils/format';
import { AppLogoNetwork, AppNumber, AppToggle } from 'src/components';
import { filterParams, multipliedByNumber } from 'src/utils/helper';
import { NumericFormat } from 'react-number-format';
import { toastError, toastInfo } from 'src/libs/toast';

const RESOLUTIONS = ['5m', '1h', '6h', '24h'];

export const TrendingPage = () => {
  const [pairs, setPairs] = useState<TPair[]>([]);
  const [resolution, setResolution] = useState<string>('24h');
  const { network, accessToken } = useSelector(
    (state: RootState) => state.user,
  );
  const [isQuickBuy, setIsQuickBuy] = useState<boolean>(false);
  const [buyAmount, setBuyAmount] = useState<any>('');
  const [settings, setSettings] = useState<any>({});

  const history = useHistory();

  const getPairTrending = async () => {
    try {
      const res = await rf.getRequest('PairRequest').getPairTrending(network, {
        resolution,
      });
      setPairs(res);
    } catch (e) {
      console.error(e);
    }
  };

  const getTradeSettings = async () => {
    try {
      const res = await rf.getRequest('OrderRequest').getTradeSettings(network);
      setSettings(res);
    } catch (e: any) {
      console.error(e);
    }
  };

  const createOrder = async (pair: TPair) => {
    try {
      if (!buyAmount) {
        throw Error('Please input amount!');
      }
      await rf.getRequest('OrderRequest').createOrderQuickBuy(
        pair.network,
        filterParams({
          buyAmount: +buyAmount,
          orderSetting: settings,
          pairId: pair?.pairId,
          tokenAddress: pair?.tokenBase?.address,
          wallets: [],
        }),
      );
      toastInfo('Submitting', 'Submitting Order!');
    } catch (e: any) {
      console.error(e);
      toastError('Error', e.message || 'Something went wrong!');
    }
  };

  useEffect(() => {
    if (!accessToken) return;
    getTradeSettings().then();
  }, [accessToken, network]);

  useEffect(() => {
    getPairTrending().then();
  }, [resolution, network]);

  useEffect(() => {
    if (!accessToken) return;
    setIsQuickBuy(true);
  }, [accessToken]);

  return (
    <BasePage>
      <div className="px-9 mt-8">
        <div className="font-bold text-[20px] uppercase">Trending</div>
        <div className="text-[#8d93b7] mt-2">
          Top 100 trending token pairs by transactions within selected
          timeframe.
        </div>

        <div className="flex justify-end gap-2 items-center ">
          <div>
            <div className="flex gap-2 items-center">
              <AppToggle
                disabled={!accessToken}
                value={isQuickBuy}
                onChange={() => setIsQuickBuy(!isQuickBuy)}
              />
              Quick Buy
              <div className="flex items-center gap-2 px-3 py-2 border-neutral-alpha-100 rounded-[100px] border ">
                <AppLogoNetwork network={network} className="w-5 h-5" />
                <NumericFormat
                  disabled={!isQuickBuy}
                  value={buyAmount ?? ''}
                  allowLeadingZeros
                  allowNegative={false}
                  thousandSeparator=","
                  className="w-[60px] text-[14px] outline-none bg-transparent"
                  decimalScale={6}
                  onValueChange={({ floatValue }) => {
                    return setBuyAmount(floatValue);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex rounded-[6px] overflow-hidden border border-neutral-alpha-50 w-max">
            {RESOLUTIONS.map((r, index) => {
              return (
                <div
                  key={index}
                  className={`py-2 px-4 text-center border-r border-neutral-alpha-50 cursor-pointer text-[11px] ${
                    r === resolution
                      ? 'bg-neutral-alpha-50 text-neutral-0'
                      : 'text-[#8d93b7]'
                  }`}
                  onClick={() => setResolution(r)}
                >
                  {r}
                </div>
              );
            })}
          </div>
        </div>

        <div
          className={`grid ${
            isQuickBuy ? 'grid-cols-9' : 'grid-cols-8'
          } uppercase pr-[9px] border-y font-bold mt-6 py-2 border-neutral-alpha-50 py-3 text-[12px] text-[#8d93b7] w-full`}
        >
          <div className="col-span-2 px-3">Pair Info</div>
          <div className="px-3">Created</div>
          <div className="px-3">Liquidity</div>
          <div className="px-3">MKT CAP</div>
          <div className="px-3">Makers</div>
          <div className="px-3">TNXS</div>
          <div className="px-3">Volume</div>
          {isQuickBuy && <div className="px-3">Action</div>}
        </div>
        <div className="max-h-[calc(100vh-300px)] overflow-auto customer-scroll">
          {!!pairs.length ? (
            pairs?.map((pair, index: number) => {
              return (
                <div
                  onClick={() => history.push(`/${pair.network}/${pair.slug}`)}
                  key={index}
                  className={`grid ${
                    isQuickBuy ? 'grid-cols-9' : 'grid-cols-8'
                  } py-6 cursor-pointer hover:bg-neutral-alpha-100 ${
                    index % 2 ? 'bg-neutral-alpha-50' : 'bg-neutral-alpha-20'
                  }`}
                >
                  <div className="col-span-2 px-3">
                    <span className="font-bold text-[14px]">
                      {pair.tokenBase.symbol}
                    </span>
                    <span className="text-[#8d93b7] text-[12px]">
                      {' '}
                      / {pair.tokenQuote.symbol}
                    </span>
                  </div>
                  <div className="px-3 ">
                    {formatAgeTime(pair.timestamp * 1000)}
                  </div>
                  <div className="px-3 flex gap-1 flex-wrap">
                    <AppNumber value={pair.liquidity} />
                    <div className="text-[#8d93b7] flex font-normal">
                      /<AppNumber value={pair.liquidityUsd} isForUSD />
                    </div>
                  </div>
                  <div className="px-3">
                    <AppNumber
                      value={multipliedByNumber(
                        pair?.tokenBase?.priceUsd,
                        pair.tokenBase?.totalSupply,
                      )}
                      isForUSD
                    />
                  </div>
                  <div className="px-3">{formatNumber(pair.totalMakers)}</div>
                  <div className="px-3">{formatNumber(pair.totalTxns)}</div>
                  <div className="px-3">
                    <AppNumber value={pair.volumeUsd} isForUSD />
                  </div>
                  {isQuickBuy && (
                    <div className="px-3">
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          createOrder(pair).then();
                        }}
                        className="flex w-[80px] bg-neutral-alpha-50 hover:bg-neutral-alpha-300 items-center gap-2 px-2 py-1 border-neutral-alpha-100 rounded-[100px] border cursor-pointer"
                      >
                        <AppLogoNetwork network={network} className="w-5 h-5" />
                        {buyAmount}
                      </div>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <div className="text-center w-full mt-10"> No Data...</div>
          )}
        </div>
      </div>
    </BasePage>
  );
};
