import {
  formatAgeTime,
  formatNumber,
  formatShortAddress,
  formatUnixTimestamp,
} from 'src/utils/format';
import {
  ExternalLink,
  FilterIcon,
  CloseIcon,
  SwapMoneyIcon,
  TargetIcon,
  WhaleIcon,
  ShirmpIcon,
  DolphinIcon,
  FishIcon,
} from 'src/assets/icons';
import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { AppBroadcast } from 'src/libs/broadcast';
import { getSocketInstance, SOCKET_EVENTS, SOCKET_TYPE } from 'src/libs/socket';
import { TPair, TPoolActivity, TTrader } from 'src/types';
import rf from 'src/services/RequestFactory';
import {
  filterParams,
  getLinkAddressExplorer,
  getLinkTxExplorer,
  minusNumber,
  multipliedByNumber,
} from 'src/utils/helper';
import { useParams } from 'react-router';
import { useInitialing } from 'src/hooks';
import { AppLogoNetwork, AppNumber, AppProgressSingle } from 'src/components';
import { useContext } from 'react';
import { PoolContext } from 'src/pages/pool/context';
import {
  FilterByAmount,
  FilterByMaker,
  // FilterByTotal,
  FilterByType,
} from './FilterTransaction';
import BigNumber from 'bignumber.js';
import Tooltip from 'rc-tooltip';
import { CATEGORY_MARKER } from 'src/utils/contants';
import { Virtuoso } from 'react-virtuoso';
import { useHeightContent } from '../hook/useHeightContent';

const DATE_TYPE = {
  DATE: 'Date',
  AGE: 'Age',
};

const UNIT_TYPE = {
  USD: 'USD',
  TOKEN: 'TOKEN',
};

const TypeTx = ({ type }: { type: string }) => {
  if (type === 'BUY' || type === 'ADD') {
    return (
      <div className="text-green-500 px-1 bg-green-800 capitalize rounded-[4px] py-[1px]">
        {type.toLowerCase()}
      </div>
    );
  }
  return (
    <div className="text-red-500 px-1 bg-red-800 capitalize rounded-[4px] py-[1px]">
      {type.toLowerCase()}
    </div>
  );
};

const CategoryMarker = ({
  category,
  width,
}: {
  category: string;
  width: number;
}) => {
  if (category === CATEGORY_MARKER.SHRIMP) {
    return <ShirmpIcon className={`w-[${width}px] h-[${width}px]`} />;
  }

  if (category === CATEGORY_MARKER.WHALE) {
    return <WhaleIcon className={`w-[${width}px] h-[${width}px]`} />;
  }

  if (category === CATEGORY_MARKER.DOLPHIN) {
    return <DolphinIcon className={`w-[${width}px] h-[${width}px]`} />;
  }

  if (category === CATEGORY_MARKER.PLANKTON) {
    return <div className={`w-[${width}px] h-[${width}px]`}>xx</div>;
  }

  return <FishIcon className={`w-[${width}px] h-[${width}px]`} />;
};

const DetailMarker = ({
  markerAddress,
  category,
}: {
  markerAddress: string;
  category: string;
}) => {
  const [data, setData] = useState<TTrader>();
  const { pair } = useContext(PoolContext) as { pair: TPair };

  const getInfoTraders = async () => {
    try {
      const res = await rf
        .getRequest('TradersRequest')
        .getInfoTraders(pair?.network, pair?.pairId, {
          pair: pair?.pairId,
          addresses: [markerAddress],
        });
      setData(res.docs[0]);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!pair) return;
    getInfoTraders().then();
  }, [pair?.pairId, markerAddress]);

  const pnl = minusNumber(data?.volumeUsdSold || 0, data?.volumeUsdBought || 0);

  const totalUnrealized = minusNumber(
    data?.baseAmountBought || 0,
    data?.baseAmountSold || 0,
  );

  const totalUnrealizedByUSD = () => {
    if (new BigNumber(totalUnrealized).comparedTo(0) < 0) {
      return '0';
    }

    return multipliedByNumber(totalUnrealized, pair?.tokenBase?.priceUsd);
  };

  const _renderBalance = () => {
    if (!data?.baseAmountBought || +totalUnrealized < 0)
      return (
        <div className="body-xs-regular-10 text-neutral-alpha-500">Unknown</div>
      );
    return (
      <div className="flex flex-col items-center">
        <div className="body-xs-regular-10">
          {!!+totalUnrealized ? formatNumber(totalUnrealized, 2) : '0'}{' '}
          <span className="text-neutral-alpha-500 body-xs-regular-10 px-[2px]">
            of
          </span>
          {formatNumber(data.baseAmountBought || 0, 2)}
        </div>
        <AppProgressSingle
          height={2}
          value={totalUnrealized}
          total={data.baseAmountBought || 0}
        />
      </div>
    );
  };

  const getRule = () => {
    switch (category) {
      case CATEGORY_MARKER.PLANKTON: {
        return 'Plankton:  <$10 bought or sold';
      }
      case CATEGORY_MARKER.SHRIMP: {
        return 'Shrimp: $250 - $1k bought or sold';
      }
      case CATEGORY_MARKER.FISH: {
        return 'Fish: $10 - $250 bought or sold';
      }
      case CATEGORY_MARKER.DOLPHIN: {
        return 'Dolphin: $1k - $10k bought or sold';
      }
      case CATEGORY_MARKER.WHALE: {
        return 'Whale: >$10k bought or sold';
      }
      default: {
        return '';
      }
    }
  };

  return (
    <div className="px-2 bg-neutral-alpha-50">
      <table>
        <tbody>
          <tr className="body-xs-regular-10 border-b border-dashed border-neutral-alpha-50">
            <td className="w-[72px] py-2">
              <span className="text-neutral-alpha-500 pr-1">(+)</span> Invested
            </td>
            <td className="body-sm-semibold-12 py-2 text-red-500">
              <AppNumber value={data?.volumeUsdBought} isForUSD />
            </td>
            <td className="px-4 py-2">
              <AppNumber value={data?.baseAmountBought} />
            </td>
            <td className="pl-4 py-2 text-neutral-alpha-500">
              {data?.boughtTxs} txn
            </td>
          </tr>

          <tr className="body-xs-regular-10 border-b px-4 border-dashed border-neutral-alpha-50">
            <td className="w-[72px] py-2">
              <span className="text-neutral-alpha-500 pr-1">(-)</span> Sold
            </td>
            <td className="body-sm-semibold-12 py-2 text-green-500">
              <AppNumber value={data?.volumeUsdSold} isForUSD />
            </td>
            <td className="py-2 px-4">
              <AppNumber value={data?.baseAmountSold} />
            </td>
            <td className="pl-4 py-2 text-neutral-alpha-500">
              {data?.soldTxs} txn
            </td>
          </tr>

          <tr className="body-xs-regular-10 border-b border-dashed border-neutral-alpha-50">
            <td className="w-[72px] py-2">
              <span className="text-neutral-alpha-500 pr-1">(=)</span> P&L
            </td>
            <td
              className={`body-sm-semibold-12 py-2 ${
                +pnl > 0 ? 'text-accent-green-500' : 'text-red-500'
              }`}
            >
              <AppNumber value={new BigNumber(pnl).abs().toString()} isForUSD />
            </td>
          </tr>

          <tr className="body-xs-regular-10 border-b border-dashed border-neutral-alpha-50">
            <td className="w-[72px] py-2">
              <div>Unrelized</div>
            </td>
            <td className="body-sm-semibold-12 py-2">
              <AppNumber value={totalUnrealizedByUSD()} isForUSD />
            </td>
            <td colSpan={2} className="pl-4">
              {_renderBalance()}
            </td>
          </tr>
        </tbody>
      </table>

      <div className="py-2 flex gap-1 items-center">
        <CategoryMarker category={category} width={32} />
        <div>
          <div className="text-neutral-alpha-1000 body-sm-semibold-12">
            {formatShortAddress(markerAddress, 6, 6)}
          </div>
          <div className="body-xs-regular-10 text-neutral-alpha-500">
            {getRule()}
          </div>
          <div className="body-xs-regular-10 text-neutral-alpha-500">
            Holder Since:: 5h 50m
          </div>
        </div>
      </div>
    </div>
  );
};

const SwapUnit = ({
  unit,
  setUnit,
  pair,
}: {
  unit: string;
  setUnit: (value: string) => void;
  pair: TPair;
}) => {
  return (
    <div
      className="flex items-center gap-1 px-1 py-[2px] text-[10px] leading-[16px] text-neutral-alpha-1000 bg-neutral-alpha-50 rounded-[4px] cursor-pointer"
      onClick={() => {
        if (unit === UNIT_TYPE.USD) {
          setUnit(UNIT_TYPE.TOKEN);
          return;
        }

        setUnit(UNIT_TYPE.USD);
        return;
      }}
    >
      {unit === 'USD' ? 'USD' : `${pair?.tokenQuote?.symbol}`}

      <SwapMoneyIcon />
    </div>
  );
};

const OPTIONS_DATE = [DATE_TYPE.AGE, DATE_TYPE.DATE];

const LIMIT_PER_PAGE = 100;

export const Transactions = ({ isExpand }: { isExpand?: boolean }) => {
  const { pairSlug, network } = useParams() as any;
  const [page, setPage] = useState(1);
  const [activities, setActivities] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [dateType, setDateType] = useState<string>(DATE_TYPE.AGE);
  const [priceUnit, setPriceUnit] = useState<string>(UNIT_TYPE.USD);
  const [totalUnit, setTotalUnit] = useState<string>(UNIT_TYPE.USD);
  const { isInitialing } = useInitialing();
  const { pair } = useContext(PoolContext) as { pair: TPair };
  const [params, setParams] = useState<any>({
    tradingType: 'ALL',
    makerAddress: '',
    totalUsd: '',
    baseAmount: '',
    quoteAmount: '',
  });
  const paramsRef = useRef(params);
  const { heightContent, setIsExpand } = useHeightContent();
  const listTransactionRef = useRef(null);

  useEffect(() => {
    paramsRef.current = params;
  }, [params]);

  useEffect(() => {
    setIsExpand(!!isExpand);
  }, [isExpand]);

  const isFiltering = () => {
    if (!paramsRef?.current) {
      return false;
    }
    return Object.values(paramsRef?.current).some(
      (value) => value !== '' && value !== 'ALL',
    );
  };

  useEffect(() => {
    const intervalTime = setInterval(() => {
      (listTransactionRef.current as any)?.forceUpdateGrid();
    }, 1000);

    return () => clearInterval(intervalTime);
  }, [listTransactionRef.current]);

  const getTransactions = async () => {
    const paramsFilter = filterParams({
      pairIdOrSlug: pairSlug,
      page,
      limit: LIMIT_PER_PAGE,
      ...params,
    });

    const res = await rf
      .getRequest('TransactionRequest')
      .getTransactions(network, paramsFilter);

    const newData = res?.docs || [];
    setActivities([...activities, ...newData]);
    setTotalPages(res?.total_pages);
  };

  const getTransactionsFirstTime = async () => {
    const paramsFilter = filterParams({
      pairIdOrSlug: pairSlug,
      page: 1,
      limit: LIMIT_PER_PAGE,
      ...params,
    });

    const res = await rf
      .getRequest('TransactionRequest')
      .getTransactions(network, paramsFilter);

    const newData = res?.docs || [];
    setActivities(newData);
    setTotalPages(res?.total_pages);
  };

  useEffect(() => {
    getTransactionsFirstTime().then();
  }, [pairSlug, params, network]);

  useEffect(() => {
    if (page === 1) return;
    getTransactions().then();
  }, [page]);

  const canAppendActivity = (data: TPoolActivity): boolean => {
    if (!isFiltering() || !paramsRef?.current) return true;

    for (const [key, value] of Object.entries(paramsRef.current)) {
      if (value === '' || value === 'ALL') continue;

      if (key === 'tradingType' && data.tradingType) {
        if (data.tradingType !== value) return false;
      }

      if (key === 'makerAddress' && data.maker?.address) {
        if (
          !data.maker?.address
            ?.toLowerCase()
            .includes((value as string)?.toLowerCase())
        )
          return false;
      }

      if (key === 'totalUsd' && data.totalUsd) {
        const [minTotalUsd, maxTotalUsd] = (value as string)
          .split('-')
          .map(Number);
        if (
          new BigNumber(data.totalUsd).isLessThan(minTotalUsd) ||
          new BigNumber(data.totalUsd).isGreaterThan(maxTotalUsd)
        )
          return false;
      }

      if (key === 'baseAmount' && data.baseAmount) {
        const [minBaseAmount, maxBaseAmount] = (value as string)
          .split('-')
          .map(Number);
        if (
          new BigNumber(data.baseAmount).isLessThan(minBaseAmount) ||
          new BigNumber(data.baseAmount).isGreaterThan(maxBaseAmount)
        )
          return false;
      }

      if (key === 'quoteAmount' && data.quoteAmount) {
        const [minQuoteAmount, maxQuoteAmount] = (value as string)
          .split('-')
          .map(Number);
        if (
          new BigNumber(data.quoteAmount).isLessThan(minQuoteAmount) ||
          new BigNumber(data.quoteAmount).isGreaterThan(maxQuoteAmount)
        )
          return false;
      }
    }

    return true;
  };

  const loadMore = () => {
    if (totalPages === page) return;
    setPage((prevState) => prevState + 1);
  };

  const subscribeTransaction = () => {
    getSocketInstance(SOCKET_TYPE.PUBLIC)?.emit(
      SOCKET_EVENTS.SUBSCRIBE_REALTIME_TRANSACTION,
      {
        pair: pairSlug,
      },
    );
  };

  const unsubscribeTransaction = () => {
    getSocketInstance(SOCKET_TYPE.PUBLIC)?.emit(
      SOCKET_EVENTS.UNSUBSCRIBE_REALTIME_TRANSACTION,
      {
        pair: pairSlug,
      },
    );
  };

  useEffect(() => {
    if (isInitialing) return;
    subscribeTransaction();

    return () => {
      unsubscribeTransaction();
    };
  }, [pairSlug, isInitialing]);

  useEffect(() => {
    const handleNewTransaction = (data: any) => {
      console.log(data?.timestamp, 'timestamp');

      if (canAppendActivity(data)) {
        data.isNew = true;
        setActivities((prevActivities: TPoolActivity[]) => [
          data,
          ...prevActivities,
        ]);
      }
    };

    const intervalId = setInterval(() => {
      const oneSecondAgo = Date.now() - 1000;
      setActivities((prevActivities: TPoolActivity[]) =>
        prevActivities.map((activity) =>
          activity.isNew && activity.timestamp < oneSecondAgo
            ? { ...activity, isNew: false }
            : activity,
        ),
      );
    }, 1000);

    AppBroadcast.on(SOCKET_EVENTS.TRANSACTION, handleNewTransaction);

    return () => {
      AppBroadcast.remove(SOCKET_EVENTS.TRANSACTION);
      clearInterval(intervalId);
    };
  }, []);

  const rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    style, // Style object to be applied to row (to position it)
  }: any) => {
    return <Row key={key} style={style} activity={activities[index]} />;
  };

  const getDecimalAmount = (
    value: number | string | BigNumber,
    decimalToken = 8,
  ) => {
    if (new BigNumber(value).gte(1000)) {
      return 1;
    }

    return decimalToken;
  };

  const Row = ({
    style,
    activity,
  }: {
    style: any;
    activity: TPoolActivity;
  }) => (
    <div
      style={style}
      className={`flex text-[12px] hover:bg-neutral-alpha-50 border-b border-neutral-alpha-50 leading-[1.5] font-normal ${
        activity.tradingType === 'SELL' || activity.tradingType === 'REMOVE'
          ? 'text-red-500'
          : 'text-green-500'
      } ${activity.isNew ? 'animate-new-transaction' : ''}`}
    >
      <div className="w-[14%] td text-neutral-alpha-500">
        {dateType === DATE_TYPE.DATE
          ? formatUnixTimestamp(activity.timestamp, 'MMM DD, HH:mm:ss')
          : formatAgeTime(activity.timestamp, 'ago')}
      </div>
      <div className="w-[10%] td">
        <TypeTx type={activity.tradingType} />
      </div>

      <div className="w-[18%] td">
        {totalUnit === UNIT_TYPE?.USD ? (
          <AppNumber value={activity.totalUsd} isForUSD />
        ) : (
          <>
            <AppLogoNetwork
              network={pair?.network}
              className="w-4 h-4"
              isBase
            />
            <AppNumber
              value={activity.quoteAmount}
              decimals={getDecimalAmount(
                activity.quoteAmount,
                pair?.tokenQuote?.decimals,
              )}
            />
          </>
        )}
      </div>
      <div className="w-[18%] td">
        <AppNumber
          value={activity.baseAmount}
          decimals={getDecimalAmount(
            activity.baseAmount,
            pair?.tokenBase?.decimals,
          )}
        />
      </div>

      <div className="w-[18%] td">
        {priceUnit === UNIT_TYPE.USD ? (
          <AppNumber value={activity.priceUsd} isForUSD />
        ) : (
          <>
            <AppLogoNetwork
              network={pair?.network}
              className="w-4 h-4"
              isBase
            />
            <AppNumber
              value={activity.price}
              decimals={pair?.tokenBase?.decimals}
            />
          </>
        )}
      </div>

      <div className="flex-1 td gap-1 text-neutral-alpha-800">
        <div className="w-4 h-4">
          <TargetIcon className="w-4 h-4" />
        </div>
        <div className="w-4 h-4">
          <Tooltip
            overlayClassName="tooltip-marker"
            overlay={
              <DetailMarker
                markerAddress={activity?.maker?.address}
                category={activity?.maker?.traderCategory}
              />
            }
            placement={'left'}
            showArrow={false}
          >
            <div className="cursor-pointer">
              <CategoryMarker
                category={activity?.maker?.traderCategory}
                width={16}
              />
            </div>
          </Tooltip>
        </div>

        <a
          href={getLinkAddressExplorer(pair?.network, activity?.maker?.address)}
          target="_blank"
          className="text-neutral-alpha-800 hover:text-neutral-0 hover:underline w-[48px]"
        >
          {activity.maker?.address?.slice(
            activity.maker?.address?.length - 6,
            activity.maker?.address?.length,
          )}
        </a>

        <div className="text-neutral-alpha-800 cursor-pointer hover:text-neutral-0">
          {params.makerAddress ? (
            <CloseIcon
              className="w-3"
              onClick={() =>
                setParams({
                  ...params,
                  makerAddress: '',
                })
              }
            />
          ) : (
            <FilterIcon
              onClick={() =>
                setParams({
                  ...params,
                  makerAddress: activity.maker?.address,
                })
              }
            />
          )}
        </div>

        <a
          href={getLinkTxExplorer(pair.network, activity.hash)}
          target="_blank"
          className="text-neutral-alpha-800 hover:text-neutral-0"
        >
          <ExternalLink />
        </a>
      </div>
    </div>
  );

  return (
    <div>
      <div className="flex text-[12px] leading-[1.5] font-normal text-neutral-alpha-500 border-b border-neutral-alpha-50">
        <div className="w-[14%] thead">
          <div className="flex p-[2px] rounded-[4px] bg-neutral-alpha-50">
            {OPTIONS_DATE.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`rounded-[4px] cursor-pointer p-1 text-[12px] leading-[1.5] ${
                    dateType === item
                      ? 'bg-neutral-alpha-50 text-neutral-alpha-1000'
                      : 'text-neutral-alpha-400'
                  }`}
                  onClick={() => setDateType(item)}
                >
                  {item}
                </div>
              );
            })}
          </div>
        </div>

        <div className="w-[10%] thead">
          Type <FilterByType params={params} setParams={setParams} />
        </div>

        <div className="w-[18%] thead">
          Total <SwapUnit unit={totalUnit} setUnit={setTotalUnit} pair={pair} />
          <FilterByAmount params={params} setParams={setParams} />
        </div>

        <div className="w-[18%] thead">
          {pair?.tokenBase?.symbol || ''}
          <FilterByAmount params={params} setParams={setParams} isBaseAmount />
        </div>

        <div className="w-[18%] thead">
          Price <SwapUnit unit={priceUnit} setUnit={setPriceUnit} pair={pair} />
        </div>

        <div className="flex-1 thead">
          Makers <FilterByMaker params={params} setParams={setParams} />
        </div>
      </div>

      <Virtuoso
        className="customer-scroll"
        style={{ height: heightContent }}
        data={activities}
        endReached={loadMore}
        increaseViewportBy={200}
        itemContent={(index, activity) => {
          return rowRenderer({ key: index, index, style: {}, activity });
        }}
      />
    </div>
  );
};
