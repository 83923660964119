import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { AppBroadcast } from 'src/libs/broadcast';

export const useHeightContent = () => {
  const [heightContent, setHeightContent] = useState(300);
  const [isExpand, setIsExpand] = useState(false);

  useEffect(() => {
    const handleResize = debounce(() => {
      setHeightContent(window.innerHeight - 135); // 135 is the height of the header
    }, 100);

    AppBroadcast.on('LAYOUT_CHANGE', handleResize);

    return () => {
      AppBroadcast.remove('LAYOUT_CHANGE', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isExpand) {
      setHeightContent(window.innerHeight - 135); // 135 is the height of the header
    }
  }, [isExpand]);

  return {
    setIsExpand,
    heightContent,
  };
};