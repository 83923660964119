import React, { useContext, useEffect, useState } from 'react';
import { THolder, TPair } from 'src/types';
import rf from 'src/services/RequestFactory';
import { getLinkAddressExplorer } from 'src/utils/helper';
import {
  formatNumber,
  formatShortAddress,
  formatUsdNumber,
} from 'src/utils/format';
import { PoolContext } from 'src/pages/pool/context';
import { useHeightContent } from '../hook/useHeightContent';
import { Virtuoso } from 'react-virtuoso';

const HolderItem = ({ holder }: { holder: THolder }) => {
  const { pair } = useContext(PoolContext) as { pair: TPair };

  return (
    <div className="grid grid-cols-4 py-3">
      <div className="px-3">
        <a
          className="text-blue-500"
          href={getLinkAddressExplorer(pair.network, holder.walletAddress)}
          target="_blank"
        >
          {formatShortAddress(holder.walletAddress)}
        </a>
      </div>
      <div className="px-3">
        {formatNumber( holder?.ownedPercent, 2)} %
      </div>
      <div className="px-3">{formatNumber(holder.balance, 2)}</div>
      <div className="px-3">{formatUsdNumber(holder.balanceUsd)}</div>
    </div>
  );
};

export const Holders = ({isExpand}: {isExpand?: boolean}) => {
  const [holders, setHolders] = useState<THolder[]>([]);
  const { pair } = useContext(PoolContext) as { pair: TPair };
  const { heightContent, setIsExpand } = useHeightContent();

  useEffect(() => {
    setIsExpand(!!isExpand);
  }, [isExpand]);

  const getHolders = async () => {
    try {
      const res = await rf
        .getRequest('HolderRequest')
        .getHolders(pair.network, {
          tokenAddress: pair?.tokenBase?.address,
        });
      setHolders(res.docs);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getHolders().then();
  }, [pair?.tokenBase?.address]);

  return (
    <div>
      <div className="grid grid-cols-4 uppercase py-3 text-[12px] mr-[6px] text-[#8d93b7]">
        <div className="px-3">Wallet</div>
        <div className="px-3">% Owned</div>
        <div className="px-3">Amount</div>
        <div className="px-3">Value</div>
      </div>

      {!!holders?.length ? (
        <Virtuoso
        style={{ height: heightContent }}
          data={holders}
          increaseViewportBy={200}
          itemContent={(index, item) => {
            return <HolderItem holder={item} key={index} />;
          }}
        />
      ) : (
        <div className="flex justify-center mt-10 text-[#8d93b7]"></div>
      )}
    </div>
  );
};
