import { TSocial, TWebsite } from 'src/types';

export const getLinkWebsitePair = (data: TWebsite[]) => {
  if (!data || !data.length) return '#';

  return data.find((item) => item.label === 'Website')?.url || '#';
};

export const getLinkSocialPair = (data: TSocial[], key: string) => {
  if (!data || !data.length) return '#';

  return data.find((item) => item.type === key)?.url || '#';
};
