import config from 'src/config';
import BaseRequest from './BaseRequest';

export default class CandleRequest extends BaseRequest {
  getUrlPrefix() {
    return config.apiUrl;
  }

  async getCandles(
    network: string,
    params: {
      from: number;
      to: number;
      pair: string;
      queryBy: string;
      resolution: number | string;
    },
  ) {
    const url = `/${network}/candles`;
    return this.get(url, params);
  }
}
