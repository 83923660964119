import * as React from 'react';
import { useHistory } from 'react-router';
import { BasePage } from 'src/layout';
import rf from 'src/services/RequestFactory';
import { useEffect } from 'react';
import { useState } from 'react';
import * as moment from 'moment';
import { TPair } from 'src/types';
import { formatAgeTime, formatNumber } from 'src/utils/format';
import { AppLogoNetwork, AppNumber, AppToggle } from 'src/components';
import { filterParams, multipliedByNumber } from 'src/utils/helper';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NumericFormat } from 'react-number-format';
import { toastError, toastInfo } from 'src/libs/toast';

export const ListPoolPage = () => {
  const [pairs, setPairs] = useState<TPair[]>([]);
  const [isQuickBuy, setIsQuickBuy] = useState<boolean>(false);
  const [buyAmount, setBuyAmount] = useState<any>('');
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [settings, setSettings] = useState<any>({});

  const { network, accessToken } = useSelector(
    (state: RootState) => state.user,
  );
  const history = useHistory();

  const getTradeSettings = async () => {
    try {
      const res = await rf.getRequest('OrderRequest').getTradeSettings(network);
      setSettings(res);
    } catch (e: any) {
      console.error(e);
    }
  };

  const createOrder = async (pair: TPair) => {
    try {
      if (!buyAmount) {
        throw Error('Please input amount!');
      }
      await rf.getRequest('OrderRequest').createOrderQuickBuy(
        pair.network,
        filterParams({
          buyAmount: +buyAmount,
          orderSetting: settings,
          pairId: pair?.pairId,
          tokenAddress: pair?.tokenBase?.address,
          wallets: [],
        }),
      );
      toastInfo('Submitting', 'Submitting Order!');
    } catch (e: any) {
      console.error(e);
      toastError('Error', e.message || 'Something went wrong!');
    }
  };

  const getPairRecently = async () => {
    try {
      const res = await rf.getRequest('PairRequest').getPairRecently(network, {
        timestamp: moment().subtract(1, 'week').unix(),
        page,
        limit: 20,
      });
      const newData = res?.docs || [];
      setPairs([...pairs, ...newData]);
      setTotalPages(res.total_pages);
      setPage(res.page);
    } catch (e) {
      console.error(e);
    }
  };

  const getPairRecentlyFistTime = async () => {
    try {
      const res = await rf.getRequest('PairRequest').getPairRecently(network, {
        timestamp: moment().subtract(1, 'week').unix(),
        page: 1,
        limit: 20,
      });
      setPairs(res?.docs);
      setTotalPages(res.total_pages);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchMoreData = () => {
    if (totalPages === page) return;
    setPage((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (page > 1) {
      getPairRecently().then();
    }
  }, [page]);

  useEffect(() => {
    getPairRecentlyFistTime().then();
  }, [network]);

  useEffect(() => {
    if (!accessToken) return;
    setIsQuickBuy(true);
  }, [accessToken]);

  useEffect(() => {
    if (!accessToken) return;
    getTradeSettings().then();
  }, [network, accessToken]);

  return (
    <BasePage>
      <div className="px-9 mt-8">
        <div className="font-bold text-[20px] uppercase">New Pairs</div>
        <div className="text-[#8d93b7] mt-2">
          New token pairs in the last 1-week updated in real-time.
        </div>

        <div className="mt-4 flex justify-end">
          <div className="flex gap-2 items-center">
            <AppToggle
              disabled={!accessToken}
              value={isQuickBuy}
              onChange={() => setIsQuickBuy(!isQuickBuy)}
            />
            Quick Buy
            <div className="flex items-center gap-2 px-3 py-2 border-neutral-alpha-100 rounded-[100px] border ">
              <AppLogoNetwork network={network} className="w-5 h-5" />
              <NumericFormat
                disabled={!isQuickBuy}
                value={buyAmount ?? ''}
                allowLeadingZeros
                allowNegative={false}
                thousandSeparator=","
                className="w-[60px] text-[14px] outline-none bg-transparent"
                decimalScale={6}
                onValueChange={({ floatValue }) => {
                  return setBuyAmount(floatValue);
                }}
              />
            </div>
          </div>
        </div>

        <div
          className={`grid ${
            isQuickBuy ? 'grid-cols-9' : 'grid-cols-8'
          } uppercase pr-[9px] border-y font-bold mt-6 py-2 border-neutral-alpha-50 py-3 text-[12px] text-[#8d93b7] w-full`}
        >
          <div className="col-span-2 px-3">Pair Info</div>
          <div className="px-3">Created</div>
          <div className="px-3">Liquidity</div>
          <div className="px-3">MKT CAP</div>
          <div className="px-3">Makers</div>
          <div className="px-3">TNXS</div>
          <div className="px-3">Volume</div>
          {isQuickBuy && <div className="px-3">Action</div>}
        </div>

        <div
          className="max-h-[calc(100vh-260px)] overflow-auto customer-scroll"
          id="scrollableNewPair"
        >
          <InfiniteScroll
            dataLength={pairs.length}
            next={fetchMoreData}
            hasMore={totalPages > page}
            loader={<div />}
            scrollableTarget="scrollableNewPair"
          >
            {pairs.map((pair, index: number) => {
              const mCap = multipliedByNumber(
                pair?.tokenBase?.priceUsd,
                pair.tokenBase?.totalSupply,
              );
              return (
                <div
                  key={index}
                  className={`grid ${
                    isQuickBuy ? 'grid-cols-9' : 'grid-cols-8'
                  } py-6 cursor-pointer hover:bg-neutral-alpha-100 font-medium ${
                    index % 2 ? 'bg-neutral-alpha-50' : 'bg-neutral-alpha-20'
                  }`}
                  onClick={() => history.push(`/${pair.network}/${pair.slug}`)}
                >
                  <div className="col-span-2 px-3">
                    <span className="font-bold text-[14px]">
                      {pair.tokenBase.symbol}
                    </span>
                    <span className="text-[#8d93b7] text-[12px]">
                      {' '}
                      / {pair.tokenQuote.symbol}
                    </span>
                  </div>
                  <div className="px-3">
                    {formatAgeTime(pair.timestamp * 1000)}
                  </div>
                  <div className="px-3 flex gap-1">
                    <AppNumber value={pair.liquidity} />
                    <div className="text-[#8d93b7] flex font-normal">
                      /<AppNumber value={pair.liquidityUsd} isForUSD />
                    </div>
                  </div>
                  <div className="px-3">
                    <AppNumber value={mCap} isForUSD />
                  </div>
                  <div className="px-3">{formatNumber(pair.totalMakers)}</div>
                  <div className="px-3">{formatNumber(pair.totalTxns)}</div>
                  <div className="px-3">
                    <AppNumber value={pair.volumeUsd} isForUSD />
                  </div>

                  {isQuickBuy && (
                    <div className="px-3">
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          createOrder(pair).then();
                        }}
                        className="flex w-[80px] bg-neutral-alpha-50 hover:bg-neutral-alpha-300 items-center gap-2 px-2 py-1 border-neutral-alpha-100 rounded-[100px] border cursor-pointer"
                      >
                        <AppLogoNetwork network={network} className="w-5 h-5" />
                        {buyAmount}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      </div>
    </BasePage>
  );
};
