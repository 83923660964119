import * as React from 'react';
import {
  CheckboxIcon,
  CheckedIcon,
  LockIcon,
  ArrowRight,
} from 'src/assets/icons';
import { useState } from 'react';
import ReactSlider from 'react-slider';
import { QRCodeSVG } from 'qrcode.react';
import { AppCopy } from 'src/components';

export const OnBoardPage = () => {
  const [agree, setAgree] = useState<boolean>(false);
  const [showPrivateKey, setShowPrivateKey] = useState<boolean>(false);
  const [isDepositScreen, setIsDepositScreen] = useState<boolean>(false);

  const _renderBtn = () => {
    if (isDepositScreen) {
      return (
        <div
          className={`cursor-pointer hover:bg-[#7970ea] bg-[#6a60e8] h-[42px] w-[120px] mx-auto mt-6 px-4 font-medium text-[16px] flex justify-center items-center rounded-[100px]`}
        >
          Continue
        </div>
      );
    }

    return (
      <div
        onClick={() => setIsDepositScreen(true)}
        className={`${
          agree
            ? 'cursor-pointer hover:bg-[#7970ea] bg-[#6a60e8]'
            : 'cursor-not-allowed bg-[#7970ea] opacity-60'
        } h-[42px] w-[120px] mx-auto mt-6 px-4 font-medium text-[16px] flex justify-center items-center rounded-[100px]`}
      >
        Next
      </div>
    );
  };

  const _renderContent = () => {
    if (isDepositScreen) {
      return (
        <div className="p-6 w-full flex flex-col items-center justify-center">
          <div>
            Your wallet has{' '}
            <span className="font-bold text-accent-blue-500">0.00 APT</span>
          </div>
          <div className="my-4">
            Deposit APT to this wallet to start trading
          </div>
          <QRCodeSVG
            fgColor="#ffffff"
            value="D4jEULCRvuZXYaH6urzjBVg2SNXcT7hDKNnHbkUbSLhL"
            bgColor="transparent"
          />
          <div className="text-[#8d93b7] my-4">OR</div>
          <div className="p-4 bg-neutral-alpha-600 rounded-[8px] flex gap-2 justify-between">
            D4jEULCRvuZXYaH6urzjBVg2SNXcT7hDKNnHbkUbSLhL
            <AppCopy
              message={'--'}
              className="w-5 text-[#8d93b7] hover:text-neutral-0"
            />
          </div>
        </div>
      );
    }

    return (
      <div className="p-6 w-full">
        <div>
          <div className="mb-2 text-[#8d93b7] uppercase text-[12px]">
            dex trade trading wallet
          </div>
          <div className="p-4 bg-neutral-alpha-600 rounded-[8px] flex justify-between">
            D4jEULCRvuZXYaH6urzjBVg2SNXcT7hDKNnHbkUbSLhL
            <AppCopy
              message={'--'}
              className="w-5 text-[#8d93b7] hover:text-neutral-0"
            />
          </div>
        </div>

        <div className="mt-4">
          <div className="mb-2 text-[#8d93b7] uppercase text-[12px]">
            Private Key
          </div>
          <div className="mb-2">
            Please copy the private key below and store it in a safe location.
            <div className="text-accent-yellow-500">
              Your private key will NOT be displayed again.
            </div>
          </div>

          <div className="p-4 bg-neutral-alpha-600 rounded-[8px]">
            <div className="flex justify-between gap-4 items-center">
              <div className="text-[12px]">
                Click and drag to the right to reveal private key.
              </div>

              <div className="border relative flex px-1 items-center border-[#6a60e8] w-[400px] h-[42px] rounded-[100px] bg-neutral-alpha-500">
                {!showPrivateKey ? (
                  <div className="absolute left-[60px] text-[#8d93b7] flex items-center  gap-1">
                    Slide <ArrowRight />
                  </div>
                ) : (
                  <div className="absolute right-[60px] text-[#8d93b7] flex items-center gap-1">
                    <ArrowRight className="rotate-[180deg]" /> Hide
                  </div>
                )}

                <ReactSlider
                  onChange={(value: number) => {
                    if (value === 100) {
                      setShowPrivateKey(true);
                      return;
                    }
                    if (value <= 50) {
                      setShowPrivateKey(false);
                    }
                  }}
                  className="w-full flex items-center h-full"
                  renderThumb={(props, state) => (
                    <div
                      {...props}
                      className="bg-[#6a60e8] flex justify-center text-neutral-0 cursor-pointer rounded-[100px] px-3 py-2 h-[34px] w-[50px] outline-none border-0"
                    >
                      <LockIcon className="w-5 h-5" />
                    </div>
                  )}
                />
              </div>
            </div>

            <div className="relative p-4 rounded-[8px] flex gap-4 bg-neutral-alpha-500 mt-4 items-center">
              {!showPrivateKey && (
                <div className="absolute left-0 right-0 w-full h-full rounded-[8px] backdrop-blur-sm" />
              )}

              <div className="break-all">
                56MKNJFqczVTycahWUnuEcDwi3D9AWCDx1Sq7s2UmVFT9RKz4ycQJwjykMRMtnPhf5vNorjQ82CCQ98cLrKNy3YS
              </div>

              <div>
                <AppCopy
                  message={'--'}
                  className="w-5 text-[#8d93b7] hover:text-neutral-0"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex justify-center items-center bg-[#0f1018] min-h-screen">
      <div>
        <div className="max-w-[500px] bg-neutral-alpha-50 border border-neutral-alpha-500 rounded-[16px] w-full">
          <div className="uppercase text-[16px] font-bold text-center py-4 border-b border-neutral-alpha-500">
            Getting started with dex trade
          </div>

          {_renderContent()}
        </div>

        {!isDepositScreen && (
          <div className="flex gap-2 mt-4">
            <div onClick={() => setAgree(!agree)} className="cursor-pointer">
              {agree ? (
                <CheckedIcon className="w-5 h-5" />
              ) : (
                <CheckboxIcon className="w-5 h-5" />
              )}
            </div>

            <div>
              "I Agree" Checkboxes for{' '}
              <a className="text-accent-blue-500" href="#" target="_blank">
                Privacy Policies
              </a>{' '}
              &{' '}
              <a className="text-accent-blue-500" href="#" target="_blank">
                Terms & Conditions Agreements
              </a>
            </div>
          </div>
        )}

        {_renderBtn()}
      </div>
    </div>
  );
};
