import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { ChartLightWeight } from 'src/components';
import * as React from 'react';
import { TPair } from 'src/types';
import { useContext } from 'react';
import { PoolContext } from '../context';

export const PartPoolChart = () => {
  const handle = useFullScreenHandle();
  const { pair } = useContext(PoolContext) as { pair: TPair };

  return (
    <FullScreen handle={handle} className="h-full">
      <ChartLightWeight
        handleFullscreen={!handle.active ? handle.enter : handle.exit}
        isFullScreen={handle.active}
        pair={pair}
      />
    </FullScreen>
  );
};
