import { BasePage } from 'src/layout';
import * as React from 'react';
import { useState } from 'react';
import { AutoBuy, QuickBuy, QuickSell, AutoSell } from './parts';
import { copyToClipboard } from '../../utils/copyToClipboard';
import { CopyIcon } from '../../assets/icons';

enum SETTINGS {
  QUICK_BUY,
  AUTO_BUY,
  QUICK_SELL,
  AUTO_SELL,
}

const SETTING_TABS = [
  {
    name: 'Quick Buy',
    value: SETTINGS.QUICK_BUY,
  },
  {
    name: 'Quick Auto',
    value: SETTINGS.QUICK_SELL,
  },
  {
    name: 'Auto Buy',
    value: SETTINGS.AUTO_BUY,
  },
  {
    name: 'Auto Auto',
    value: SETTINGS.AUTO_SELL,
  },
];

export const SettingsPage = () => {
  const [tab, setTab] = useState<number>(SETTINGS.QUICK_BUY);

  const _renderContent = () => {
    switch (tab) {
      case SETTINGS.QUICK_BUY: {
        return <QuickBuy />;
      }
      case SETTINGS.QUICK_SELL: {
        return <QuickSell />;
      }
      case SETTINGS.AUTO_BUY: {
        return <AutoBuy />;
      }
      case SETTINGS.AUTO_SELL: {
        return <AutoSell />;
      }
      default: {
        return <QuickBuy />;
      }
    }
  };

  return (
    <BasePage>
      <div className="px-9 mt-8">
        <div className="font-bold text-[20px] uppercase">Settings</div>
        <div className="border-neutral-alpha-500 border rounded-[8px] gap-10 py-4 px-6 bg-neutral-alpha-50 mt-6 flex items-center">
          <div className="uppercase font-bold">Photon Trading Wallet</div>
          <div className="px-4 py-3 bg-neutral-alpha-600 rounded-[100px] flex gap-2">
            EuWZM4LgiFCLaV9bMMb7eTTLvnSLX1nGek7Rfk4NiQcP
            <CopyIcon
              className="w-5 h-5 cursor-pointer text-[#8d93b7] hover:text-neutral-0"
              onClick={() => copyToClipboard('--')}
            />
          </div>
        </div>

        <div className="border-neutral-alpha-500 border rounded-[8px] p-6 bg-neutral-alpha-50 mt-2">
          <div className="flex gap-10 border-b border-neutral-alpha-500">
            {SETTING_TABS.map((item, index) => {
              return (
                <div
                  onClick={() => setTab(item.value)}
                  key={index}
                  className={`${
                    item.value === tab
                      ? 'text-neutral-0 border-b-2'
                      : 'text-[#8d93b7]'
                  } cursor-pointer hover:text-neutral-0  border-neutral-0 pb-2`}
                >
                  {item.name}
                </div>
              );
            })}
          </div>

          <div className="mt-6">{_renderContent()}</div>
        </div>
      </div>
    </BasePage>
  );
};
