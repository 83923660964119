import cx from 'classnames';
import React, { ReactNode } from 'react';
import ReactModal from 'react-modal';
import { CloseCircleIcon } from 'src/assets/icons';

interface Props {
  children: ReactNode;
  isOpen: boolean;
  onClose?: VoidFunction;
  title: string;
  zIndex?: number;
  className?: string;
  headerClassName?: string;
  titleClassName?: string;
  width?: string;
  closable?: boolean;
}

export const BaseModal = ({
  isOpen,
  children,
  onClose,
  title,
  className,
  headerClassName,
  titleClassName,
  closable = true,
  zIndex,
}: Props) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '24px',
      border: 0,
      padding: 0,
      background: '#1c1d23',
      overflow: 'inherit',
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.8)',
      zIndex: zIndex ?? 999,
    },
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      ariaHideApp={false}
    >
      <div className={`p-6 relative min-w-[480px] ${className}`}>
        <div className={cx(headerClassName || 'flex justify-between mb-6')}>
          <div className={cx('uppercase text-base font-bold', titleClassName)}>
            {title}
          </div>

          {closable && (
            <div onClick={onClose} className="cursor-pointer">
              <CloseCircleIcon />
            </div>
          )}
        </div>

        <div className={'w-full'}>{children}</div>
      </div>
    </ReactModal>
  );
};
