import config from 'src/config';
import BaseRequest from './BaseRequest';

export default class CampaignsRequest extends BaseRequest {
  getUrlPrefix = (): string => {
    return config.referralApiUrl;
  };

  getActiveCampaigns = async (): Promise<any> => {
    const url = `/campaigns/active`;
    return this.get(url);
  };

  getUserAirdropStats = async (campaignId: string): Promise<any> => {
    const url = `/campaigns/${campaignId}/stats`;
    return this.get(url);
  };

  getLeaderboard = async (
    campaignId: string,
    params?: {
      page?: number;
      limit?: number;
    },
  ): Promise<any> => {
    const url = `/campaigns/${campaignId}/leader-boards`;
    return this.get(url, params);
  };

  getMissions = async (campaignId: string): Promise<any> => {
    const url = `/missions/${campaignId}`;
    return this.get(url);
  };

  resolveMission = async (missionId: string): Promise<any> => {
    const url = `/missions/${missionId}/resolve`;
    return this.post(url);
  };
}
