import BigNumber from 'bignumber.js';
import * as React from 'react';

export const AppProgressSingle = ({
  value,
  total,
  height = 4,
}: {
  value: string | number;
  total: string | number;
  height?: number;
}) => {
  const percent = new BigNumber(value)
    .dividedBy(new BigNumber(total))
    .multipliedBy(100);

  return (
    <div className={`h-[${height}px] w-full bg-neutral-alpha-100`}>
      <div
        className={`h-[${height}px]  bg-neutral-alpha-1000 rounded-[100px]`}
        style={{ width: `${percent}%` }}
      />
    </div>
  );
};
