import { BasePage } from 'src/layout';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { AppButton, AppCopy } from 'src/components';
import rf from 'src/services/RequestFactory';
import { useEffect } from 'react';
import config from 'src/config';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { toastError, toastSuccess } from 'src/libs/toast';
import { TClaimRequest } from 'src/types/referral.type';
import { ArrowRight, CherronUpIcon } from 'src/assets/icons';

const DEFAULT_REFERRAL_LAYERS: TClaimRequest[] = [
  {
    level: 1,
    totalReferrals: 0,
    availableCommissions: '0',
    totalCommissions: '0',
  },
  {
    level: 2,
    totalReferrals: 0,
    availableCommissions: '0',
    totalCommissions: '0',
  },
  {
    level: 3,
    totalReferrals: 0,
    availableCommissions: '0',
    totalCommissions: '0',
  },
  {
    level: 4,
    totalReferrals: 0,
    availableCommissions: '0',
    totalCommissions: '0',
  },
  {
    level: 5,
    totalReferrals: 0,
    availableCommissions: '0',
    totalCommissions: '0',
  },
];

export const ReferralPage = () => {
  const [referralCode, setReferralCode] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const { accessToken } = useSelector((state: RootState) => state.user);

  const [referralLayers, setReferralLayers] = useState<TClaimRequest[]>([]);
  const [showMore, setShowMore] = useState(false);
  const claimableAmount = useMemo(() => {
    return referralLayers.reduce(
      (acc, layer) => acc + Number(layer.availableCommissions),
      0,
    );
  }, [referralLayers]);

  const totalUsdClaimed = useMemo(() => {
    return referralLayers.reduce(
      (acc, layer) => acc + Number(layer.totalCommissions),
      0,
    );
  }, [referralLayers]);

  const getReferralCode = async () => {
    try {
      const res = await rf.getRequest('UserRequest').getReferralCode();
      if (!!res.referralCode) {
        setReferralCode(res.referralCode);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const registerReferralCode = async () => {
    try {
      if (!code) {
        throw Error('Referral code is required!');
      }
      await rf.getRequest('UserRequest').registerReferralCode({
        code,
      });
      toastSuccess('Success', 'Update Successfully!');
      await getReferralCode();
    } catch (e: any) {
      console.error(e);
      toastError('Error', e.message || 'Something went wrong!');
    }
  };

  const getReferralLayers = async () => {
    try {
      const res = await rf.getRequest('UserRequest').getCommissions();
      if (res && res.length > 0) {
        setReferralLayers(res);
      } else {
        setReferralLayers(DEFAULT_REFERRAL_LAYERS);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getClaimRequests = async () => {
    try {
      const res = await rf.getRequest('UserRequest').createClaimRequest();
      if (!!res) {
        toastSuccess('Success', 'Get claim requests successfully!');
      } else {
        toastError('Error', 'Get claim requests failed!');
      }
    } catch (e) {
      toastError('Error', 'Get claim requests failed!');
    }
  };

  useEffect(() => {
    if (!accessToken) return;
    getReferralCode().then();
    getReferralLayers().then();
  }, [accessToken]);

  return (
    <BasePage>
      <div className="max-w-[1200px] mx-auto w-full px-9 mt-8">
        <div className="font-bold text-[20px] uppercase">Referral Tracking</div>

        <div className="bg-neutral-alpha-50 border border-neutral-alpha-500 rounded-[8px] p-6 mt-10">
          <div className="pb-3 border-b border-neutral-alpha-500 uppercase font-bold">
            Your referral link
          </div>

          {referralCode ? (
            <div className="flex gap-3 items-center mt-4 text-[#8d93b7]">
              <div className="text-[16px]">
                {config.appUrl}?start=ref{referralCode}
              </div>
              <AppCopy
                message={`${config.appUrl}?start=ref${referralCode}`}
                className="hover:text-neutral-0 w-5"
              />
            </div>
          ) : (
            <>
              <div className="mt-4 text-[#8d93b7]">
                You have 1 chance to update your referral URL handle.
              </div>

              <div className="flex gap-3 items-center mt-4">
                <div className="text-[16px]">{config.appUrl}?start=ref</div>
                <div>
                  <input
                    className="bg-transparent text-[16px] border border-neutral-alpha-500 rounded-[100px] w-[150px] px-3 py-[6px]"
                    value={code}
                    onChange={(e) => setCode(e.target.value.trim())}
                  />
                </div>

                <AppButton
                  onClick={registerReferralCode}
                  className="w-[80px] font-bold h-[34px] flex items-center justify-center"
                >
                  Save
                </AppButton>
              </div>
            </>
          )}
        </div>

        <div className="bg-neutral-alpha-50 border border-neutral-alpha-500 rounded-[8px] p-6 mt-6">
          <div className="pb-3 border-b border-neutral-alpha-500 uppercase font-bold">
            Early access referrals
          </div>
          <div className="mt-4 text-[#8d93b7]">
            Friends you refer get early access to RaidenX.
          </div>

          <div className="w-full flex justify-center items-center gap-4">
            {!showMore ? (
              <div
                className="text-primary-500 gap-2 flex items-center cursor-pointer"
                onClick={() => {
                  setShowMore(!showMore);
                }}
              >
                Show more{' '}
                <CherronUpIcon className="text-primary-500 rotate-180" />
              </div>
            ) : (
              <div className="w-full flex flex-col justify-center items-center gap-4">
                <EarlyAccessReferrals />
                <div
                  className="text-primary-500 gap-2 flex items-center cursor-pointer"
                  onClick={() => {
                    setShowMore(!showMore);
                  }}
                >
                  Hide <CherronUpIcon className="text-primary-500" />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="bg-neutral-alpha-50 border border-neutral-alpha-500 rounded-[8px] p-6 mt-6">
          <div className="pb-3 border-b border-neutral-alpha-500 uppercase font-bold">
            Your Referrals
          </div>
          <div className="flex justify-between items-center gap-4">
            <div>
              <YourReferrals referralLayers={referralLayers} />
            </div>
            <div>
              <ArrowRight className="text-primary-500" />
            </div>
            <div className="flex flex-col justify-center items-center gap-2 mt-9">
              <div className="border border-primary-500 rounded-xl px-4 py-2 text-center">
                <div className="text-[#8d93b7]">Claimable amount</div>
                <div className="text-[16px] font-bold text-primary-500">
                  ${claimableAmount}
                </div>
              </div>
              <div className="border border-brand-500 rounded-xl px-4 py-2 text-center">
                <div className="text-[#8d93b7]">Total usd claimed</div>
                <div className="text-[16px] font-bold text-brand-500">
                  ${totalUsdClaimed}
                </div>
              </div>
              <button
                className="bg-gradient-to-r from-brand-500 to-primary-500 rounded-3xl px-4 py-2 text-neutral-0"
                onClick={getClaimRequests}
              >
                Create claim request
              </button>
            </div>
          </div>
          <div className="mt-4 text-accent-yellow-500">
            The requested amount may change due to changes in SOL price. Your
            earned will reset after 1 month. Please claim when possible!
          </div>
        </div>

        <div className="bg-neutral-alpha-50 border border-neutral-alpha-500 rounded-[8px] p-6 mt-6">
          <div className="pb-3 border-b border-neutral-alpha-500 uppercase font-bold">
            Claim history
          </div>
          <div className="mt-4 text-[#8d93b7]">
            You have not claimed anything yet. Refer your friends to get
            rewards!
          </div>
        </div>
      </div>
    </BasePage>
  );
};

interface YourReferralsProps {
  referralLayers: TClaimRequest[];
}

const YourReferrals: React.FC<YourReferralsProps> = ({ referralLayers }) => {
  return (
    <div className="rounded-[8px] p-6">
      <div className="grid grid-cols-4 gap-4 mb-4">
        <div className=""></div>
        <div className="text-[#8d93b7] rounded-3xl border border-neutral-alpha-500 px-4 py-2 text-center">
          REFERRAL COUNT
        </div>
        <div className="text-[#8d93b7] rounded-3xl border border-neutral-alpha-500 px-4 py-2 text-center">
          CLAIMABLE VOLUME
        </div>
        <div className="text-[#8d93b7] rounded-3xl border border-neutral-alpha-500 px-4 py-2 text-center">
          LIFETIME VOLUME
        </div>
      </div>
      {referralLayers.map((layer) => (
        <div
          key={layer.level}
          className="grid grid-cols-4 gap-4 mb-2 items-center"
        >
          <div>
            <div className="bg-neutral-800 rounded-full py-2 px-4 text-[#8d93b7] text-sm inline-block border border-neutral-alpha-500">
              LAYER {layer.level}
            </div>
          </div>
          <div className="text-neutral-0 rounded-lg border border-neutral-alpha-500 px-4 py-2 text-end">
            {layer.totalReferrals}
          </div>
          <div className="text-brand-500 rounded-lg border border-neutral-alpha-500 px-4 py-2 text-end">
            ${layer.availableCommissions}
          </div>
          <div className="text-brand-500 rounded-lg border border-neutral-alpha-500 px-4 py-2 text-end">
            ${layer.totalCommissions}
          </div>
        </div>
      ))}
    </div>
  );
};

const EarlyAccessReferrals: React.FC = () => {
  return (
    <div className="w-full flex flex-col items-center p-6 rounded-lg">
      <div className="flex flex-col items-center mb-8">
        <div className="bg-gradient-to-r from-purple-500 to-blue-500 text-white px-4 py-1 rounded-full mb-2">
          YOU
        </div>
        <div className="text-[#00ffff] text-sm">
          Receive 25% of Friend 1's Fees
        </div>
      </div>

      <div className="relative w-full">
        {[1, 2, 3, 4, 5].map((level) => (
          <Level key={level} level={level} />
        ))}
      </div>
    </div>
  );
};

interface LevelProps {
  level: number;
}

const Level: React.FC<LevelProps> = ({ level }) => {
  const nodeCount = Math.pow(2, level - 1);
  const percentage = 25 / Math.pow(2, level - 1);

  return (
    <div className="flex justify-between items-center mb-8">
      <div className="text-[#00ffff] w-8">#{level}</div>
      <div className="flex-grow flex justify-around items-center relative">
        {[...Array(nodeCount)].map((_, index) => (
          <React.Fragment key={index}>
            <Node />
            {level < 5 && <ConnectingLine />}
          </React.Fragment>
        ))}
        {level < 5 && (
          <div className="absolute top-full left-0 right-0 text-center text-sm text-[#8d93b7] mt-2">
            {level === 1
              ? "Receive 3.5% of Friend 2's Fees"
              : level === 2
                ? "3% of Friend 3's"
                : level === 3
                  ? "2% of Friend 4's"
                  : '1%'}
          </div>
        )}
      </div>
      <div className="text-[#00ff00] w-12 text-right">
        {percentage.toFixed(1)}%
      </div>
    </div>
  );
};

const Node: React.FC = () => (
  <div className="w-8 h-8 rounded-full bg-blue-500 border-2 border-white flex items-center justify-center">
    <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
        clipRule="evenodd"
      />
    </svg>
  </div>
);

const ConnectingLine: React.FC = () => (
  <div
    className="absolute top-1/2 left-0 right-0 h-px bg-blue-500"
    style={{ transform: 'translateY(-50%)' }}
  />
);
