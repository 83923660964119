import { ChevronDownIcon, IconSettings } from 'src/assets/icons';
import * as React from 'react';
import { useState } from 'react';
import { OrderFormSetting, TOrderSetting } from './OrderFormSetting';
import { PnLForm, TTarget } from './PnLForm';
import { NumericFormat } from 'react-number-format';
import { AppButton } from 'src/components';
import rf from 'src/services/RequestFactory';
import moment from 'moment';
import { toastError, toastSuccess } from 'src/libs/toast';
import { useContext } from 'react';
import { PoolContext } from 'src/pages/pool/context';
import { TPair } from 'src/types';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';

export interface IStopLoss {
  hoursExpires: any;
  sellPercent: any;
}

export interface ITakeProfit {
  hoursExpires: number | string;
  sellPercent: number | string;
}

enum SETTING {
  STOP_LOSS,
  TAKE_PROFIT,
}

export const FormSettingSell = ({
  data,
  setData,
}: {
  data: ITakeProfit | IStopLoss;
  setData: (data: ITakeProfit | IStopLoss) => void;
}) => {
  return (
    <div className="pt-3 border-t border-neutral-alpha-500">
      <div className="mb-4">
        <div className="text-[12px] mb-1 text-[#8d93b7]">Sell Amount</div>
        <div className="flex gap-3 items-center">
          <NumericFormat
            value={data.sellPercent ?? ''}
            allowLeadingZeros
            allowNegative={false}
            thousandSeparator=","
            decimalScale={6}
            className="h-10 px-4 border-neutral-alpha-500 bg-transparent outline-none border w-full focus:border-[#6a60e8] rounded-[100px] disabled:text-neutral-grey-500"
            onValueChange={({ floatValue }) => {
              return setData({
                ...data,
                sellPercent: floatValue,
              });
            }}
          />
          %
        </div>
      </div>
      <div className="mb-4">
        <div className="text-[12px] mb-1 text-[#8d93b7]">Expires in hrs</div>
        <div className="flex gap-3 items-center">
          <NumericFormat
            value={data.hoursExpires ?? ''}
            allowLeadingZeros
            allowNegative={false}
            thousandSeparator=","
            decimalScale={0}
            className="h-10 px-4 border-neutral-alpha-500 bg-transparent outline-none border w-full focus:border-[#6a60e8] rounded-[100px] disabled:text-neutral-grey-500"
            onValueChange={({ floatValue }) => {
              return setData({
                ...data,
                hoursExpires: floatValue,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

const StopLoss = ({
  stopLoss,
  setStopLoss,
  targetStopLoss,
  setTargetStopLoss,
}: {
  targetStopLoss: TTarget;
  stopLoss: IStopLoss;
  setStopLoss: (data: IStopLoss) => void;
  setTargetStopLoss: (data: TTarget) => void;
}) => {
  return (
    <div>
      <div>
        <PnLForm
          data={targetStopLoss}
          setData={setTargetStopLoss}
          typeLimit="MIN"
        />
        <FormSettingSell data={stopLoss} setData={setStopLoss} />
      </div>
    </div>
  );
};

export const TakeProfit = ({
  takeProfit,
  setTakeProfit,
  targetTakeProfit,
  setTargetTakeProfit,
}: {
  targetTakeProfit: TTarget;
  takeProfit: ITakeProfit;
  setTargetTakeProfit: (data: TTarget) => void;
  setTakeProfit: (data: ITakeProfit) => void;
}) => {
  return (
    <div>
      <div>
        <PnLForm
          data={targetTakeProfit}
          setData={setTargetTakeProfit}
          typeLimit="MAX"
        />
        <FormSettingSell data={takeProfit} setData={setTakeProfit} />
      </div>
    </div>
  );
};

export const OrderFormSellAuto = () => {
  const [tab, setTab] = useState<number>(SETTING.STOP_LOSS);
  const [orderSetting, setOrderSetting] = useState<TOrderSetting>({
    slippage: 20,
    priorityFee: 0.008,
  });
  const [isShowAdvancedSettings, setIsShowAdvancedSettings] =
    useState<boolean>(false);
  const [targetStopLoss, setTargetStopLoss] = useState<TTarget>({
    targetMc: '',
    targetMcPercentage: 20,
    targetLinePercentage: 0,
  });
  const [stopLoss, setStopLoss] = useState<IStopLoss>({
    hoursExpires: 24,
    sellPercent: 100,
  });

  const [takeProfit, setTakeProfit] = useState<ITakeProfit>({
    hoursExpires: 24,
    sellPercent: 100,
  });
  const [targetTakeProfit, setTargetTakeProfit] = useState<TTarget>({
    targetMc: '',
    targetMcPercentage: 20,
    targetLinePercentage: 0,
  });

  const { pair } = useContext(PoolContext) as { pair: TPair };
  const { accessToken } = useSelector((state: RootState) => state.user);

  const createOrder = async () => {
    try {
      if (!accessToken) {
        throw Error('Please login!');
      }
      await rf.getRequest('OrderRequest').createOrderBuyDip({
        orderSetting: orderSetting,
        pairId: pair?.pairId,
        stopLoss: {
          expiredAt: moment().add(stopLoss.hoursExpires, 'hours').valueOf(),
          sellAmount: 'string',
          sellPercent: stopLoss.sellPercent,
          ...targetStopLoss,
        },
        takeProfit: {
          expiredAt: moment().add(takeProfit.hoursExpires, 'hours').valueOf(),
          sellAmount: 'string',
          sellPercent: takeProfit.sellPercent,
          ...targetTakeProfit,
        },
        tokenAddress: pair?.tokenBase?.address,
      });
      toastSuccess('Success', 'Created successfully!');
    } catch (e: any) {
      console.error(e);
      toastError('Error', e.message || 'Something went wrong!');
    }
  };

  return (
    <div>
      <div className="bg-neutral-alpha-500 rounded-[8px] my-4">
        <div className="grid grid-cols-2 border-b px-3 pt-3 border-neutral-alpha-500">
          <div
            onClick={() => setTab(SETTING.STOP_LOSS)}
            className={`text-center cursor-pointer font-bold pb-3 ${
              tab === SETTING.STOP_LOSS
                ? 'text-neutral-0 border-b'
                : 'text-[#8d93b7]'
            }`}
          >
            Stop Loss
          </div>
          <div
            onClick={() => setTab(SETTING.TAKE_PROFIT)}
            className={`text-center cursor-pointer font-bold pb-3 ${
              tab === SETTING.TAKE_PROFIT
                ? 'text-neutral-0 border-b'
                : 'text-[#8d93b7]'
            }`}
          >
            Take Profit
          </div>
        </div>

        <div className="p-3">
          {tab === SETTING.STOP_LOSS ? (
            <StopLoss
              stopLoss={stopLoss}
              setStopLoss={setStopLoss}
              targetStopLoss={targetStopLoss}
              setTargetStopLoss={setTargetStopLoss}
            />
          ) : (
            <TakeProfit
              targetTakeProfit={targetTakeProfit}
              setTargetTakeProfit={setTargetTakeProfit}
              takeProfit={takeProfit}
              setTakeProfit={setTakeProfit}
            />
          )}
        </div>
      </div>

      <div
        className={`${
          isShowAdvancedSettings ? 'border-t pt-3' : 'border-y py-3'
        } flex hover:text-neutral-0 font-bold text-[13px] text-[#8d93b7] justify-between items-center w-full cursor-pointer border-neutral-alpha-500`}
        onClick={() => setIsShowAdvancedSettings(!isShowAdvancedSettings)}
      >
        <div className="flex gap-2 items-center">
          <IconSettings />
          Advanced Settings
        </div>
        <div>
          <ChevronDownIcon
            className={`${
              isShowAdvancedSettings ? 'rotate-[180deg]' : ' '
            } w-4 h-4`}
          />
        </div>
      </div>

      {isShowAdvancedSettings && (
        <OrderFormSetting
          setOrderSetting={setOrderSetting}
          orderSetting={orderSetting}
        />
      )}

      <AppButton className="mt-4" onClick={createOrder}>
        Create Order
      </AppButton>

      <div className="text-[12px] text-[#8d93b7] mt-3">
        Once you click on <b>Create Order</b> , your order will be created
        immediately.
      </div>
    </div>
  );
};
