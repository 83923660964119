import dev from './dev.json';
import prod from './prod.json';

export interface Config {
  apiUrl: string;
  referralApiUrl: string;
  appUrl: string;
  appInsightUrl: string;
  socketUrl: string;
  explorerAptosUrl: string;
  explorerSuiUrl: string;
  link_telegram: string;
  linkSocial: {
    twitter: string;
    telegram: string;
    discord: string;
  };
  title: string;
}

const envConfig = process.env.REACT_APP_ENV || 'dev';

interface EnvConfig {
  prod: Config;
  dev: Config;
  local: Config;
  stg: Config;
}

const configs: EnvConfig = { dev, prod } as EnvConfig;
const config: Config = configs[envConfig as keyof typeof configs];

export default config;
