import config from 'src/config';
import BaseRequest from './BaseRequest';

export default class PairRequest extends BaseRequest {
  getUrlPrefix() {
    return config.apiUrl;
  }

  async getPairRecently(network: string, params: any) {
    const url = `/${network}/pairs/recently`;
    return this.get(url, params);
  }

  async getPairTrending(network: string, params: any) {
    const url = `/${network}/pairs/trending`;
    return this.get(url, params);
  }

  async getPair(network: string, pairId: string) {
    const url = `/${network}/pairs/${pairId}`;
    return this.get(url);
  }

  async getPairStats(network: string, pairId: string) {
    const url = `/${network}/pairs/${pairId}/stats`;
    return this.get(url);
  }

  async getPairAudit(network: string, pairId: string) {
    const url = `/${network}/pairs/${pairId}/audit`;
    return this.get(url);
  }
}
