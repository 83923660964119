import { BaseModal } from 'src/modals/BaseModal';
import React, { useEffect, useState } from 'react';
import { AppButton } from 'src/components';
import { getLinkAddressExplorer } from 'src/utils/helper';
import { TWallet } from 'src/types';
import rf from 'src/services/RequestFactory';
import { toastError, toastSuccess } from 'src/libs/toast';

export const ModalEditWallet = ({
  isOpen,
  onClose,
  wallet,
  fetchData,
  network,
}: {
  isOpen: boolean;
  network: string;
  onClose: () => void;
  fetchData: () => void;
  wallet: TWallet;
}) => {
  const [name, setName] = useState<string>('');

  useEffect(() => {
    setName(wallet.aliasName);
  }, [wallet.aliasName]);

  const onEditName = async () => {
    try {
      await rf
        .getRequest('WalletRequest')
        .editNameWallet(network, wallet.address, {
          newAlias: name,
        });
      toastSuccess('Success', 'Edit successfully!');
      fetchData();
      onClose();
    } catch (e: any) {
      console.error(e);
      toastError('Error', e.message || 'Something went wrong!');
    }
  };

  return (
    <BaseModal isOpen={isOpen} title={'Edit Wallet'} onClose={onClose}>
      <div className="flex flex-col gap-1">
        <div className="text-[12px] text-[#8d93b7] uppercase">Name</div>
        <input
          className="outline-none bg-transparent border border-neutral-alpha-500 rounded-[6px] px-4 py-3 w-full"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="flex flex-col gap-1 mt-4">
        <div className="text-[12px] text-[#8d93b7] uppercase">Address</div>
        <a
          className="text-blue-500"
          href={getLinkAddressExplorer(network, wallet.address)}
          target="_blank"
        >
          {wallet.address}
        </a>
      </div>
      <div className="flex gap-4 justify-center mt-8">
        <AppButton className="min-w-[120px]" onClick={onEditName}>
          Save
        </AppButton>
      </div>
    </BaseModal>
  );
};
