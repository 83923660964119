import * as React from 'react';
import { useState } from 'react';
// import { CheckboxIcon, CheckedIcon } from 'src/assets/icons';
import { FlashIcon, TagIcon } from 'src/assets/icons';
import { OrderFormBuyNow } from './OrderFormBuyNow';
import { OrderFormBuyDip } from './OrderFormBuyDip';
import { OrderFormSellNow } from './OrderFormSellNow';
import { OrderFormSellAuto } from './OrderFormSellAuto';

enum TRADE_TYPE {
  BUY,
  SELL,
}

enum TYPE_SIDE {
  BUY_NOW = 'BUY_NOW',
  BUY_DIP = 'BUY_DIP',
  SELL_NOW = 'SELL_NOW',
  AUTO_SELL = 'AUTO_SELL',
}
//
// const BUY_OPTIONS = [
//   {
//     name: 'Buy Now',
//     value: TYPE_SIDE.BUY_NOW,
//   },
//   {
//     name: 'Buy Dip',
//     value: TYPE_SIDE.BUY_DIP,
//   },
// ];
//
// const SELL_OPTIONS = [
//   {
//     name: 'Sell Now',
//     value: TYPE_SIDE.SELL_NOW,
//   },
//   {
//     name: 'Auto Sell',
//     value: TYPE_SIDE.AUTO_SELL,
//   },
// ];

export const OrderForm = () => {
  const [type, setType] = useState<number>(TRADE_TYPE.BUY);
  // const [sideOptions, setSideOptions] = useState(BUY_OPTIONS);
  const [selectedSideOption, setSelectedSideOption] = useState<string>(
    TYPE_SIDE.BUY_NOW,
  );

  const selectType = (type: number) => {
    setType(type);
    if (type === TRADE_TYPE.BUY) {
      setSelectedSideOption(TYPE_SIDE.BUY_NOW);
      // setSideOptions(BUY_OPTIONS);
    } else {
      setSelectedSideOption(TYPE_SIDE.SELL_NOW);
      // setSideOptions(SELL_OPTIONS);
    }
  };

  const renderOrderFormBuyContent = () => {
    return (
      <>
        {selectedSideOption === TYPE_SIDE.BUY_NOW ? (
          <OrderFormBuyNow />
        ) : (
          <OrderFormBuyDip />
        )}
      </>
    );
  };

  const renderOrderFormSellContent = () => {
    return (
      <>
        {selectedSideOption === TYPE_SIDE.SELL_NOW ? (
          <OrderFormSellNow />
        ) : (
          <OrderFormSellAuto />
        )}
      </>
    );
  };

  const renderOrderFormContent = () => {
    if (type === TRADE_TYPE.BUY) {
      return renderOrderFormBuyContent();
    } else {
      return renderOrderFormSellContent();
    }
  };

  return (
    <div className="bg-neutral-alpha-50 px-2 py-3 border-b border-neutral-alpha-50 w-full overflow-hidden">
      <div className="flex grid grid-cols-2 my-2 bg-neutral-beta-500 p-[4px] rounded-[4px]">
        <div
          className={`${
            type === TRADE_TYPE.BUY
              ? 'bg-neutral-alpha-50 text-brand-500'
              : 'text-neutral-alpha-500'
          } flex items-center gap-1 justify-center text-center px-[8px] py-[6px] body-sm-semibold-12 cursor-pointer rounded-[4px]`}
          onClick={() => selectType(TRADE_TYPE.BUY)}
        >
          <FlashIcon /> Buy
        </div>
        <div
          className={`${
            type === TRADE_TYPE.SELL
              ? 'bg-neutral-alpha-50 text-brand-500'
              : 'text-neutral-alpha-500'
          } flex items-center gap-1 justify-center text-center px-[8px] py-[6px] body-sm-semibold-12 cursor-pointer rounded-[4px]`}
          onClick={() => selectType(TRADE_TYPE.SELL)}
        >
          <TagIcon /> Sell
        </div>
      </div>

      <div>
        {/*<div className="flex grid grid-cols-2 border-b pb-3 border-neutral-alpha-500">*/}
        {/*  {sideOptions.map((option, index) => (*/}
        {/*    <div*/}
        {/*      key={index}*/}
        {/*      className={`${*/}
        {/*        selectedSideOption === option.value*/}
        {/*          ? 'text-neutral-0'*/}
        {/*          : 'text-[#8d93b7]'*/}
        {/*      } flex gap-2 cursor-pointer items-center`}*/}
        {/*      onClick={() => setSelectedSideOption(option.value)}*/}
        {/*    >*/}
        {/*      {selectedSideOption === option.value ? (*/}
        {/*        <CheckedIcon className="w-5 h-5" />*/}
        {/*      ) : (*/}
        {/*        <CheckboxIcon className="w-5 h-5" />*/}
        {/*      )}*/}
        {/*      {option.name}*/}
        {/*    </div>*/}
        {/*  ))}*/}
        {/*</div>*/}
        {renderOrderFormContent()}
      </div>
    </div>
  );
};
