import React, { useContext, useEffect, useState } from 'react';
import rf from 'src/services/RequestFactory';
import { useParams } from 'react-router';
import { TPair, TTrader } from 'src/types/pair.type';
import { formatNumber, formatShortAddress } from 'src/utils/format';
import BigNumber from 'bignumber.js';
import { PoolContext } from 'src/pages/pool/context';
import {
  getLinkAddressExplorer,
  minusNumber,
  multipliedByNumber,
} from 'src/utils/helper';
import { ExternalLink } from 'src/assets/icons';
import { AppNumber, AppProgressSingle } from 'src/components';
import { Virtuoso } from 'react-virtuoso';
import { useHeightContent } from '../hook/useHeightContent';

const TraderItem = ({ rank, trader }: { rank: number; trader: TTrader }) => {
  const { pair } = useContext(PoolContext) as { pair: TPair };
  const totalUnrealized = +trader.baseAmountBought - +trader.baseAmountSold;

  const totalUnrealizedByUSD = () => {
    if (totalUnrealized < 0) {
      return '0';
    }

    return multipliedByNumber(totalUnrealized, pair?.tokenBase?.priceUsd);
  };

  const pnl = minusNumber(trader.volumeUsdSold, trader.volumeUsdBought);

  const _renderSold = () => {
    if (!+trader?.baseAmountSold) {
      return <div className="flex items-center h-full">--</div>;
    }
    return (
      <>
        <div className="text-accent-green-500">
          <AppNumber value={trader.volumeUsdSold} isForUSD />
        </div>
        <div className="text-[12px] text-[#8d93b7] font-bold flex gap-1">
          <AppNumber value={trader.baseAmountSold} /> /{' '}
          {+trader.soldTxs || '--'} <span className="font-normal">txns</span>
        </div>
      </>
    );
  };

  const _renderBought = () => {
    if (!+trader?.baseAmountBought) {
      return <div className="flex items-center h-full">--</div>;
    }

    return (
      <>
        <div className="text-red-500">
          <AppNumber value={trader.volumeUsdBought} isForUSD />
        </div>
        <div className="text-[12px] text-[#8d93b7] font-bold flex gap-1">
          <AppNumber value={trader.baseAmountBought} /> /{' '}
          {+trader.boughtTxs || '--'} <span className="font-normal">txns</span>
        </div>
      </>
    );
  };

  const _renderBalance = () => {
    if (!+trader.baseAmountBought || +totalUnrealized < 0)
      return (
        <div className="flex h-full items-center text-[12px] justify-center text-[#8d93b7]">
          Unknown
        </div>
      );
    return (
      <div className="flex flex-col h-full items-center gap-2">
        <div>
          {!!+totalUnrealized ? formatNumber(totalUnrealized, 2) : '0'}{' '}
          <span className="text-[12px] text-[#8d93b7] px-1">of</span>
          {formatNumber(trader.baseAmountBought, 2)}
        </div>

        <AppProgressSingle
          height={4}
          value={totalUnrealized}
          total={trader.baseAmountBought}
        />
      </div>
    );
  };
  return (
    <div className="flex py-3 border-b border-neutral-alpha-500 ">
      <div className="px-3 w-[5%] flex items-center justify-center">
        #{rank}
      </div>
      <div className="px-3 w-[20%] flex items-center justify-center">
        {formatShortAddress(trader.maker)}
      </div>
      <div className="px-3 w-[15%]">{_renderBought()}</div>
      <div className="px-3 w-[15%]">{_renderSold()}</div>
      <div
        className={`px-3 w-[15%] flex items-center ${
          +pnl > 0 ? 'text-accent-green-500' : 'text-red-500'
        }`}
      >
        <AppNumber value={new BigNumber(pnl).abs().toString()} isForUSD />
      </div>
      <div className="px-3 w-[15%] flex items-center">
        <AppNumber value={totalUnrealizedByUSD()} isForUSD />
      </div>
      <div className="px-3 w-[15%]">{_renderBalance()}</div>

      <div className="px-3 w-[5%] flex items-center justify-center">
        <a
          href={getLinkAddressExplorer(pair.network, trader.maker)}
          target="_blank"
          className="text-neutral-500 hover:text-neutral-0"
        >
          <ExternalLink />
        </a>
      </div>
    </div>
  );
};

export const TopTraders = ({isExpand}: {isExpand?: boolean}) => {
  const { network } = useParams() as any;
  const [traders, setTraders] = useState<TTrader[]>([]);
  const { pair } = useContext(PoolContext) as { pair: TPair };
  const { heightContent, setIsExpand } = useHeightContent();

  const getTopTraders = async () => {
    try {
      const res = await rf
        .getRequest('TradersRequest')
        .getTopTraders(network, pair?.pairId);
      setTraders(res.docs);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setIsExpand(!!isExpand);
  }, [isExpand]);

  useEffect(() => {
    getTopTraders().then();
  }, [pair?.pairId]);

  return (
    <div>
      <div className="flex uppercase py-3 text-[12px] mr-[6px] text-[#8d93b7]">
        <div className="px-3 w-[5%] text-center">Rank</div>
        <div className="px-3 w-[20%] text-center">Maker</div>
        <div className="px-3 w-[15%]">bought</div>
        <div className="px-3 w-[15%]">sold</div>
        <div className="px-3 w-[15%]">Pnl</div>
        <div className="px-3 w-[15%]">unrealized</div>
        <div className="px-3 w-[15%] text-center">balance</div>
        <div className="px-3 w-[5%] text-center">Exp</div>
      </div>
      {!!traders.length ? (
        <Virtuoso
        style={{ height: heightContent }}
          data={traders}
          increaseViewportBy={200}
          itemContent={(index, item) => {
            return <TraderItem key={index} rank={index + 1} trader={item} />
          }}
        />
      ) : (
        <div className="flex justify-center mt-10 text-[#8d93b7]">No Data</div>
      )}
    </div>
  );
};
