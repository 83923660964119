import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { TPair, TWallet } from 'src/types';
import rf from 'src/services/RequestFactory';
import { AppCopy, AppLogoNetwork } from 'src/components';
import { CheckboxIcon, CheckedIcon } from 'src/assets/icons';
import { useContext } from 'react';
import { PoolContext } from 'src/pages/pool/context';

const WalletItem = ({ wallet }: { wallet: TWallet }) => {
  const { walletsActive, setWalletsActive } = useContext(PoolContext);

  const isSelected = useMemo(
    () => walletsActive?.some((item) => item === wallet.address),
    [walletsActive],
  );

  const onSelectWallet = () => {
    if (isSelected) {
      const newWallets =
        walletsActive?.filter((item) => item !== wallet.address) || [];
      setWalletsActive && setWalletsActive(newWallets);
      return;
    }

    setWalletsActive &&
      setWalletsActive([...(walletsActive || []), wallet.address]);
  };

  return (
    <div className="grid grid-cols-2 py-2 border-b border-neutral-alpha-500">
      <div className="flex gap-2 items-center">
        <div onClick={onSelectWallet} className="cursor-pointer">
          {isSelected ? (
            <CheckedIcon className="w-4" />
          ) : (
            <CheckboxIcon className="w-4" />
          )}
        </div>

        {wallet.address.slice(wallet.address.length - 4, wallet.address.length)}
        <AppCopy message={wallet.address} />
      </div>
      <div className="flex justify-end">{wallet.balance}</div>
    </div>
  );
};

export const Wallets = () => {
  const [wallets, setWallets] = useState<TWallet[]>([]);
  const { setWalletsActive } = useContext(PoolContext);
  const { pair } = useContext(PoolContext) as { pair: TPair };

  const getWallets = async () => {
    try {
      const res = await rf
        .getRequest('WalletRequest')
        .getWallets(pair?.network);
      setWallets(res);
      const walletDefault = res.find((item: TWallet) => item.isDefault);
      if (setWalletsActive && !!walletDefault) {
        setWalletsActive([walletDefault?.address]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!pair?.network) return;
    getWallets().then();
  }, [pair?.network]);

  const totalBalance = useMemo(
    () => wallets.reduce((total, wallet) => +wallet.balance + total, 0),
    [wallets],
  );

  return (
    <div className="bg-neutral-alpha-50 mt-3 border border-neutral-alpha-500 rounded-[8px]">
      <div className="p-4 border-b border-neutral-alpha-500 uppercase font-bold">
        Wallets
      </div>
      <div className="p-4">
        <div className="grid grid-cols-2 uppercase text-[12px] text-[#8d93b7]">
          <div>Wallet</div>
          <div className="text-right">Balance</div>
        </div>
        <div className="max-h-[200px] overflow-auto customer-scroll">
          {wallets.map((wallet, index) => {
            return <WalletItem key={index} wallet={wallet} />;
          })}
        </div>

        <div className="grid grid-cols-2 mt-3">
          <div className="text-[12px] text-[#8d93b7]">Total Balance</div>
          <div className="text-right font-bold flex items-center gap-2 justify-end">
            {totalBalance}
            <AppLogoNetwork network={pair?.network} className="w-4 h-4" />
          </div>
        </div>
      </div>
    </div>
  );
};
