import { BaseModal } from 'src/modals/BaseModal';
import React, { useState } from 'react';
import { AppButton } from 'src/components';
import rf from 'src/services/RequestFactory';
import { toastError, toastSuccess } from 'src/libs/toast';

export const ModalImportWallet = ({
  isOpen,
  onClose,
  fetchData,
  network,
}: {
  isOpen: boolean;
  network: string;
  fetchData: () => void;
  onClose: () => void;
}) => {
  const [privateKey, setPrivateKey] = useState<string>('');

  const onImportWallet = async () => {
    try {
      await rf.getRequest('WalletRequest').getImportWallet(network, {
        privateKey,
      });
      fetchData();
      toastSuccess('Success', 'Import successfully!');
      onClose();
    } catch (e: any) {
      console.error(e);
      toastError('Error', e.message || 'Something went wrong!');
    }
  };

  return (
    <BaseModal isOpen={isOpen} title={'Import Wallet'} onClose={onClose}>
      <div className="flex flex-col gap-1">
        <div className="text-[12px] text-[#8d93b7] uppercase">Private key</div>
        <input
          className="outline-none bg-transparent border border-neutral-alpha-500 rounded-[6px] px-4 py-3 w-full"
          value={privateKey}
          onChange={(e) => setPrivateKey(e.target.value)}
        />
      </div>
      <div className="flex gap-4 justify-center mt-8">
        <AppButton className="min-w-[120px]" onClick={onImportWallet}>
          Import
        </AppButton>
      </div>
    </BaseModal>
  );
};
