import { DefaultAvatar } from 'src/assets/icons';
import React from 'react';

export const AppAvatarToken = ({
  image,
  className,
}: {
  image?: string;
  className?: string;
}) => {
  if (!image) return <DefaultAvatar className={className} />;

  return <img src={image} className={`rounded-[100px] ${className}`} />;
};
